import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Typography,
  Backdrop,
  Fade,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FilledInput,
  Checkbox,
  ListItemText,
  Button,
  Divider,
} from '@material-ui/core';
import Image from 'material-ui-image';

import imgPautas from '~/assets/ilustra_pautas.svg';

import {
  salvarMateriaSelecionada,
  salvarTurmaSelecionada,
  requestMaterias,
  saveProfessoresSelecionados,
} from '~/store/modules/pautas/actions';
import { requestProfessores } from '~/store/modules/professores/actions';
import { changeOpenMenu } from '~/store/modules/layout/actions';

import history from '~/services/history';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 487,
    maxHeight: '90%',
    margin: '10px',
    overflow: 'auto',
  },
  statusMateria: {
    margin: '10px',
    color: theme.palette.secondary.main,
    fontSize: '14px',
    letterSpacing: '1.5px',
  },
}));

export default function ModalSelectTurma({ openModal }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const turmas = useSelector(state => state.turmas.data);
  const { pauta } = useSelector(state => state.permissoes.acessos);
  const pessoa = useSelector(state => state.permissoes.pessoa);
  const professores = useSelector(state => state.professores.data);
  const {
    turmaSelecionada,
    materiaSelecionada,
    materias,
    professoresSelecionados,
  } = useSelector(state => state.pautas);

  const [professoresIDs, setProfessoresIDs] = useState([]);

  useEffect(() => {
    if (!professores) {
      dispatch(requestProfessores());
    }
    const ids = [];
    if (professores) {
      professores.map(professor => ids.push(professor.professor_id));
    }
    setProfessoresIDs(ids);
  }, [professores]); // eslint-disable-line

  try {
    const onChangeTurma = async event => {
      const turmaEscolhida = turmas.filter(
        turma => turma.turma_id === event.target.value
      );

      dispatch(salvarTurmaSelecionada(turmaEscolhida[0]));
      dispatch(requestMaterias({ turma: turmaEscolhida[0].turma_id }));
    };

    const onChangeMateria = event => {
      let materiaEscolhida = null;
      Object.keys(materias).map(status => {
        const materiaFiltered = materias[status].filter(
          materia => materia.materia_id === event.target.value
        );

        if (materiaFiltered.length) {
          materiaEscolhida = materiaFiltered[0]; //eslint-disable-line
        }
      });

      dispatch(salvarMateriaSelecionada(materiaEscolhida));
      dispatch(changeOpenMenu(false));
    };

    const renderProfessoresSelecionados = () => {
      const descricoes = [];
      professores
        .filter(professor =>
          professoresSelecionados.includes(professor.professor_id)
        )
        .map(professor => descricoes.push(professor.professor));

      return descricoes.join(', ');
    };

    const handleChangeProfessores = event => {
      dispatch(saveProfessoresSelecionados(event.target.value));
    };

    const handleClickExit = () => {
      dispatch(saveProfessoresSelecionados([]));
      dispatch(salvarMateriaSelecionada(null));
      dispatch(salvarTurmaSelecionada(null));
      history.push('/');
    };

    const getStatusMateriaLabel = status => {
      switch (Number(status)) {
        case 2:
          return 'Não Iniciadas';
        case 3:
          return 'Finalizadas';
        default:
          return 'Em Andamento';
      }
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={classes.paper}>
              <Image
                src={imgPautas}
                className={classes.ilustracao}
                style={{ height: '100%' }}
              />
              <Typography color="secondary" variant="body1" align="center">
                Para criar uma pauta de chamada, primeiro você precisa escolher
                uma turma e uma matéria
              </Typography>
              {pauta.lancarcomooutrousuario && (
                <FormControl fullWidth variant="filled" margin="normal">
                  <InputLabel id="label-professor">Professor</InputLabel>
                  <Select
                    name="professores"
                    labelId="label-professor"
                    onChange={handleChangeProfessores}
                    multiple
                    defaultValue={professoresSelecionados}
                    input={<FilledInput />}
                    renderValue={() => renderProfessoresSelecionados()}
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                    {professores &&
                      professores.map(professor => (
                        <MenuItem
                          value={professor.professor_id}
                          key={professor.professor_id}
                        >
                          <Checkbox
                            checked={
                              professoresSelecionados.indexOf(
                                professor.professor_id
                              ) > -1
                            }
                          />
                          <ListItemText primary={professor.professor} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              <FormControl fullWidth variant="filled" margin="normal">
                <InputLabel id="label-turma">Turma</InputLabel>
                <Select
                  labelId="label-turma"
                  onChange={onChangeTurma}
                  defaultValue={
                    (turmaSelecionada && turmaSelecionada.turma_id) || ''
                  }
                >
                  <MenuItem value="" disabled>
                    Selecione
                  </MenuItem>
                  {turmas &&
                    turmas
                      .filter(
                        turma =>
                          pauta.lancarcomooutrousuario ||
                          turma.professor_id === pessoa
                      )
                      .filter(
                        turma =>
                          turma.professor_id &&
                          professoresIDs.includes(turma.professor_id) &&
                          (!professoresSelecionados.length ||
                            professoresSelecionados.includes(
                              turma.professor_id
                            ))
                      )
                      .map(turma => (
                        <MenuItem value={turma.turma_id} key={turma.turma_id}>
                          {turma.nome}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="filled" margin="normal">
                <InputLabel id="label-turma">Matéria</InputLabel>
                <Select
                  labelId="label-turma"
                  onChange={onChangeMateria}
                  defaultValue={
                    (materiaSelecionada && materiaSelecionada.materia_id) || ''
                  }
                >
                  <MenuItem value="" disabled>
                    Selecione
                  </MenuItem>
                  {materias && Object.keys(materias).length && (
                    <ListItemText className={classes.statusMateria}>
                      {getStatusMateriaLabel(Object.keys(materias)[0])}
                    </ListItemText>
                  )}
                  {materias && Object.keys(materias).length && <Divider />}
                  {materias &&
                    Object.keys(materias).length &&
                    materias[Object.keys(materias)[0]].map(materia => (
                      <MenuItem
                        value={materia.materia_id}
                        key={materia.materia_id}
                      >
                        {materia.materia}
                      </MenuItem>
                    ))}
                  {materias && Object.keys(materias).length > 1 && (
                    <ListItemText className={classes.statusMateria}>
                      {getStatusMateriaLabel(Object.keys(materias)[1])}
                    </ListItemText>
                  )}
                  {materias && Object.keys(materias).length > 1 && <Divider />}
                  {materias &&
                    Object.keys(materias).length > 1 &&
                    materias[Object.keys(materias)[1]].map(materia => (
                      <MenuItem
                        value={materia.materia_id}
                        key={materia.materia_id}
                      >
                        {materia.materia}
                      </MenuItem>
                    ))}
                  {materias && Object.keys(materias).length > 2 && (
                    <ListItemText className={classes.statusMateria}>
                      {getStatusMateriaLabel(Object.keys(materias)[2])}
                    </ListItemText>
                  )}
                  {materias && Object.keys(materias).length > 2 && <Divider />}
                  {materias &&
                    Object.keys(materias).length > 2 &&
                    materias[Object.keys(materias)[2]].map(materia => (
                      <MenuItem
                        value={materia.materia_id}
                        key={materia.materia_id}
                      >
                        {materia.materia}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleClickExit}
              >
                Sair
              </Button>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } catch (error) {
    console.log('302', error);
  }
}

ModalSelectTurma.propTypes = {
  openModal: PropTypes.bool.isRequired,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Fade,
  Checkbox,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { ChevronRight, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '2px',
  },
  treeView: {
    maxHeight: '300px',
    overflow: 'auto',
  },
  description: {
    margin: '10px 0',
  },
  buttons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function TreeviewUnidadesModal({
  showTree,
  handleUnidadesSelecionadas,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(showTree);
  const [selected, setSelected] = useState([]);
  const unidadesVideoaula = useSelector(
    state => state.permissoes.unidades_videoaula
  );
  const [unidades] = useState({
    unidade: 'Unidades',
    unidade_id: 'root',
    children: unidadesVideoaula,
  });

  const handleClose = () => {
    setOpen(false);
  };

  function getChildById(node, id) {
    let array = [];

    function getAllChild(nodes) {
      if (nodes === null) {
        return [];
      }

      array.push(nodes.unidade_id);

      if (Array.isArray(nodes.children)) {
        nodes.children.forEach(eachNode => {
          array = [...array, ...getAllChild(eachNode)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(nodes, idNodes) {
      if (idNodes === 'root') {
        return nodes;
      }

      if (nodes.unidade_id === idNodes) {
        return nodes;
      }
      if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach(eachNode => {
          if (!!getNodeById(eachNode, id)) {
            result = getNodeById(eachNode, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, id));
  }

  function getOnChange(checked, nodes) {
    const allNode = getChildById(unidades, nodes.unidade_id);
    let array = checked
      ? [...selected, ...allNode]
      : selected.filter(value => !allNode.includes(value));

    array = array.filter((v, i) => array.indexOf(v) === i);

    setSelected(array);
  }

  const renderTree = nodes => (
    <TreeItem
      key={nodes.unidade_id}
      nodeId={nodes.unidade_id}
      label={
        <FormControlLabel
          control={
            <Checkbox
              checked={selected.some(item => item === nodes.unidade_id)}
              onChange={event =>
                getOnChange(event.currentTarget.checked, nodes)
              }
              onClick={e => e.stopPropagation()}
            />
          }
          label={<>{nodes.unidade}</>}
          key={nodes.unidade_id}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map(node => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Replicar videoaula</h2>
            <p
              id="transition-modal-description"
              className={classes.description}
            >
              Selecione as unidades que também receberão esta videoaula
            </p>
            <TreeView
              defaultCollapseIcon={<ExpandMore />}
              defaultExpanded={['root']}
              defaultExpandIcon={<ChevronRight />}
              className={classes.treeView}
            >
              {renderTree(unidades)}
            </TreeView>
            <div className={classes.buttons}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  handleUnidadesSelecionadas(selected);
                  handleClose();
                }}
              >
                Enviar
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

TreeviewUnidadesModal.propTypes = {
  showTree: PropTypes.bool,
  handleUnidadesSelecionadas: PropTypes.func.isRequired,
};

TreeviewUnidadesModal.defaultProps = {
  showTree: true,
};

import React, { useRef, useEffect } from 'react';
import { useField } from '@rocketseat/unform';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

export default function CustomCheckbox({ name, defaultChecked, ...rest }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'checked',
      });
    }
  }, [fieldName, registerField]); //eslint-disable-line

  return (
    <Checkbox
      inputRef={ref}
      error={!!error}
      helperText={error}
      defaultChecked={!!defaultValue || !!defaultChecked}
      {...rest}
    />
  );
}

CustomCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
};

CustomCheckbox.defaultProps = {
  defaultChecked: false,
};

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Formulario from '~/components/VideoAula/FormularioStore';
import Breadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 0 0',
    color: '#4D5884',
  },
  subtitle: {
    margin: '0 0 19px 0',
    color: '#868CAB',
  },
  card: {
    padding: '30px',
  },
});

export default function Novo({ match }) {
  const classes = useStyles();
  const { live } = match.params;

  return (
    <>
      <Breadcrumbs categorias={['Videoaula']} funcionalidade="Nova Videoaula" />
      <Typography variant="h5" className={classes.title}>
        Nova videoaula
      </Typography>
      <Typography variant="h6" className={classes.subtitle}>
        Preencha os dados a seguir para iniciar a videoaula
      </Typography>
      <Card className={classes.card}>
        <Formulario live={!!parseInt(live, 10)} />
      </Card>
    </>
  );
}

Novo.propTypes = {
  match: PropTypes.shape().isRequired,
};

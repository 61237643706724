export function requestProfessores() {
  return {
    type: '@professores/REQUEST_PROFESSORES',
  };
}

export function requestProfessoresSuccess(professores) {
  return {
    type: '@professores/REQUEST_PROFESSORES_SUCCESS',
    payload: { professores },
  };
}

export function requestProfessoresFailure() {
  return {
    type: '@professores/REQUEST_PROFESSORES_FAILURE',
  };
}

import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { fontesRequestSuccess, fontesRequestFailure } from './actions';

export function* request({ payload }) {
  try {
    const { unidade_id } = payload;

    const response = yield call(api.get, `fontes/${unidade_id}`);

    yield put(fontesRequestSuccess(response.data));
  } catch (err) {
    toast.error('Falha na requisição, contacte o suporte.');
    yield put(fontesRequestFailure());
  }
}

export default all([takeLatest('@fontes/FONTES_REQUEST', request)]);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import DataGrid, {
  Column,
  RowDragging,
  Scrolling,
  Lookup,
  Sorting,
  Button as DxButton,
} from 'devextreme-react/data-grid';

import QuestaoForm from './QuestaoForm';

const useStyles = makeStyles(theme => ({
  questions: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    borderRadius: '200px',
    width: '275px',
    height: '40px',
    marginTop: '17px',
  },
}));

export default function Questoes({
  openForm,
  questions,
  setQuestions,
  addQuestion,
  hasNewQuestion,
  setHasNewQuestion,
}) {
  const classes = useStyles();

  const [question, setQuestion] = useState({});
  const [actualQuestion, setActualQuestion] = useState(null);

  const updateQuestions = updatedQuestion => {
    setHasNewQuestion(false);
    const newQuestions = [...questions];
    newQuestions[updatedQuestion.id - 1] = updatedQuestion;
    setQuestions(newQuestions);
  };

  useEffect(() => {
    const configQuestion = async () => {
      if (actualQuestion) {
        const questionConfig = questions.find(
          query => query.id === actualQuestion
        );

        setQuestion(questionConfig);
        setHasNewQuestion(false);
      }
    };

    if (openForm && !hasNewQuestion) {
      if (!questions.length) {
        setQuestion({});
        setActualQuestion(null);
        setHasNewQuestion(false);
      } else {
        configQuestion();
      }
    }
  }, [questions]); //eslint-disable-line

  useEffect(() => {
    const configQuestion = async () => {
      const questionConfig =
        questions.length > 0 && questions[questions.length - 1];
      await setQuestion({});

      setQuestion(questionConfig);
      setActualQuestion(questionConfig.id);
    };

    if (hasNewQuestion && questions.length) {
      configQuestion();
    }
  }, [hasNewQuestion]); //eslint-disable-line

  const onReorder = e => {
    const newOrderedQuestions = [...questions];

    newOrderedQuestions.splice(e.fromIndex, 1);
    newOrderedQuestions.splice(e.toIndex, 0, e.itemData);

    newOrderedQuestions.map((questionMap, index) => {
      questionMap.id = index + 1;
    });

    setQuestions(newOrderedQuestions);
  };

  const handleClickEdit = async questionToEdit => {
    await setQuestion({});
    setQuestion(questionToEdit);
    setActualQuestion(questionToEdit.id);
    setHasNewQuestion(false);
  };

  const deleteQuestion = questionToDelete => {
    const newQuestions = [...questions];
    newQuestions.splice(questionToDelete.id - 1, 1);
    newQuestions.map((questionMap, index) => {
      questionMap.id = index + 1;
    });
    setQuestions(newQuestions);
    setHasNewQuestion(false);
    setActualQuestion(null);
    setQuestion({});
  };

  return (
    <div className={classes.questions}>
      <DataGrid
        dataSource={questions}
        keyExpr="id"
        showBorders
        noDataText="Clique no botão abaixo para criar uma pergunta"
      >
        <RowDragging allowReordering onReorder={onReorder} showDragIcons />
        <Scrolling mode="virtual" />
        <Sorting mode="none" />
        <Column dataField="id" caption="#" width="50" />
        <Column dataField="enunciado" caption="Enunciado">
          <Lookup
            dataSource={questions}
            valueExpr="enunciado"
            displayExpr="enunciado"
          />
        </Column>
        <Column dataField="tipoDescricao" caption="Tipo da Questão" width="120">
          <Lookup
            dataSource={questions}
            valueExpr="tipoDescricao"
            displayExpr="tipoDescricao"
          />
        </Column>
        <Column dataField="peso" caption="Peso" width="60">
          <Lookup dataSource={questions} valueExpr="peso" displayExpr="peso" />
        </Column>
        <Column caption="" type="buttons">
          <DxButton
            render={e => {
              return (
                <div>
                  <IconButton onClick={() => handleClickEdit(e.data)}>
                    <Edit color="primary" />
                  </IconButton>
                </div>
              );
            }}
          />
        </Column>
      </DataGrid>
      {questions.length === 0 && (
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={addQuestion}
        >
          Criar uma pergunta
        </Button>
      )}
      {Object.keys(question).length !== 0 && (
        <QuestaoForm
          updateQuestions={updateQuestions}
          question={question}
          deleteQuestion={deleteQuestion}
        />
      )}
    </div>
  );
}

Questoes.propTypes = {
  openForm: PropTypes.bool.isRequired,
  questions: PropTypes.shape(),
  setQuestions: PropTypes.func.isRequired,
  addQuestion: PropTypes.func.isRequired,
  hasNewQuestion: PropTypes.bool.isRequired,
  setHasNewQuestion: PropTypes.func.isRequired,
};

Questoes.defaultProps = {
  questions: null,
};

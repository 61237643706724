import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Image from 'material-ui-image';
import Grid from '@material-ui/core/Grid';
import bg from '~/assets/background_login.png';
import Powered from '~/assets/powered.svg';

const useStyles = makeStyles({
  content: {
    height: '100%',
    display: 'flex',
  },
  container: {
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    marginBottom: '15px',
  },
  powered: {
    color: '#fff',
    marginRight: '5px',
  },
});

export default function AuthLayout({ children }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#FF9239',
      },
    },
  });

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <div />
        <Container maxWidth="lg" component="main">
          <Grid container justifyContent="center" className={classes.content}>
            <Grid item xs={12} sm={10} md={7}>
              {children}
            </Grid>
          </Grid>
        </Container>
        <div className={classes.footer}>
          <Image
            src={Powered}
            alt="F10"
            style={{ backgroundColor: 'none', paddingTop: 'unset' }}
            imageStyle={{ position: 'unset' }}
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export function fontesRequest(unidade_id) {
  return {
    type: '@fontes/FONTES_REQUEST',
    payload: { unidade_id },
  };
}

export function fontesRequestSuccess(fontes) {
  return {
    type: '@fontes/FONTES_REQUEST_SUCCESS',
    payload: { fontes },
  };
}

export function fontesRequestFailure() {
  return {
    type: '@fontes/FONTES_REQUEST_FAILURE',
  };
}

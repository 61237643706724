import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  cursosRequestSuccess,
  cursosRequestFailure,
  cursosRequest,
} from './actions';

export function* request() {
  try {
    const response = yield call(api.get, 'cursos');

    yield put(cursosRequestSuccess(response.data));
  } catch (err) {
    if (err.response) {
      if (
        err.response.data.error ===
        'Ocorreu um erro ao tentar configurar sessão.'
      ) {
        yield put(cursosRequest());
      }
    } else {
      toast.error('Falha na requisição, contacte o suporte.');
      yield put(cursosRequestFailure());
    }
  }
}

export default all([takeLatest('@cursos/CURSOS_REQUEST', request)]);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core';
import { CommentOutlined } from '@material-ui/icons';

import { toast } from 'react-toastify';
import { updateNovaPauta } from '~/store/modules/pautas/actions';

import { useStyles } from './styles';

const MAX_CARACTERES_ALLOWED = 50;

export default function ModalFormAnotacoesAluno({ aluno, hasAnotacoes }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { largeScreen } = useSelector(state => state.layout);

  const { novaPauta } = useSelector(state => state.pautas);

  const [anotacoes, setAnotacoes] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      const indexAluno = novaPauta.alunos.findIndex(
        alunoPauta => alunoPauta.id === aluno.id
      );
      if (indexAluno > -1) {
        setAnotacoes(novaPauta.alunos[indexAluno].obs);
      }
    }
  }, [open]); // eslint-disable-line

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickSave = () => {
    if (anotacoes.length > MAX_CARACTERES_ALLOWED) {
      toast.warn(
        `O formulário excedeu máximo de ${MAX_CARACTERES_ALLOWED} caracteres permitidos.`
      );
      return;
    }

    const indexAluno = novaPauta.alunos.findIndex(
      alunoPauta => alunoPauta.aluno_id === aluno.id
    );
    const novaPautaAlunos = [...novaPauta.alunos];
    novaPautaAlunos[indexAluno] = {
      ...novaPauta.alunos[indexAluno],
      obs: anotacoes || null,
    };

    dispatch(
      updateNovaPauta({
        ...novaPauta,
        alunos: novaPautaAlunos,
      })
    );
    setOpen(false);
  };

  return (
    <div>
      {(largeScreen && (
        <IconButton
          onClick={handleClickOpen}
          color={hasAnotacoes ? 'primary' : 'secondary'}
        >
          <CommentOutlined />
        </IconButton>
      )) || (
        <Button
          onClick={handleClickOpen}
          color="primary"
          variant="contained"
          size="small"
          fullWidth
        >
          Adicionar Comentário
        </Button>
      )}

      <Dialog
        open={open}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Anotações sobre a presença
        </DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            value={anotacoes}
            variant="filled"
            label="Comentário"
            placeholder="Digite um comentário"
            multiline
            minRows={5}
            onChange={event => setAnotacoes(event.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            autoFocus
            error={anotacoes && anotacoes.length > MAX_CARACTERES_ALLOWED}
            helperText={`${(anotacoes && anotacoes.length) ||
              0} / ${MAX_CARACTERES_ALLOWED} caracteres permitidos`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancelar
          </Button>
          <Button
            onClick={onClickSave}
            color="primary"
            variant="contained"
            disabled={anotacoes && anotacoes.length > MAX_CARACTERES_ALLOWED}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ModalFormAnotacoesAluno.propTypes = {
  aluno: PropTypes.shape().isRequired,
  hasAnotacoes: PropTypes.bool,
};

ModalFormAnotacoesAluno.defaultProps = {
  hasAnotacoes: false,
};

import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import {
  InputLabel,
  Select,
  FormHelperText,
  FormControl,
} from '@material-ui/core';

export default function FormSelect({ name, label, children, ...props }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [valueX, setValueX] = useState(defaultValue || undefined);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'value',
        clearValue: valueRef => {
          valueRef.clearValue();
          setValueX(undefined);
        },
      });
    }
  }, [ref.current, registerField]); //eslint-disable-line

  return (
    <FormControl variant="filled" fullWidth error={!!error}>
      <InputLabel id={`${name}-filled-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-filled-label`}
        id={`${name}-filled`}
        name={name}
        defaultValue={valueX}
        inputRef={ref}
        {...props}
      >
        {children}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

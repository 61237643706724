import React from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { Typography } from '@material-ui/core';
import Image from 'material-ui-image';

import imgConteudoDesativado from '~/assets/ilustra_conteudo_encerrado.svg';

export default function Video() {
  const transmissao = useSelector(state => state.transmissao.aula);
  const isConteudoDesativado = () => {
    return transmissao.status === 4 || transmissao.status_id === 4;
  };

  const showVideo = () => {
    if (
      isConteudoDesativado() ||
      (!transmissao.url && !transmissao.streaming_url)
    ) {
      return (
        <>
          <Image
            src={imgConteudoDesativado}
            style={{
              display: 'flex',
              paddingTop: 'unset',
              backgroundColor: 'none',
              justifyContent: 'center',
            }}
            imageStyle={{
              width: 'unset',
              height: 'unset',
              position: 'unset',
            }}
          />
          <Typography
            color="primary"
            variant="h5"
            component="div"
            align="center"
          >
            Fim da Transmissão
          </Typography>
          <Typography
            color="secondary"
            variant="body1"
            component="div"
            align="center"
          >
            A transmissão da videoaula foi encerrada ;)
          </Typography>
        </>
      );
    }

    return (
      <ReactPlayer
        url={transmissao.url || transmissao.streaming_url}
        controls
      />
    );
  };

  return <>{showVideo()}</>;
}

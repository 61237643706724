import { darken, lighten } from 'polished';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    width: '442px',
    height: '454px',
    padding: '34px 42px',
  },
  container: {
    height: '100%',
  },
  tituloModal: {
    color: '#4D5884',
    fontSize: '16px',
    marginBottom: '10px',
  },
  tituloAtividade: {
    fontSize: '19px',
    color: '#50A6FF',
  },
  info: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  label: {
    color: '#50A6FF',
    letterSpacing: '1.5px',
    fontSize: '10px',
  },
  value: {
    color: '#868CAB',
    fontSize: '14px',
  },
  file: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(80, 166, 255, 0.4)',
    borderRadius: '8px',
    maxHeight: '39px',
    padding: '5px 15px',
    color: '#50A6FF',
    fontSize: '14px',
    margin: '5px 2px 0 0',
  },
  fileName: {
    fontWeight: 'bold',
  },
  btRemover: {
    color: '#50A6FF',
  },
  containerFiles: {
    flex: 1,
    overflowY: 'auto',
  },
  btEntregar: {
    color: '#fff',
    background: '#50A6FF',
    borderRadius: '200px',
    alignSelf: 'center',
    '&:hover': {
      background: `${darken(0.1, '#50A6FF')}`,
    },
  },
  dropZone: {
    border: `2px dashed ${lighten(0.2, '#868CAB')}`,
    borderRadius: '10px',
    width: '364px',
    height: '185px',
    margin: '15px 0',
    cursor: 'pointer',
    background: '#fff',
  },
  internDrop: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  aside: {
    color: '#999',
    textAlign: 'center',
  },
  paside: {
    color: '#50A6FF',
    fontWeight: 'bold',
  },
  pasideu: {
    color: '#333',
  },
  iconUpload: {
    color: '#50A6FF',
    fontSize: '110px',
  },
  btAnexo: {
    color: '#fff',
    borderRadius: '200px',
    marginBottom: '20px',
  },
}));

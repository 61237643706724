import React from 'react';
// import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import ReactPlayer from 'react-player';
import Breadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 20px',
  },
  player: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function Aula() {
  const classes = useStyles();

  return (
    <>
      <Breadcrumbs categorias={['Acadêmico']} funcionalidade="Aula" />
      <div className={classes.title}>
        <Typography variant="h5">Videoaula</Typography>
        {/* <Button
          variant="contained"
          color="primary"
          className={classes.novo}
          type="button"
          onClick={() => {
            history.push('/novo-contrato');
          }}
        >
          Novo Contrato
        </Button> */}
      </div>
      <div className={classes.player}>
        {/* <ReactPlayer url={youtube.stream} playing />
        <Typography variant="h6" color="primary">
          {youtube.title}
        </Typography> */}
      </div>
    </>
  );
}

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import {
  Modal,
  Grid,
  Backdrop,
  Fade,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import { DeleteOutlineOutlined, CloudUpload } from '@material-ui/icons';
import * as Yup from 'yup';

import { useStyles } from './styles';

Yup.setLocale({
  mixed: {
    default: 'Tipo de arquivo inválido',
  },
  string: {
    oneOf: 'Tipo de arquivo inválido',
  },
  number: {
    max: 'Arquivo excede o tamanho máximo permitido',
  },
});

const schema = Yup.object().shape({
  size: Yup.number()
    .max(3072)
    .required(),
  type: Yup.string().oneOf(
    ['video/mp4', 'video/mkv', 'video/x-matroska'],
    'Tipo de arquivo inválido'
  ),
});

export default function ModalUpload({ setFile }) {
  const classes = useStyles();

  const [arquivo, setArquivo] = useState(null);
  const [open, setOpen] = useState(false);

  async function validarArquivoUpdado() {
    const { size: tamanho, type } = arquivo;
    const size = tamanho / 1024 / 1024;

    schema.validate({ size, type }).catch(error => {
      setArquivo(null);
      toast.error(error.message);
    });
  }

  if (arquivo) {
    validarArquivoUpdado();
  }

  const onDrop = useCallback(acceptedFile => {
    setArquivo(acceptedFile[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  async function onClickHandler() {
    setFile(arquivo);
    setArquivo(null);
    setOpen(false);
  }

  const getHandledFileName = () => {
    if (arquivo.name.length > 28) {
      return `${arquivo.name.substring(0, 10)} ... ${arquivo.name.substring(
        arquivo.name.length - 10
      )}`;
    }

    return arquivo.name;
  };

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setOpen(true);
        }}
        variant="contained"
        color="primary"
        fullWidth
        className={classes.btAnexo}
      >
        Anexar Arquivo
      </Button>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12} className={classes.info}>
                  <div>
                    <Typography
                      variant="h5"
                      className={classes.tituloAtividade}
                    >
                      Arquivo de vídeo
                    </Typography>
                    <Typography
                      variant="h6"
                      className={classes.tituloModal}
                      component="p"
                    >
                      Faça o upload do arquivo aqui.
                    </Typography>
                  </div>
                  {!arquivo && (
                    <div className={classes.dropZone}>
                      <div className={classes.internDrop} {...getRootProps()}>
                        <CloudUpload className={classes.iconUpload} />
                        <input {...getInputProps()} />
                        <aside className={classes.aside}>
                          <p className={classes.paside}>
                            Arraste seu arquivo ou{' '}
                            <u className={classes.pasideu}>busque</u>
                          </p>
                          <small>Suportamos arquivos até 3GB.</small>
                        </aside>
                      </div>
                    </div>
                  )}
                  {arquivo && (
                    <Grid container className={classes.containerFiles}>
                      <Grid item xs={12} className={classes.file}>
                        <Typography className={classes.fileName}>
                          {getHandledFileName()}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => setArquivo(null)}
                        >
                          <DeleteOutlineOutlined
                            className={classes.btRemover}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    className={classes.btEntregar}
                    onClick={() => onClickHandler()}
                  >
                    ADICIONAR A AULA
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
}

ModalUpload.propTypes = {
  setFile: PropTypes.func.isRequired,
};

import { combineReducers } from 'redux';

import auth from './auth/reducer';
import professor from './professor/reducer';
import user from './user/reducer';
import permissoes from './permissoes/reducer';
import unidade from './unidade/reducer';
import cursos from './cursos/reducer';
import fontes from './fontes/reducer';
import lookup from './lookup/reducer';
import turmas from './turmas/reducer';
import pautas from './pautas/reducer';
import atividades from './atividades/reducer';
import videoaula from './videoaula/reducer';
import transmissao from './transmissao/reducer';
import professores from './professores/reducer';
import layout from './layout/reducer';

export default combineReducers({
  auth,
  professor,
  user,
  permissoes,
  unidade,
  fontes,
  cursos,
  lookup,
  turmas,
  pautas,
  atividades,
  videoaula,
  transmissao,
  professores,
  layout,
});

import produce from 'immer';

const INITIAL_STATE = {
  unidade_id: null,
  unidade: null,
};

export default function unidade(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@unidade/UNIDADE_SELECIONADA_REQUEST': {
        draft.unidade_id = action.payload.unidade_id;
        draft.unidade = action.payload.unidade;
        break;
      }
      case '@unidade/UNIDADE_SELECIONADA_FAILURE': {
        draft.unidade_id = null;
        draft.unidade = null;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.unidade_id = null;
        draft.unidade = null;
        break;
      }
      default:
    }
  });
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Fade,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  MenuItem,
} from '@material-ui/core';
import {
  AddCircleOutlineOutlined,
  DeleteOutlineOutlined,
} from '@material-ui/icons';

import Select from '~/components/CustomUi/FormSelect';
import DatePicker from '~/components/CustomUi/CustomDatePicker';
import GridArquivosUpload from '~/components/Atividades/ModalFormAtividade/GridArquivosUpload';
import ModalUpload from '~/components/Atividades/ModalFormAtividade/ModalUpload';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: theme.spacing(2),
    borderRadius: '10px',
    width: '555px',
    maxHeight: '640px',
    overflow: 'auto',
  },
  tituloModal: {
    color: '#50A6FF',
  },
  complementos: {
    height: '104px',
    maxHeight: '104px',
    overflow: 'auto',
  },
  complemento: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    background: 'rgba(80, 166, 255, 0.4)',
    alignItems: 'center',
    height: '39px',
    borderRadius: '8px',
    marginBottom: '5px',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '22px',
  },
  linkLabel: {
    color: '#868CAB',
    marginRight: '10px',
    fontWeight: 'bold',
  },
  linkValue: {
    fontWeight: 'bold',
    color: '#50A6FF',
  },
  btRemoveComplemento: {
    color: '#50A6FF',
    marginRight: '10px',
  },
  btAdd: {
    marginLeft: '10px',
    color: '#fff',
    borderRadius: '200px',
  },
  btCancelar: {
    borderRadius: '200px',
  },
  buttons: {
    display: 'flex',
  },
}));

const schema = Yup.object().shape({
  tipo_atividade_id: Yup.number()
    .positive()
    .nullable(true)
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('O tipo da atividade é obrigatório'),
  tipo_resposta_id: Yup.number()
    .positive()
    .nullable(true)
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  tipo_nota: Yup.number()
    .positive()
    .nullable(true)
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  inicio: Yup.string(),
  prazo: Yup.string(),
});

export default function ModalEditarAtividade({
  atualizarAtividade,
  initialData,
  openModal,
  handleClose,
}) {
  const classes = useStyles();
  const lookup = useSelector(state => state.lookup);
  const unidade = useSelector(state => state.unidade.unidade_id);

  const [atividade, setAtividade] = useState(initialData);
  const [linksComplementos, setLinksComplementos] = useState([]);
  const [linkDigitado, setLinkDigitado] = useState(null);
  const [arquivosUpload, setArquivosUpload] = useState(
    (initialData.files && [...initialData.files]) || []
  );
  const [tipoAtividadeEscolhida, setTipoAtividadeEscolhida] = useState(null);
  const [tipoNotaEscolhida, setTipoNotaEscolhida] = useState(null);
  const [tipoRespostaEscolhida, setTipoRespostaEscolhida] = useState(null);

  function adicionarLink(link) {
    return !!link && setLinksComplementos([...linksComplementos, link]);
  }

  function removerComplemento(link) {
    const index = linksComplementos.indexOf(link);
    const newArray = [...linksComplementos];
    newArray.splice(index, 1);
    setLinksComplementos(newArray);
  }

  function handleSubmitAtividade({
    tipo_atividade_id,
    tipo_resposta_id,
    tipo_nota,
    inicio,
    prazo,
  }) {
    let inicioSplitted = null;
    if (inicio) {
      inicioSplitted = inicio.split('/');
      inicio = `${inicioSplitted[1]}-${inicioSplitted[0]}-${inicioSplitted[2]} 00:00:00`;
    }

    let prazoSplitted = null;
    if (prazo) {
      prazoSplitted = prazo.split('/');
      prazo = `${prazoSplitted[1]}-${prazoSplitted[0]}-${prazoSplitted[2]} 00:00:00`;
    }

    atualizarAtividade({
      ...atividade,
      tipo_atividade_id: tipoAtividadeEscolhida || tipo_atividade_id,
      tipo_resposta_id: tipoRespostaEscolhida || tipo_resposta_id,
      tipo_nota: tipoNotaEscolhida || tipo_nota,
      inicio,
      prazo,
      anexos: [...atividade.anexos, ...linksComplementos],
      files: arquivosUpload,
    });

    handleClose();
  }

  const getExtension = extension => {
    switch (extension) {
      case 'application/pdf':
        return 'pdf';
      case 'image/png':
        return 'png';
      case 'image/jpeg':
        return 'jpeg';
      case 'image/jpg':
        return 'jpg';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'pptx';
      default:
        throw new Error('Invalid type.');
    }
  };

  function handleAddArquivos(arquivo) {
    const fileName = `${new Date().getTime()}${Math.random(9999)}`;
    const unidadeFormatada = `0000${unidade}`.slice(-5);
    const extension = getExtension(arquivo.type);
    arquivo.downloadUrl = `${process.env.REACT_APP_ORACLE_URL_DOWNLOAD_ATIVIDADE}${unidadeFormatada}%2Fatividades%2F${atividade.atividade_id}%2F${fileName}.${extension}`;
    arquivo.fileName = `${fileName}.${extension}`;
    setArquivosUpload([...arquivosUpload, arquivo]);
  }

  function handleRemoverArquivo(arquivo) {
    const index = arquivosUpload.indexOf(arquivo);
    const newArray = [...arquivosUpload];
    newArray.splice(index, 1);
    setArquivosUpload(newArray);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={() => {
          handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            {atividade && (
              <Form
                onSubmit={handleSubmitAtividade}
                schema={schema}
                initialData={atividade}
              >
                <Typography variant="h6" className={classes.tituloModal}>
                  Adicionar atividade
                </Typography>
                <Grid container spacing={3}>
                  {initialData && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Select name="atividade_id" label="Atividade">
                          <MenuItem value={initialData.atividade_id}>
                            {initialData.atividade}
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Select
                          name="tipo_atividade_id"
                          label="Tipo de atividade"
                          onChange={event => {
                            const {
                              descricao,
                            } = lookup.tipoAtividadeLancamento.filter(
                              filtered => filtered.intkey === event.target.value
                            )[0];

                            setTipoAtividadeEscolhida(event.target.value);
                            setAtividade({
                              ...atividade,
                              [event.target.name]: event.target.value,
                              tipo_atividade: descricao,
                            });
                          }}
                        >
                          <MenuItem value="">
                            <em>Selecione</em>
                          </MenuItem>
                          {lookup.tipoAtividadeLancamento.map(tipo => (
                            <MenuItem key={tipo.intkey} value={tipo.intkey}>
                              {tipo.descricao}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Select
                          name="tipo_resposta_id"
                          label="Tipo de resposta"
                          onChange={event => {
                            const {
                              descricao,
                            } = lookup.tipoRespostaLancamento.filter(
                              filtered => filtered.intkey === event.target.value
                            )[0];

                            setTipoRespostaEscolhida(event.target.value);

                            setAtividade({
                              ...atividade,
                              [event.target.name]: event.target.value,
                              tipo_resposta: descricao,
                            });
                          }}
                        >
                          <MenuItem value="">
                            <em>Selecione</em>
                          </MenuItem>
                          {lookup.tipoRespostaLancamento.map(tipo => (
                            <MenuItem key={tipo.intkey} value={tipo.intkey}>
                              {tipo.descricao}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Select
                          name="tipo_nota"
                          label="Tipo de nota"
                          onChange={event => {
                            setTipoNotaEscolhida(event.target.value);
                            setAtividade({
                              ...atividade,
                              [event.target.name]: event.target.value,
                            });
                          }}
                        >
                          <MenuItem value="">
                            <em>Selecione</em>
                          </MenuItem>
                          {lookup.tipoNota.map(tipo => (
                            <MenuItem key={tipo.intkey} value={tipo.intkey}>
                              {tipo.descricao}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Ínicio"
                      name="inicio"
                      fullWidth
                      disablePast
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Prazo"
                      name="prazo"
                      fullWidth
                      disablePast
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="link"
                      label="Link"
                      placeholder="Digite um link"
                      variant="filled"
                      color="primary"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              type="button"
                              onClick={() => adicionarLink(linkDigitado)}
                            >
                              <AddCircleOutlineOutlined />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      onChange={e => {
                        setLinkDigitado(e.target.value);
                      }}
                    />
                  </Grid>
                  {!!linksComplementos.length && (
                    <Grid item xs={12} className={classes.complementos}>
                      {linksComplementos.map(link => (
                        <div
                          className={classes.complemento}
                          key={`${link}${Math.random(0, 9999)}`}
                        >
                          <div className={classes.link}>
                            <Typography
                              variant="body1"
                              className={classes.linkLabel}
                            >
                              Link
                            </Typography>
                            <Typography
                              variant="body1"
                              className={classes.linkValue}
                            >
                              {link.length > 40
                                ? `${link.substring(0, 40)}...`
                                : link}
                            </Typography>
                          </div>
                          <IconButton
                            className={classes.btRemoveComplemento}
                            size="small"
                            onClick={() => removerComplemento(link)}
                          >
                            <DeleteOutlineOutlined />
                          </IconButton>
                        </div>
                      ))}
                    </Grid>
                  )}
                  {atividade && (
                    <Grid item xs={12} className={classes.complementos}>
                      {atividade.anexos.map(link => (
                        <div
                          className={classes.complemento}
                          key={`${link}${Math.random(0, 9999)}`}
                        >
                          <div className={classes.link}>
                            <Typography
                              variant="body1"
                              className={classes.linkLabel}
                            >
                              Link
                            </Typography>
                            <Typography
                              variant="body1"
                              className={classes.linkValue}
                            >
                              {link.length > 40
                                ? `${link.substring(0, 40)}...`
                                : link}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </Grid>
                  )}
                  {arquivosUpload.length < 2 && (
                    <Grid item xs={12}>
                      <ModalUpload handleAddArquivos={handleAddArquivos} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <GridArquivosUpload
                      arquivos={arquivosUpload}
                      removerArquivo={handleRemoverArquivo}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.buttons}>
                    <Button
                      type="button"
                      onClick={() => {
                        handleClose();
                      }}
                      variant="contained"
                      color="secondary"
                      className={classes.btCancelar}
                      fullWidth
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.btAdd}
                      fullWidth
                    >
                      Adicionar Atividade
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalEditarAtividade.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  atualizarAtividade: PropTypes.func.isRequired,
  initialData: PropTypes.shape().isRequired,
};

import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 4),
    maxWidth: 487,
    borderRadius: '10px',
  },
  gridItem: {
    marginTop: theme.spacing(2),
  },
  button: {
    color: '#fff',
    borderRadius: '200px',
  },
  titulo: {
    marginBottom: theme.spacing(2),
  },
  caption: {
    letterSpacing: '1px',
  },
  info: {
    color: '#333',
  },
  gridItemButton: {
    marginTop: theme.spacing(4),
  },
}));

export default function ModalTurma({ setOpenModal, openModal, data }) {
  // const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Typography color="primary" variant="h4" className={classes.titulo}>
              {data.nome}
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <Typography
                  color="primary"
                  variant="caption"
                  component="p"
                  className={classes.caption}
                >
                  STATUS
                </Typography>
                <Typography
                  color="secondary"
                  variant="body1"
                  className={classes.info}
                >
                  Ativa
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <Typography
                  color="primary"
                  variant="caption"
                  component="p"
                  className={classes.caption}
                >
                  CURSO
                </Typography>
                <Typography
                  color="secondary"
                  variant="body1"
                  className={classes.info}
                >
                  Ativa
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <Typography
                  color="primary"
                  variant="caption"
                  component="p"
                  className={classes.caption}
                >
                  ALUNOS/VAGAS
                </Typography>
                <Typography
                  color="secondary"
                  variant="body1"
                  className={classes.info}
                >
                  Ativa
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <Typography
                  color="primary"
                  variant="caption"
                  component="p"
                  className={classes.caption}
                >
                  INÍCIO
                </Typography>
                <Typography
                  color="secondary"
                  variant="body1"
                  className={classes.info}
                >
                  Ativa
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography
                  color="primary"
                  variant="caption"
                  component="p"
                  className={classes.caption}
                >
                  PROGRESSO DO CURSO
                </Typography>
                <Typography
                  color="secondary"
                  variant="body1"
                  className={classes.info}
                >
                  Ativa
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <Typography
                  color="primary"
                  variant="caption"
                  component="p"
                  className={classes.caption}
                >
                  MATÉRIA ATUAL
                </Typography>
                <Typography
                  color="secondary"
                  variant="body1"
                  className={classes.info}
                >
                  {data.descricao}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <Typography
                  color="primary"
                  variant="caption"
                  component="p"
                  className={classes.caption}
                >
                  PROFESSOR
                </Typography>
                <Typography
                  color="secondary"
                  variant="body1"
                  className={classes.info}
                >
                  Ativa
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography
                  color="primary"
                  variant="caption"
                  component="p"
                  className={classes.caption}
                >
                  PROGRESSO DA MATÉRIA
                </Typography>
                <Typography
                  color="secondary"
                  variant="body1"
                  className={classes.info}
                >
                  Ativa
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.gridItemButton}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                >
                  INICIAR LIVE
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalTurma.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
};

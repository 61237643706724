import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Image from 'material-ui-image';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Videofront from './Videofront';
import Jitsi from './Jitsi';
import Hangouts from './Hangouts';
import Video from './Video';
import Live from './Live';

import imgConteudoPublicado from '~/assets/grafismo_assistir_videoconferencia.jpeg';

const useStyles = makeStyles(() => ({
  conteudo: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

export default function Transmissao({ hasUrlConf, setHasUrlConf }) {
  const classes = useStyles();
  const transmissao = useSelector(state => state.transmissao.aula);

  const [showConteudoPublicado, setShowConteudoPublicado] = useState(false);

  const isConteudoPublicado = () =>
    transmissao.status === 3 || transmissao.status_id === 3;

  const isConteudoDesativado = () => {
    const statusDesativado =
      transmissao.status === 4 || transmissao.status_id === 4;

    return (
      statusDesativado ||
      (isConteudoPublicado() && !transmissao.url && !hasUrlConf)
    );
  };

  const getConteudo = () => {
    if (isConteudoPublicado() && hasUrlConf && !showConteudoPublicado) {
      return (
        <Image
          src={imgConteudoPublicado}
          style={{
            display: 'flex',
            paddingTop: 'unset',
            cursor: 'pointer',
            justifyContent: 'center',
          }}
          imageStyle={{
            width: '640px',
            height: 'unset',
            position: 'absolute',
            cursos: 'pointer',
          }}
          onClick={() => setShowConteudoPublicado(true)}
        />
      );
    }

    switch (transmissao.tipo) {
      case 2:
        return <Live />;
      case 3:
        return <Hangouts />;
      case 4:
        return <Jitsi hasUrlConferencia={setHasUrlConf} />;
      case 5:
        return <Videofront hasUrlConferencia={setHasUrlConf} />;
      default:
        return <Video />;
    }
  };

  const getDescricao = () => {
    if (
      isConteudoDesativado() ||
      !showConteudoPublicado ||
      !transmissao.descricao
    ) {
      return;
    }

    return (
      <Grid item xs={12}>
        <Typography variant="caption" component="p" color="primary">
          DESCRIÇÃO
        </Typography>
        <Typography component="p">{transmissao.descricao}</Typography>
      </Grid>
    );
  };

  return (
    <>
      <Grid item xs={12} className={classes.conteudo}>
        {getConteudo()}
      </Grid>
      {getDescricao()}
    </>
  );
}

Transmissao.propTypes = {
  hasUrlConf: PropTypes.bool.isRequired,
  setHasUrlConf: PropTypes.func.isRequired,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import DataGrid, {
  Column,
  Button,
  GroupPanel,
  Grouping,
} from 'devextreme-react/data-grid';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt';

import { toast } from 'react-toastify';
import AtividadeTurmaAlunosModal from './AtividadeTurmaAlunosModal';

export default function AtividadeTurmas({ data }) {
  const [openModal, setOpenModal] = useState(false);
  const [atividadeTurmas] = useState(data.data.atividades || []);
  const [atividadeRealizadas, setAtividadesRealizadas] = useState(null);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function prepareCell(e) {
    if (e.rowType === 'data') {
      if (e.column.caption === 'DATA') {
        e.cellElement.innerText = '-';
        if (e.value) {
          const dataFormatada = format(new Date(e.value), 'dd/MM/yyyy', {
            locale: ptBr,
          });
          e.cellElement.innerText = dataFormatada;
        }
      }

      if (e.column.caption === 'PRAZO') {
        e.cellElement.innerText = '-';
        if (e.value) {
          const dataFormatada = format(new Date(e.value), 'dd/MM/yyyy', {
            locale: ptBr,
          });
          e.cellElement.innerText = dataFormatada;
        }
      }
      if (
        e.column.caption === 'MATÉRIA' ||
        e.column.caption === 'AULA' ||
        e.column.caption === 'TURMA'
      ) {
        e.cellElement.innerText = e.value || '-';
      }
    }
  }

  return (
    <>
      <DataGrid
        dataSource={atividadeTurmas}
        allowColumnReordering
        showBorders
        hoverStateEnabled
        noDataText="Essa atividade ainda não foi aplicada"
        onCellPrepared={e => {
          prepareCell(e);
        }}
      >
        <GroupPanel visible emptyPanelText="" />
        <Grouping autoExpandAll={false} />
        <Column caption="ID" dataField="id" visible={false} />
        <Column caption="DATA" dataField="inicio" format="dd/MM/yyyy" />
        <Column caption="MATÉRIA" dataField="materia" />
        <Column caption="TURMA" dataField="turma" />
        <Column caption="AULA" dataField="aula" />
        <Column caption="PROFESSOR" dataField="professor" />
        <Column caption="PRAZO" dataField="prazo" />
        <Column type="buttons">
          <Button
            render={e => {
              return (
                <IconButton
                  onClick={() => {
                    if (!e.data.alunos) {
                      toast.info(
                        'Não há respostas para a atividade selecionada.'
                      );
                      return;
                    }
                    setOpenModal(true);
                    setAtividadesRealizadas(e.data.alunos);
                  }}
                >
                  <ArrowForward color="primary" />
                </IconButton>
              );
            }}
          />
        </Column>
      </DataGrid>
      {atividadeRealizadas && openModal && (
        <AtividadeTurmaAlunosModal
          open={openModal}
          handleClose={handleCloseModal}
          atividadesRealizadas={atividadeRealizadas}
          atividadeLancada={atividadeTurmas}
        />
      )}
    </>
  );
}

AtividadeTurmas.propTypes = {
  data: PropTypes.shape().isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, LinearProgress, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
  rootBox: {
    width: '300px',
  },
}));

export default function ProgressBar({ progress }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open>
      <Box display="flex" alignItems="center" className={classes.rootBox}>
        <Box width="100%" mr={1}>
          <LinearProgress
            variant="determinate"
            value={progress}
            color="primary"
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textPrimary">
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>
    </Backdrop>
  );
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

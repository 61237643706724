import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Image from 'material-ui-image';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import Loading from '~/components/Loading';

import api from '~/services/api';

import imgPreviewConferencia from '~/assets/preview_conferencia.png';
import imgConteudoDesativado from '~/assets/ilustra_conteudo_encerrado.svg';

export default function Videofront({ hasUrlConferencia }) {
  const transmissao = useSelector(state => state.transmissao.aula);

  const [urlConferencia, setUrlConferencia] = useState(transmissao.url);
  const [isUrlVideofront, setIsUrlVideofront] = useState(false);
  const [showLoading, setLoading] = useState(false);

  const isConteudoPublicado = () =>
    transmissao.status === 3 || transmissao.status_id === 3;

  const isConteudoPublicadoSemUrl = () =>
    (transmissao.status === 3 || transmissao.status_id === 3) &&
    !urlConferencia;

  const isConteudoDesativado = () => {
    const statusDesativado =
      transmissao.status === 4 || transmissao.status_id === 4;

    return statusDesativado || isConteudoPublicadoSemUrl();
  };

  const isConteudoTransmitindo = () =>
    transmissao.status === 2 || transmissao.status_id === 2;

  useEffect(() => {
    async function setUrlVideofront() {
      try {
        setLoading(true);
        const response = await api.get(
          `url-meet/${transmissao.videoaula_id || transmissao.id}`
        );

        setUrlConferencia(response.data.url);
        setLoading(false);
        hasUrlConferencia(true);
      } catch (error) {
        setLoading(false);
        hasUrlConferencia(false);
      }
    }

    async function getUrlVideoteca() {
      try {
        setLoading(true);
        setUrlConferencia(transmissao.streaming_url); // atualiza o valor selecionado
        let videoRecorded =
          urlConferencia || transmissao.url || transmissao.streaming_url;

        if (!videoRecorded) {
          const responseCheck = await api.get(
            `check-record/${transmissao.videoaula_id || transmissao.id}`
          );

          videoRecorded = responseCheck.status === 200;
        }

        if (videoRecorded) {
          const response = await api.get(
            `url-videoteca/${transmissao.videoaula_id || transmissao.id}`
          );

          setUrlConferencia(response.data.urlVideoteca);
          setLoading(false);
          hasUrlConferencia(true);
          setIsUrlVideofront(!!response.data.urlVideoteca);
        }
      } catch (error) {
        setLoading(false);
        hasUrlConferencia(false);
      }
    }

    if (isConteudoTransmitindo()) {
      setUrlVideofront();
    } else if (!transmissao.url) {
      getUrlVideoteca();
    }
  }, [transmissao]); //eslint-disable-line

  const showVideoConferencia = () => {
    if (isConteudoTransmitindo()) {
      return (
        <iframe
          allow="camera *; microphone *; fullscreen *; display-capture *"
          src={urlConferencia}
          title="video-conferencia"
          width="100%"
          height="600px"
        />
      );
    }

    if (isConteudoPublicado() && urlConferencia && isUrlVideofront) {
      return (
        <iframe
          allowFullScreen
          src={urlConferencia}
          title="video-conferencia"
          width="100%"
          height="600px"
        />
      );
    }

    if (isConteudoPublicado() && urlConferencia && !isUrlVideofront) {
      return (
        <ReactPlayer
          url={transmissao.url || transmissao.streaming_url}
          controls
        />
      );
    }

    if (isConteudoDesativado()) {
      return (
        <>
          <Image
            src={imgConteudoDesativado}
            style={{
              display: 'flex',
              paddingTop: 'unset',
              backgroundColor: 'none',
              justifyContent: 'center',
            }}
            imageStyle={{
              width: 'unset',
              height: 'unset',
              position: 'unset',
            }}
          />
          <Typography
            color="primary"
            variant="h5"
            component="div"
            align="center"
          >
            Fim da Transmissão
          </Typography>
          <Typography
            color="secondary"
            variant="body1"
            component="div"
            align="center"
          >
            A transmissão da videoaula foi encerrada ;)
          </Typography>
        </>
      );
    }

    return (
      <Image
        src={imgPreviewConferencia}
        style={{
          display: 'flex',
          paddingTop: 'unset',
          justifyContent: 'center',
        }}
        imageStyle={{
          width: '640px',
          height: 'unset',
          position: 'absolute',
          cursos: 'pointer',
        }}
      />
    );
  };

  return <>{(showLoading && <Loading />) || showVideoConferencia()}</>;
}

Videofront.propTypes = {
  hasUrlConferencia: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Menu from '@material-ui/core/Menu';
import FilterList from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import api from '~/services/api';
import history from '~/services/history';

import CustomSelect from '~/components/CustomUi/CustomSelect';
import CustomDatePicker from '~/components/CustomUi/CustomDatePicker';
import CustomTextField from '~/components/CustomUi/CustomTextField';
import Breadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';

import { cursosRequest } from '~/store/modules/cursos/actions';
import { fontesRequest } from '~/store/modules/fontes/actions';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 20px',
  },
  novo: {
    borderRadius: '200px',
    color: '#fff',
    letterSpacing: '1px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  menuFiltros: {
    marginTop: '50px',
  },
  btFiltrar: {
    color: '#fff',
    borderRadius: '200px',
  },
  filtros: {
    padding: '10px 6px 0',
    margin: '0px 10px',
  },
  iconFilter: {
    marginRight: '10px',
  },
}));

const schema = Yup.object().shape({
  curso: Yup.string(),
  fonte: Yup.string(),
  matricula: Yup.string(),
  nome: Yup.string(),
});

export default function Contratos() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const cursos = useSelector(state => state.cursos.data);
  const fontes = useSelector(state => state.fontes.data);
  const acessos = useSelector(state => state.permissoes.acessos);
  const unidade = useSelector(state => state.unidade.unidade_id);

  const [fontesSelectValues, setFontesSelectValues] = useState(null);
  const [cursosSelectValues, setCursosSelectValues] = useState(null);
  const [chips, setChips] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contratos, setContratos] = useState(null);
  const [fonteEscolhida, setFonteEscolhida] = useState('');
  const [cursoEscolhido, setCursoEscolhido] = useState('');
  const [dataEscolhida, setDataEscolhida] = useState('');
  const [nomeDigitado, setNomeDigitado] = useState('');

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!cursos && unidade) {
      dispatch(cursosRequest(unidade));
    }

    if (!fontes && unidade) {
      dispatch(fontesRequest(unidade));
    }

    if (fontes && fontesSelectValues === null) {
      const arraySelectValues = [];
      fontes.map(fonte => {
        arraySelectValues.push({ value: fonte.fonte_id, label: fonte.fonte });
      });
      setFontesSelectValues(arraySelectValues);
    }

    if (cursos && cursosSelectValues === null) {
      const arraySelectValues = [];
      cursos.map(curso => {
        arraySelectValues.push({ value: curso.curso_id, label: curso.curso });
      });
      setCursosSelectValues(arraySelectValues);
    }

    async function getContratos() {
      const response = await api.get(`contratos/?unidade_id=${unidade}`);
      await setContratos(response.data);
    }

    if (!contratos) {
      getContratos();
    }
  }, [
    contratos,
    cursos,
    cursosSelectValues,
    dispatch,
    fontes,
    fontesSelectValues,
    unidade,
  ]);

  async function handleSubmit({ fonte, curso, matricula, nome }) {
    const dataSplitted = matricula && matricula.split('/');
    const dataFormatada =
      dataSplitted &&
      `${dataSplitted[2]}-${dataSplitted[1]}-${dataSplitted[0]}`;

    const queryParams = `?unidade_id=${unidade}${
      fonte !== '' ? `&fonte_id=${fonte}` : ''
    }${curso !== '' ? `&curso_id=${curso}` : ''}${
      matricula !== '' ? `&data_matricula=${dataFormatada}` : ''
    }${nome !== '' ? `&nome=${nome}` : ''}`;

    const newChips = [];

    if (fonte) {
      const fontePesquisa = fontes.filter(fonteFiltered => {
        return fonteFiltered.fonte_id === parseInt(fonte, 10);
      });

      setFonteEscolhida(fonte);
      newChips.push({ key: 'fonte', label: fontePesquisa[0].fonte });
    }

    if (curso) {
      const cursoPesquisa = cursos.filter(cursoFiltered => {
        return cursoFiltered.curso_id === parseInt(curso, 10);
      });

      setCursoEscolhido(curso);
      newChips.push({ key: 'curso', label: cursoPesquisa[0].curso });
    }

    if (matricula) {
      setDataEscolhida(dataFormatada);
      newChips.push({ key: 'data', label: matricula });
    }

    if (nome) {
      setNomeDigitado(nome);
      newChips.push({ key: 'nome', label: nome });
    }

    setChips(newChips);

    const response = await api.get(`contratos/${queryParams}`);
    await setContratos(response.data);
  }

  function handleDeleteFilter(chipToDelete) {
    const newChips = [];

    if (chips.length > 0) {
      chips.map((chip, index) => {
        newChips.push(chip);
        if (chip === chipToDelete) {
          newChips.splice(index);
        }
      });
    }

    const newObjectRequest = {
      fonte: fonteEscolhida,
      curso: cursoEscolhido,
      matricula: dataEscolhida,
      nome: nomeDigitado,
    };

    if (chipToDelete.key === 'curso') {
      setCursoEscolhido('');
      newObjectRequest.curso = '';
    }

    if (chipToDelete.key === 'fonte') {
      setFonteEscolhida('');
      newObjectRequest.fonte = '';
    }

    if (chipToDelete.key === 'data') {
      setDataEscolhida('');
      newObjectRequest.matricula = '';
    }

    if (chipToDelete.key === 'nome') {
      setNomeDigitado('');
      newObjectRequest.nome = '';
    }

    handleSubmit(newObjectRequest);

    if (newChips.length < 1) {
      setChips([]);
      return;
    }

    setChips(newChips);
  }

  function openFiltros(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Breadcrumbs categorias={['Comercial']} funcionalidade="Contratos" />
      <div className={classes.title}>
        <Typography variant="h5">Contratos</Typography>
        {acessos.contratos.cadastrar && (
          <Button
            variant="contained"
            color="primary"
            className={classes.novo}
            type="button"
            onClick={() => {
              history.push('/novo-contrato');
            }}
          >
            Novo Contrato
          </Button>
        )}
      </div>
      <Card>
        <div className={classes.filtros}>
          <Button
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={openFiltros}
          >
            <FilterList
              color="primary"
              fontSize="large"
              className={classes.iconFilter}
            />
            Filtros
          </Button>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: 270,
                padding: '15px',
              },
            }}
            className={classes.menuFiltros}
          >
            <Typography variant="h6" color="secondary">
              Filtros
            </Typography>
            <Form schema={schema} onSubmit={handleSubmit}>
              <CustomSelect
                label="Curso"
                name="curso"
                options={cursosSelectValues}
              />
              <CustomSelect
                label="Fonte"
                name="fonte"
                options={fontesSelectValues}
              />
              <CustomDatePicker label="Data da matrícula" name="matricula" />
              <CustomTextField label="Nome" name="nome" />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.btFiltrar}
              >
                Filtrar
              </Button>
            </Form>
          </Menu>
          {chips &&
            chips.map(chip => (
              <Chip
                key={chip.key}
                label={chip.label}
                onDelete={() => {
                  handleDeleteFilter(chip);
                }}
                className={classes.chip}
              />
            ))}
        </div>
        <CardContent>
          <DataGrid dataSource={contratos} showBorders>
            <Paging defaultPageSize={10} />
            <Pager showInfo />

            <Column caption="STATUS" dataField="status" />
            <Column caption="Nº DO CONTRATO" dataField="contrato_id" />
            <Column caption="NOME DO ALUNO" dataField="aluno_nome" />
            <Column caption="TITULAR" dataField="titular_nome" />
            <Column caption="CURSO" dataField="curso" />
            <Column caption="FONTE" dataField="fonte" />
            <Column caption="TIPO" dataField="tipo" />
            <Column caption="MATRÍCULA" dataField="matricula" />
            <Column caption="INÍCIO" dataField="inicio" />
          </DataGrid>
        </CardContent>
      </Card>
    </>
  );
}

import produce from 'immer';

const INITIAL_STATE = {
  aula: null,
};

export default function transmissao(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@transmissao/AULA_SELECIONADA': {
        draft.aula = action.payload.aula;
        break;
      }
      case '@transmissao/INICIAR': {
        draft.aula = action.payload.aula;
        break;
      }
      case '@unidade/UNIDADE_SELECIONADA_SUCCESS': {
        draft.aula = null;
        break;
      }
      case '@unidade/UNIDADE_SELECIONADA_FAILURE': {
        draft.aula = null;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.aula = null;
        break;
      }
      default:
    }
  });
}

import produce from 'immer';

const INITIAL_STATE = {
  turmaSelecionada: null,
  materiaSelecionada: null,
  professoresSelecionados: [],
  conteudos: null,
  materias: null,
  aulas: null,
  filtros: {
    data: {},
    labels: [],
  },
  novaPauta: null,
  alunos: null,
  proximaAula: null,
  dadosPauta: null,
};

export default function turmas(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@pautas/PROFESSORES_SELECIONADOS': {
        draft.professoresSelecionados = action.payload.professores;
        draft.alunos = null;
        draft.conteudos = null;
        break;
      }
      case '@pautas/TURMA_SELECIONADA': {
        draft.turmaSelecionada = action.payload.turma;
        draft.alunos = null;
        draft.conteudos = null;
        break;
      }
      case '@pautas/MATERIA_SELECIONADA': {
        draft.materiaSelecionada = action.payload.materia;
        draft.alunos = null;
        draft.conteudos = null;
        break;
      }
      case '@pautas/REQUEST_MATERIAS_SUCCESS': {
        draft.materias = action.payload.materias;
        break;
      }
      case '@pautas/REQUEST_MATERIAS_FAILURE': {
        draft.materias = null;
        break;
      }
      case '@pautas/REQUEST_AULAS_SUCCESS': {
        draft.aulas = action.payload.aulas;
        break;
      }
      case '@pautas/REQUEST_AULAS_FAILURE': {
        draft.aulas = null;
        break;
      }
      case '@pautas/REQUEST_ALUNOS_SUCCESS': {
        draft.alunos = action.payload.alunos;
        draft.dadosPauta = action.payload.dados;
        draft.proximaAula = action.payload.proximaAula;
        break;
      }
      case '@pautas/REQUEST_ALUNOS_FAILURE': {
        draft.alunos = null;
        break;
      }
      case '@pautas/REQUEST_CONTEUDOS_SUCCESS': {
        draft.conteudos = action.payload.conteudos;
        break;
      }
      case '@pautas/REQUEST_CONTEUDOS_FAILURE': {
        draft.conteudos = null;
        break;
      }
      case '@pautas/UPDATE_NEW_SCHEDULE':
      case '@pautas/START_NEW_SCHEDULE': {
        draft.novaPauta = action.payload.pauta;
        break;
      }
      case '@pautas/NEW_SCHEDULE_SAVE_SUCCESS':
      case '@pautas/CANCEL_NEW_SCHEDULE': {
        draft.novaPauta = null;
        break;
      }
      case '@pautas/SAVE_FILTERS': {
        draft.filtros.data = action.payload.filtros;
        draft.filtros.labels = action.payload.labels;
        break;
      }
      case '@unidade/UNIDADE_SELECIONADA_SUCCESS': {
        draft.professoresSelecionados = [];
        draft.turmaSelecionada = null;
        draft.materiaSelecionada = null;
        draft.filtros = {
          data: {},
          labels: [],
        };
        draft.alunos = null;
        draft.conteudos = null;
        break;
      }
      case '@unidade/UNIDADE_SELECIONADA_FAILURE': {
        draft.professoresSelecionados = [];
        draft.turmaSelecionada = null;
        draft.materiaSelecionada = null;
        draft.filtros = {
          data: {},
          labels: [],
        };
        draft.alunos = null;
        draft.conteudos = null;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.professoresSelecionados = [];
        draft.turmaSelecionada = null;
        draft.materiaSelecionada = null;
        draft.filtros = {
          data: {},
          labels: [],
        };
        draft.alunos = null;
        draft.conteudos = null;
        break;
      }
      default:
    }
  });
}

import produce from 'immer';

const INITIAL_STATE = {
  pessoa: null,
  acessos: null,
  videofront: false,
  unidades_videoaula: null,
};

export default function permissoes(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@unidade/UNIDADE_SELECIONADA_SUCCESS': {
        draft.pessoa = action.payload.permissoes.pessoa;
        draft.acessos = action.payload.permissoes.acessos;
        draft.videofront = action.payload.permissoes.videofront;
        draft.unidades_videoaula = action.payload.permissoes.unidades_videoaula;
        break;
      }
      case '@unidade/UNIDADE_SELECIONADA_FAILURE': {
        draft.pessoa = null;
        draft.acessos = null;
        draft.videofront = false;
        draft.unidades_videoaula = null;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.pessoa = null;
        draft.acessos = null;
        draft.videofront = false;
        draft.unidades_videoaula = null;
        break;
      }
      default:
    }
  });
}

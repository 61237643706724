import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';

import api from '~/services/api';

import MoneyTextField from '~/components/CustomUi/MoneyTextField';
import CustomDatePicker from '~/components/CustomUi/CustomDatePicker';
import Select from '~/components/CustomUi/CustomSelect';

const parcelas = [
  { value: 1, label: '1x' },
  { value: 2, label: '2x' },
  { value: 3, label: '3x' },
  { value: 4, label: '4x' },
  { value: 5, label: '5x' },
  { value: 6, label: '6x' },
  { value: 7, label: '7x' },
  { value: 8, label: '8x' },
  { value: 9, label: '9x' },
  { value: 10, label: '10x' },
  { value: 11, label: '11x' },
  { value: 12, label: '12x' },
];

const diasVencimento = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
];

const schema = Yup.object().shape({
  banco_id: Yup.string().required('O banco é obrigatório'),
  dia_vencimento: Yup.string().required('O dia do vencimento é obrigatório'),
  desconto: Yup.string().required('O desconto até o vencimento é obrigatório'),
  matricula_valor: Yup.string().required('O valor da matrícula é obrigatório'),
  matricula_vencimento: Yup.string().required(
    'A data de vencimento da matrícula é obrigatória'
  ),
  matricula_parcelas: Yup.string(),
  parcela_valor: Yup.string().required(
    'O valor da parcela da mensalidade é obrigatório'
  ),
  parcela_vencimento: Yup.string().required(
    'O vencimento da mensalidade é obrigatório'
  ),
  parcela_parcelas: Yup.string(),
  material_valor: Yup.string(),
  material_vencimento: Yup.string().required(
    'O vencimento do material é obrigatório'
  ),
  material_parcelas: Yup.string(),
});

const useStyles = makeStyles({
  root: {
    margin: '10px 15px',
  },
  buttons: {
    margin: '10px',
  },
  buttonText: {
    color: '#fff',
  },
});

export default function DadosCobranca({
  confirmSubmitContrato,
  previousStep,
  setDadosCobranca,
  setOpenConfirmationExit,
}) {
  const classes = useStyles();

  const unidade = useSelector(state => state.unidade.unidade_id);

  const [bancos, setBancos] = useState(null);
  const [bancosOptions, setBancosOptions] = useState(null);

  useEffect(() => {
    async function getBancos() {
      await api
        .get(`bancos/${unidade}`)
        .then(response => {
          const arraySelectValues = [];
          response.data.map(bancosMap => {
            arraySelectValues.push({
              value: bancosMap.banco_id,
              label: bancosMap.banco,
            });
          });
          setBancos(response.data);
          setBancosOptions(arraySelectValues);
        })
        .catch(() => {
          toast.error('Não há bancos cadastrados.');
        });
    }

    if (!bancos) {
      getBancos();
    }
  }, [bancos, unidade]);

  function handleSubmit(data) {
    const matricula_vencimentoSplitted = data.matricula_vencimento.split('/');
    const parcela_vencimentoSplitted = data.parcela_vencimento.split('/');
    const material_vencimentoSplitted = data.material_vencimento.split('/');

    data.matricula_vencimento = `${matricula_vencimentoSplitted[2]}-${matricula_vencimentoSplitted[1]}-${matricula_vencimentoSplitted[0]}`;
    data.parcela_vencimento = `${parcela_vencimentoSplitted[2]}-${parcela_vencimentoSplitted[1]}-${parcela_vencimentoSplitted[0]}`;
    data.material_vencimento = `${material_vencimentoSplitted[2]}-${material_vencimentoSplitted[1]}-${material_vencimentoSplitted[0]}`;

    if (data.desconto) {
      data.desconto = data.desconto.replace(/\D+/, '');
      data.desconto = data.desconto.replace(/,/, '');
    }

    if (data.matricula_valor) {
      data.matricula_valor = data.matricula_valor.replace(/\D+/, '');
      data.matricula_valor = data.matricula_valor.replace(/,/, '');
    }

    if (data.parcela_valor) {
      data.parcela_valor = data.parcela_valor.replace(/\D+/, '');
      data.parcela_valor = data.parcela_valor.replace(/,/, '');
    }

    if (data.material_valor) {
      data.material_valor = data.material_valor.replace(/\D+/, '');
      data.material_valor = data.material_valor.replace(/,/, '');
    }

    setDadosCobranca(data);
    confirmSubmitContrato();
  }

  return (
    <div className={classes.root}>
      <Typography component="h6" variant="h6">
        Preencha os campos abaixo com os dados de cobrança desse contrato
      </Typography>
      <Typography component="p" color="secondary">
        Dados gerais de cobrança
      </Typography>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
            <Select
              name="banco_id"
              label="Banco para cobrança"
              options={bancosOptions}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Select
              name="dia_vencimento"
              label="Dia do vencimento"
              options={diasVencimento}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <MoneyTextField name="desconto" label="Desconto até o vencimento" />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" color="secondary">
              Dados de matrícula
            </Typography>
          </Grid>
          <Grid item sm={12} md={4}>
            <MoneyTextField name="matricula_valor" label="Valor da matrícula" />
          </Grid>
          <Grid item sm={12} md={4}>
            <CustomDatePicker
              name="matricula_vencimento"
              label="Vencimento"
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Select
              name="matricula_parcelas"
              label="Parcelas"
              options={parcelas}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" color="secondary">
              Dados das mensalidades
            </Typography>
          </Grid>
          <Grid item sm={12} md={4}>
            <MoneyTextField name="parcela_valor" label="Valor da parcela" />
          </Grid>
          <Grid item sm={12} md={4}>
            <CustomDatePicker
              name="parcela_vencimento"
              label="Vencimento"
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Select
              name="parcela_parcelas"
              label="Parcelas"
              options={parcelas}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" color="secondary">
              Material didático
            </Typography>
          </Grid>
          <Grid item sm={12} md={4}>
            <MoneyTextField
              name="material_valor"
              label="Valor do material didático"
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <CustomDatePicker
              name="material_vencimento"
              label="Vencimento"
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Select
              name="material_parcelas"
              label="Parcelas"
              options={parcelas}
            />
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            className={classes.buttons}
          >
            <Grid item xs={12} sm={3} md={2}>
              <Button
                type="button"
                color="secondary"
                variant="text"
                fullWidth
                onClick={() => {
                  setOpenConfirmationExit(true);
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                fullWidth
                onClick={() => {
                  previousStep();
                }}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                className={classes.buttonText}
              >
                Avançar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}

DadosCobranca.propTypes = {
  confirmSubmitContrato: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  setDadosCobranca: PropTypes.func.isRequired,
  setOpenConfirmationExit: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Button } from 'devextreme-react/data-grid';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default function GridArquivosUpload({ arquivos, removerArquivo }) {
  function prepareCell(e) {
    if (e.rowType === 'data') {
      if (e.column.caption === 'DESCRIÇÃO') {
        e.cellElement.innerText =
          e.data.descricao.length < 29
            ? e.data.descricao
            : `${e.data.descricao.substring(0, 28)}...`;
      }
    }
  }

  return (
    <DataGrid
      dataSource={arquivos}
      showBorders
      hoverStateEnabled
      noDataText="Sem anexos adicionados"
      onCellPrepared={e => {
        prepareCell(e);
      }}
    >
      <Column caption="NOME" dataField="name" />
      <Column caption="REMOVER" type="buttons">
        <Button
          render={e => {
            return (
              <IconButton
                onClick={() => {
                  removerArquivo(e.data);
                }}
              >
                <Close color="error" />
              </IconButton>
            );
          }}
        />
      </Column>
    </DataGrid>
  );
}

GridArquivosUpload.propTypes = {
  arquivos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  removerArquivo: PropTypes.func.isRequired,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import Image from 'material-ui-image';

import NotaAtribuidaImg from '~/assets/nota_atribuida.svg';

import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    padding: theme.spacing(2, 4, 3),
    maxWidth: '480px',
  },
  btNota: {
    borderRadius: '200px',
    color: '#fff',
  },
  title: {
    fontSize: '24px',
  },
  label: {
    fontSize: '10px',
  },
  value: {
    fontSize: '20px',
  },
  gridSuccess: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  titleSucesso: {
    fontSize: '31px',
    fontWeight: 'bold',
    color: '#47D19D',
    textAlign: 'center',
    marginTop: '5px',
  },
  subtitleSucesso: {
    fontSize: '19px',
    color: '#868CAB',
    lineHeight: '22px',
    textAlign: 'center',
    marginTop: '19px',
  },
  btFechar: {
    borderRadius: '200px',
    marginTop: '13px',
    color: '#fff',
    minWidth: '200px',
  },
}));

export default function AtribuirNota({
  resposta,
  atividadeLancada,
  notaAtribuida,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [nota, setNota] = useState(resposta.nota || 0.0);
  const [notaSalva, setNotaSalva] = useState(false);

  const handleOpen = () => {
    setNotaSalva(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (nota) {
      try {
        notaAtribuida(nota);
        await api.post(`nota/${resposta.realizaratividade_id}`, { nota });
        setNotaSalva(true);
      } catch (error) {
        toast.error(
          'Ocorreu um ao processar a requisição, tente novamente mais tarde.'
        );
      }
    }
  };

  return (
    <div>
      <Button
        className={classes.btNota}
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleOpen}
      >
        ATRIBUIR UMA NOTA
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {!notaSalva && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    variant="body1"
                    className={classes.title}
                  >
                    Atribuir uma nota
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="captions"
                    color="primary"
                    component="p"
                    className={classes.label}
                  >
                    NOTA DE CORTE
                  </Typography>
                  <Typography
                    variant="captions"
                    color="secondary"
                    component="p"
                    className={classes.value}
                  >
                    {atividadeLancada.nota_corte || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="captions"
                    color="primary"
                    component="p"
                    className={classes.label}
                  >
                    PESO DA NOTA
                  </Typography>
                  <Typography
                    variant="captions"
                    color="secondary"
                    component="p"
                    className={classes.value}
                  >
                    {atividadeLancada.peso || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Nota da atividade"
                    variant="filled"
                    fullWidth
                    type="number"
                    inputProps={{
                      maxLength: 13,
                      step: '.1',
                    }}
                    value={nota}
                    onChange={event =>
                      setNota(parseFloat(event.target.value || 0).toFixed(2))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.btNota}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={handleClose}
                  >
                    CANCELAR
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.btNota}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={!nota}
                  >
                    PUBLICAR
                  </Button>
                </Grid>
              </Grid>
            )}
            {notaSalva && (
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.gridSuccess}>
                  <Image
                    src={NotaAtribuidaImg}
                    style={{
                      paddingTop: 'unset',
                      background: 'none',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    imageStyle={{
                      width: 'unset',
                      height: 'unset',
                      position: 'unset',
                    }}
                  />
                  <Typography className={classes.titleSucesso}>
                    Sucesso \o/
                  </Typography>
                  <Typography className={classes.subtitleSucesso}>
                    Nota e comentário cadastrado com sucesso
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btFechar}
                    onClick={handleClose}
                  >
                    OK, FECHAR
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

AtribuirNota.propTypes = {
  resposta: PropTypes.shape().isRequired,
  atividadeLancada: PropTypes.shape().isRequired,
  notaAtribuida: PropTypes.func,
};

AtribuirNota.defaultProps = {
  notaAtribuida: () => {},
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

import MenuBar from '~/components/MenuBar';

import {
  changeOpenMenu,
  changeLargeScreen,
} from '~/store/modules/layout/actions';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: (theme.breakpoints.up('md') && drawerWidth) || 0,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  toolbar: theme.mixins.toolbar,
}));

function Main({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const largeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));
  const { menuOpen, largeScreen: isLargeScreen } = useSelector(
    state => state.layout
  );

  useEffect(() => {
    dispatch(changeOpenMenu(false));
  }, [largeScreen]); // eslint-disable-line

  useEffect(() => {
    dispatch(changeLargeScreen(largeScreen));
  }, [largeScreen]); // eslint-disable-line

  return (
    <div className={classes.root}>
      <MenuBar />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: menuOpen && isLargeScreen,
        })}
      >
        <div className={classes.toolbar} />
        <div className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Main;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Menu from '@material-ui/core/Menu';
import FilterList from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
// import api from '~/services/api';
// import history from '~/services/history';

// import CustomSelect from '~/components/CustomUi/CustomSelect';
// import CustomDatePicker from '~/components/CustomUi/CustomDatePicker';
import CustomTextField from '~/components/CustomUi/CustomTextField';
import Breadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';
import Modal from '~/components/Turmas/Modal';

// import { cursosRequest } from '~/store/modules/cursos/actions';
// import { fontesRequest } from '~/store/modules/fontes/actions';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 20px',
  },
  novo: {
    borderRadius: '200px',
    color: '#fff',
    letterSpacing: '1px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  menuFiltros: {
    marginTop: '50px',
  },
  btFiltrar: {
    color: '#fff',
    borderRadius: '200px',
  },
  filtros: {
    padding: '10px 6px 0',
    margin: '0px 10px',
  },
  iconFilter: {
    marginRight: '10px',
  },
}));

const schema = Yup.object().shape({
  curso: Yup.string(),
  fonte: Yup.string(),
  matricula: Yup.string(),
  nome: Yup.string(),
});

export default function Turmas() {
  // const dispatch = useDispatch();
  const classes = useStyles();

  const turmasState = useSelector(state => state.turmas.data);
  const [filtros] = useState([]);
  const [turmas, setTurmas] = useState(turmasState);
  const [turmaEscolhida, setTurmaEscolhida] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  async function handleSubmit({ nome }) {
    const turmasFiltered = turmasState.filter(turma => {
      if (turma.nome.match(nome)) {
        return turma;
      }
    });

    await setTurmas(turmasFiltered);
  }

  function handleDeleteFilter(chipToDelete) {
    console.log(chipToDelete);
  }

  function openFiltros(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickRow(data) {
    setTurmaEscolhida(data);
    setOpenModal(true);
  }

  return (
    <>
      <Breadcrumbs categorias={['Acadêmico']} funcionalidade="Turmas" />
      <div className={classes.title}>
        <Typography variant="h5">Turmas</Typography>
      </div>
      <Card>
        <div className={classes.filtros}>
          <Button
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={openFiltros}
          >
            <FilterList
              color="primary"
              fontSize="large"
              className={classes.iconFilter}
            />
            Filtros
          </Button>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: 270,
                padding: '15px',
              },
            }}
            className={classes.menuFiltros}
          >
            <Typography variant="h6" color="secondary">
              Filtros
            </Typography>
            <Form schema={schema} onSubmit={handleSubmit}>
              {/* <CustomSelect
                label="Curso"
                name="curso"
                options={cursosSelectValues}
              />
              <CustomSelect
                label="Fonte"
                name="fonte"
                options={fontesSelectValues}
              /> */}
              {/* <CustomDatePicker label="Data da matrícula" name="matricula" /> */}
              <CustomTextField label="Nome da turma" name="nome" />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.btFiltrar}
              >
                Filtrar
              </Button>
            </Form>
          </Menu>
          {filtros.length > 0 &&
            filtros.map(filtro => (
              <Chip
                key={filtro.key}
                label={filtro.label}
                onDelete={() => {
                  handleDeleteFilter(filtro);
                }}
                className={classes.chip}
              />
            ))}
        </div>
        <CardContent>
          <DataGrid
            dataSource={turmas}
            showBorders
            onRowClick={event => handleClickRow(event.data)}
            hoverStateEnabled
          >
            <Paging defaultPageSize={10} />
            <Pager showInfo />

            <Column caption="TURMA" dataField="turma_id" />
            <Column caption="PROFESSOR" dataField="professor_id" />
            <Column caption="NOME" dataField="nome" />
            <Column caption="MATERIAL" dataField="materiaatual_id" />
            <Column caption="DESCRIÇÃO" dataField="descricao" />
          </DataGrid>
        </CardContent>
      </Card>
      {turmaEscolhida && (
        <Modal
          setOpenModal={setOpenModal}
          openModal={openModal}
          data={turmaEscolhida}
        />
      )}
    </>
  );
}

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 5, 10, 5),
    background: '#BDC0D5',
    height: '673px',
  },
  title: {
    fontWeight: 'bold',
  },
  preview: {
    width: '367px',
    height: '498px',
    overflow: 'auto',
    padding: '16px 13px',
  },
  previewTitle: {
    marginBottom: '-2px',
  },
  enunciado: {
    color: '#4D5884',
    fontWeight: 'bold',
    fontSize: '11px',
    marginTop: '14px',
    marginBottom: '7px',
  },
  checkbox: {
    background: '#E3E4ED',
  },
  opcao: {
    color: '#4D5884',
    fontSize: '9px',
    display: 'flex',
    marginBottom: '7px',
  },
  spanCheck: {
    width: '13px',
    height: '13px',
    border: '1px solid #868CAB',
    background: '#E3E4ED',
    borderRadius: '4px',
    marginRight: '5px',
  },
  input: {
    background: '#E3E4ED',
    border: '1px solid #868CAB',
    borderRadius: '4px',
    width: '100%',
    height: '23px',
  },
  textarea: {
    height: '62px',
  },
  placeholderInput: {
    color: '#868CAB',
    letterSpacing: '0.4px',
    lineHeight: '20px',
    fontSize: '9px',
    marginLeft: '7px',
  },
}));

export default function Preview({ questionario }) {
  const classes = useStyles();

  const getPreviewQuestao = questao => {
    const htmlRespostas = [];

    switch (questao.tipo) {
      case 2:
        htmlRespostas.push(
          <div className={classes.input}>
            <span className={classes.placeholderInput}>
              Digite a sua resposta
            </span>
          </div>
        );
        break;
      case 3:
        htmlRespostas.push(
          <div className={clsx(classes.input, classes.textarea)}>
            <span className={classes.placeholderInput}>
              Digite a sua resposta
            </span>
          </div>
        );
        break;

      default:
        questao.opcoes.map(opcao => {
          htmlRespostas.push(
            <Typography
              variant="caption"
              component="p"
              className={classes.opcao}
            >
              <div className={classes.spanCheck} /> {opcao.descricao}
            </Typography>
          );
        });
        break;
    }

    return (
      <>
        <Typography
          variant="caption"
          component="p"
          color="secondary"
          className={classes.enunciado}
        >
          {questao.id} - {questao.enunciado}
        </Typography>
        {htmlRespostas.map(resposta => resposta)}
      </>
    );
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="body1">
          Como está ficando seu questionário
        </Typography>
      </Grid>
      <Grid item xs={12} component={Paper} className={classes.preview}>
        <Typography
          variant="body1"
          color="primary"
          component="p"
          className={classes.previewTitle}
        >
          Questionário
        </Typography>
        {questionario.map(questao => getPreviewQuestao(questao))}
      </Grid>
    </Grid>
  );
}

Preview.propTypes = {
  questionario: PropTypes.arrayOf(PropTypes.shape()),
};

Preview.defaultProps = {
  questionario: [],
};

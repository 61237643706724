import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { darken } from 'polished';
import { toast } from 'react-toastify';

import api from '~/services/api';
import apiVideoStorage from '~/services/apiVideoStorage';
import history from '~/services/history';

import Breadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';
import Chat from '~/components/Chat';
import TransmissaoComponent from '~/components/Transmissao';
import ProgressBar from '~/components/ProgressBar';
import ModalUpload from '~/components/VideoAula/ModalUpload';

import { iniciarTransmissao } from '~/store/modules/transmissao/actions';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 20px',
  },
  encerrar: {
    color: '#fff',
    borderRadius: '200px',
  },
  iniciar: {
    color: '#fff',
    borderRadius: '200px',
    marginLeft: '10px',
    background: '#F5415A',
    transition: 'background 0.2s',
    '&:hover': {
      background: `${darken(0.1, '#F5415A')}`,
    },
  },
  editar: {
    color: '#fff',
    borderRadius: '200px',
    marginRight: '10px',
  },
  gridTransmissao: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  videoConferencia: {
    height: '100%',
    width: '100%',
    border: '0px',
  },
  btMeeting: {
    color: '#fff',
    borderRadius: '200px',
    margin: '15px 0',
  },
  imgConteudoPublicado: {
    width: '640px',
    position: 'absolute',
    cursor: 'pointer',
  },
  btEncerrar: {
    color: '#fff',
    borderRadius: '200px',
    marginBottom: '20px',
  },
});

export default function Transmissao() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const transmissao = useSelector(state => state.transmissao.aula);
  const unidade = useSelector(state => state.unidade.unidade_id);

  const [hasUrlConf, setHasUrlConf] = useState(!!transmissao.url);
  const [openFinishDialog, setOpenFinishDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [uploadLoadingProgress, setUploadLoadingProgress] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [videoUploaded, setVideoUploaded] = useState(null);

  const isConteudoPublicado = () =>
    transmissao.status === 3 || transmissao.status_id === 3;

  const isConteudoDesativado = () => {
    const statusDesativado =
      transmissao.status === 4 || transmissao.status_id === 4;

    return statusDesativado || (isConteudoPublicado() && !hasUrlConf);
  };

  const isConteudoTransmitindo = () =>
    transmissao.status === 2 || transmissao.status_id === 2;

  const isVideoConferencia = () =>
    transmissao.tipo === 4 || transmissao.tipo === 5;

  const isSalaReuniao = () => transmissao.tipo === 3;

  useEffect(() => {
    async function changeStatusAula() {
      await api.put(`transmitir-agora/${transmissao.videoaula_id}`, {
        status: 2,
      });
    }

    if (
      transmissao.tipo === 1 &&
      [transmissao.status, transmissao.status_id].includes(2)
    ) {
      changeStatusAula();
    }
  }, [transmissao]); //eslint-disable-line

  async function handleEncerrarTransmissao() {
    if (isSalaReuniao() && !videoUploaded) {
      const videoAulaId = transmissao.videoaula_id || transmissao.id;
      await api.put(`url-videoaula/${videoAulaId}`, {
        url: '',
      });
    }

    await api.put(
      `encerrar-transmissao/${transmissao.videoaula_id || transmissao.id}`,
      {
        status: 3,
      }
    );

    history.push('/video-aula');
  }

  useEffect(() => {
    async function changeUrlVideoAula() {
      try {
        setUploadLoadingProgress(true);
        const fileName = `${new Date().getTime()}`;
        const extension = videoUploaded.type.replace(/\w+\//, '.');
        const unidadeFormatada = `00000${unidade}`.slice(-5);
        const videoAulaId = transmissao.videoaula_id || transmissao.id;

        await apiVideoStorage.put(
          `${unidadeFormatada}/videoaula/${videoAulaId}/${fileName}${extension}`,
          videoUploaded,
          {
            headers: {
              'Content-type': videoUploaded.type,
            },
            onUploadProgress: progress => {
              setUploadPercentage((100 * progress.loaded) / progress.total);
            },
          }
        );

        await api.put(`url-videoaula/${videoAulaId}`, {
          url: `${process.env.REACT_APP_ORACLE_URL_DOWNLOAD_VIDEO}${unidadeFormatada}%2Fvideoaula%2F${videoAulaId}%2F${fileName}${extension}`,
        });

        setOpenFinishDialog(false);
        setOpenUploadDialog(false);
        setVideoUploaded(null);
        setUploadLoadingProgress(false);
        handleEncerrarTransmissao();
        toast.success('Upload realizado com sucesso.');
      } catch (error) {
        setVideoUploaded(null);
        setUploadLoadingProgress(false);
        toast.error(
          'Falha ao realizar upload da videoaula, tente novamente mais tarde.'
        );
      }
    }

    if (videoUploaded) {
      changeUrlVideoAula();
    }
  }, [videoUploaded]); //eslint-disable-line

  async function handleIniciarTransmissao() {
    await api.put(
      `iniciar-transmissao/${transmissao.videoaula_id || transmissao.id}`,
      {
        status: 2,
      }
    );

    dispatch(iniciarTransmissao({ ...transmissao, status: 2, status_id: 2 }));
  }

  const getChatVideoAula = () => {
    if (isConteudoTransmitindo()) {
      if (!isVideoConferencia() && !isSalaReuniao()) {
        return (
          <Grid item xs={3}>
            <Chat videoAula={transmissao.id || transmissao.videoaula_id} />
          </Grid>
        );
      }
    }
  };

  return (
    <>
      {uploadLoadingProgress && <ProgressBar progress={uploadPercentage} />}
      <Breadcrumbs categorias={['Acadêmico']} funcionalidade="Transmissão" />
      <div className={classes.title}>
        <Typography variant="h5">{transmissao.titulo}</Typography>
        <div>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            className={classes.editar}
            onClick={() =>
              history.push(
                `editar-video-aula/${transmissao.videoaula_id ||
                  transmissao.id}`
              )
            }
          >
            EDITAR VIDEOAULA
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.encerrar}
            onClick={() => {
              if (isVideoConferencia() || isSalaReuniao()) {
                setOpenUploadDialog(true);
              } else {
                setOpenFinishDialog(true);
              }
            }}
            disabled={!isConteudoTransmitindo()}
          >
            ENCERAR TRANSMISSÃO
          </Button>
          <Button
            type="button"
            variant="contained"
            color="default"
            className={classes.iniciar}
            onClick={handleIniciarTransmissao}
            disabled={
              isConteudoTransmitindo() ||
              isConteudoDesativado() ||
              isConteudoPublicado()
            }
          >
            INICIAR TRANSMISSÃO
          </Button>
        </div>
      </div>
      <Grid container spacing={2} className={classes.gridTransmissao}>
        <Grid
          item
          xs={(isVideoConferencia() && 12) || 8}
          className={classes.content}
        >
          <Grid container className={classes.content}>
            <TransmissaoComponent
              hasUrlConf={hasUrlConf}
              setHasUrlConf={setHasUrlConf}
            />
          </Grid>
        </Grid>
        {getChatVideoAula()}
      </Grid>
      <Dialog
        open={!uploadLoadingProgress && openFinishDialog}
        onClose={() => {
          setOpenFinishDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tem certeza que deseja encerrar a transmissão?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              handleEncerrarTransmissao();
            }}
            color="primary"
            autoFocus
          >
            Encerrar
          </Button>
          <Button
            onClick={() => {
              setOpenFinishDialog(false);
            }}
            color="primary"
          >
            Permanecer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!uploadLoadingProgress && openUploadDialog}
        onClose={() => {
          setOpenFinishDialog(true);
          setOpenUploadDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Deseja anexar alguma gravação local?
        </DialogTitle>
        <DialogActions>
          <ModalUpload setFile={setVideoUploaded} />
          <Button
            type="button"
            variant="contained"
            color="secondary"
            fullWidth
            className={classes.btEncerrar}
            onClick={() => {
              setOpenFinishDialog(true);
              setOpenUploadDialog(false);
            }}
          >
            Encerrar Aula
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

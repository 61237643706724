import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  makeStyles,
  // useTheme,
} from '@material-ui/core/styles';
import {
  // Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  // List,
  IconButton,
  // Divider,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  ButtonGroup,
  Button,
  Menu,
  useMediaQuery,
} from '@material-ui/core';
import {
  PermIdentity,
  Menu as MenuIcon,
  KeyboardArrowLeft,
  // ChevronRight,
  // VideoLibrary,
  // ListAltOutlined,
  // AssignmentOutlined,
  // DescriptionOutlined,
  // PeopleOutlineOutlined,
} from '@material-ui/icons';
import Image from 'material-ui-image';

// import history from '~/services/history';
import { unidadeSelecionadaRequest } from '~/store/modules/unidade/actions';
import { signOut } from '~/store/modules/auth/actions';
import { changeOpenMenu } from '~/store/modules/layout/actions';

import MenuDrawer from '~/components/MenuDrawer';

// import LogoF10 from '~/assets/logo_f10.svg';
// import Powered from '~/assets/powered_grey.svg';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fff',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  divsToolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    backgroundColor: '#F3F7FA',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  userOptions: {
    borderRadius: '50%',
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  menuUser: {
    marginTop: '60px',
  },
  btLogout: {
    borderRadius: '200px',
  },
  avatar: {
    marginBottom: '15px',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '15px',
  },
  politicas: {
    marginBottom: '15px',
  },
  dividerAcademico: {
    marginTop: '12px',
    letterSpacing: '2px',
    display: 'flex',
    justifyContent: 'center',
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
  },
}));

export default function MenuBar() {
  const classes = useStyles();
  // const theme = useTheme();
  const largeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));

  const acessos = useSelector(state => state.permissoes.acessos);
  const profile = useSelector(state => state.professor.profile);
  const unidadeSelecionada = useSelector(state => state.unidade);
  const { menuOpen } = useSelector(state => state.layout);
  const dispatch = useDispatch();
  const [unidade, setUnidade] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);

  const openProfile = Boolean(anchorEl);

  useEffect(() => {
    if (unidadeSelecionada && unidadeSelecionada.unidade_id) {
      setUnidade(unidadeSelecionada.unidade_id);
    }
    dispatch(changeOpenMenu(Boolean(acessos)));
  }, [unidadeSelecionada, acessos]); //eslint-disable-line

  function handleChange(event) {
    setUnidade(event.target.value);
    profile.unidades.filter(unidadeMap => {
      if (unidadeMap.unidade_id === event.target.value) {
        dispatch(
          unidadeSelecionadaRequest(unidadeMap.unidade_id, unidadeMap.unidade)
        );
      }
    });
  }

  const handleLogout = () => {
    dispatch(signOut());
  };

  const handleClickPerfil = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  //   drawerOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  //   drawerOpen(false);
  // };

  // const handleClickFunctionality = path => {
  //   history.push(`/${path}`);
  // };

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: largeScreen && menuOpen,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.divsToolbar}>
            {acessos && (
              <IconButton
                color="secondary"
                aria-label="open drawer"
                onClick={() => {
                  dispatch(changeOpenMenu(!menuOpen));
                }}
                edge="start"
              >
                {(menuOpen && <KeyboardArrowLeft />) || <MenuIcon />}
              </IconButton>
            )}
            <FormControl
              variant="filled"
              className={classes.formControl}
              size="small"
            >
              <InputLabel id="label-select-unidade">
                Selecione uma unidade
              </InputLabel>
              <Select
                labelId="label-select-unidade"
                id="select-unidade"
                value={unidade}
                onChange={handleChange}
              >
                <MenuItem value="" disabled>
                  <em>Selecione</em>
                </MenuItem>
                {profile &&
                  profile.unidades &&
                  profile.unidades.map(unidadeMap => {
                    return (
                      <MenuItem
                        key={unidadeMap.unidade_id}
                        value={unidadeMap.unidade_id}
                      >
                        {unidadeMap.unidade}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className={classes.divsToolbar}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClickPerfil}
              className={classes.userOptions}
            >
              <PermIdentity fontSize="large" />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={openProfile}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: 270,
                  padding: '15px',
                },
              }}
              className={classes.menuUser}
            >
              <div className={classes.avatar}>
                <Image
                  src={profile.avatar}
                  style={{
                    display: 'flex',
                    paddingTop: 'unset',
                    justifyContent: 'center',
                  }}
                  imageStyle={{
                    width: '90px',
                    height: '90px',
                    position: 'unset',
                  }}
                />
              </div>
              <div className={classes.userInfo}>
                <Typography variant="h6">{profile.nome}</Typography>
                <Typography variant="caption" component="p" color="secondary">
                  {profile.login}
                </Typography>
              </div>
              <div className={classes.politicas}>
                <Button
                  color="secondary"
                  variant="text"
                  onClick={() =>
                    window.open(
                      'https://www.f10.com.br/politica-de-privacidade/',
                      '_blank'
                    )
                  }
                >
                  Política de Privacidade
                </Button>
                <Button
                  color="secondary"
                  variant="text"
                  onClick={() =>
                    window.open(
                      'https://www.f10.com.br/termos-de-uso/',
                      '_blank'
                    )
                  }
                >
                  Termos de Serviço
                </Button>
              </div>
              <ButtonGroup
                color="secondary"
                aria-label="outlined primary button group"
                fullWidth
              >
                <Button
                  className={classes.btLogout}
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  Sair
                </Button>
              </ButtonGroup>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {acessos && <MenuDrawer />}
    </>
  );
}

import React, { useRef, useState, useEffect } from 'react';
import { useField } from '@rocketseat/unform';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export default function TextField({ name, ...rest }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [valueX, setValueX] = useState(defaultValue);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'value',
        clearValue: () => {
          setValueX(undefined);
        },
      });
    }
  }, [ref.current, fieldName]); //eslint-disable-line

  return (
    <MuiTextField
      name={name}
      error={!!error}
      helperText={error}
      inputRef={ref}
      defaultValue={valueX}
      variant="filled"
      {...rest}
    />
  );
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
};

import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const FiltersContext = createContext({});

export const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    atividades: {
      type: 1,
    },
    videoaulas: {},
  });

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export function useFilters() {
  const context = useContext(FiltersContext);

  return context;
}

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, IconButton, Box } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import DataGrid, {
  Column,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  Button as DxButton,
} from 'devextreme-react/data-grid';

import api from '~/services/api';
import history from '~/services/history';
import Breadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';
import Dialog from '~/components/VideoAula/DialogNovaAula';
import Filtros from '~/components/VideoAula/Filtros';
import { aulaSelecionada } from '~/store/modules/transmissao/actions';

import { useFilters } from '~/hooks/filters';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 20px',
    color: '#4D5884',
  },
  novoVideo: {
    color: '#fff',
  },
  boxGrid: {
    padding: '20px 30px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
});

export default function VideoAula() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters } = useFilters();
  const [openModalStore, setOpenModalStore] = useState(false);
  const [videoAulas, setVideoAulas] = useState([]);
  const [refreshTable, setRefreshTable] = useState(true);
  const [objectFilters, setObjectFilters] = useState(filters.videoaulas);
  const [professores, setProfessores] = useState([]);

  const getVideoAulas = async () => {
    const response = await api.get('videoaulas', { params: objectFilters });
    setVideoAulas(response.data);
  };

  useEffect(() => {
    const getProfessores = async () => {
      const response = await api.get('professores');
      setProfessores(response.data);
    };

    if (!professores.length) {
      getProfessores();
    }
  }, [professores]);

  useEffect(() => {
    if (refreshTable) {
      setRefreshTable(false);
      getVideoAulas();
    }
  }, [refreshTable]); // eslint-disable-line

  useEffect(() => {
    getVideoAulas();
  }, [objectFilters]); // eslint-disable-line

  const handleClickCel = e => {
    if (e.rowType === 'data') {
      if (e.column.caption === 'VISUALIZAR') {
        dispatch(aulaSelecionada(e.data));
        history.push('/transmissao');
      }
    }
  };

  function prepareRow(e) {
    e.rowElement.style.cursor = 'pointer';
  }

  return (
    <>
      <Breadcrumbs categorias={['Acadêmico']} funcionalidade="Videoaula" />
      <div className={classes.title}>
        <Typography variant="h5">Videoaulas</Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.novoVideo}
          onClick={() => setOpenModalStore(true)}
        >
          Criar nova videoaula
        </Button>
      </div>
      <Box className={classes.boxGrid}>
        <Filtros
          applyFilters={setObjectFilters}
          filters={objectFilters}
          professores={professores}
        />
        <DataGrid
          dataSource={videoAulas}
          allowColumnReordering
          showBorders
          onRowPrepared={e => {
            prepareRow(e);
          }}
          onCellClick={e => {
            handleClickCel(e);
          }}
          hoverStateEnabled
        >
          <GroupPanel visible emptyPanelText="" />
          <Grouping autoExpandAll={false} />
          <Paging defaultPageSize={200} />
          <Pager showInfo infoText="Página {0} de {1} ({2} aulas)" />

          <Column caption="STATUS_ID" dataField="status_id" visible={false} />
          <Column caption="URL" dataField="url" visible={false} />
          <Column caption="STATUS" dataField="status" />
          <Column caption="TÍTULO" dataField="titulo" />
          <Column caption="DATA" dataField="data" />
          <Column caption="CURSO" dataField="curso" />
          <Column caption="PROFESSOR" dataField="professor" />
          <Column caption="TURMA" dataField="turma" />
          <Column caption="MATÉRIA" dataField="materia" />
          <Column caption="AULA" dataField="aula" />
          <Column caption="VISUALIZAR" type="buttons">
            <DxButton
              render={() => {
                return (
                  <IconButton onClick={() => {}}>
                    <ArrowForward color="primary" />
                  </IconButton>
                );
              }}
            />
          </Column>
        </DataGrid>
      </Box>

      <Dialog openDialog={openModalStore} handleClose={setOpenModalStore} />
    </>
  );
}

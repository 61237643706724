import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  gridBtAnotacoes: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btAnotacoes: {
    height: 'fit-content',
  },
  content: {
    minWidth: '350px',
  },
}));

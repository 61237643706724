import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function CustomDialog({
  children,
  open,
  title,
  onCancel,
  onConfirm,
  textButtonCancel,
  textButtonConfirm,
}) {
  return (
    <Dialog open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {textButtonCancel || 'Cancelar'}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {textButtonConfirm || 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  textButtonCancel: PropTypes.string,
  textButtonConfirm: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

CustomDialog.defaultProps = {
  title: null,
  children: null,
  textButtonCancel: null,
  textButtonConfirm: null,
};

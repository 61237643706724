import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import 'date-fns';
import ptBr from 'date-fns/locale/pt';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  error: {
    color: '#f44336',
    width: '93%',
  },
});

export default function CustomDateTimePicker({
  label,
  name,
  fullWidth,
  required,
  disabled,
  dateSelected,
  disableFuture,
  disablePast,
  maxDate,
  minDate,
}) {
  const classes = useStyles();
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [valueX, setValueX] = useState(defaultValue || null);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current.childNodes[0].childNodes[1].childNodes[0],
        path: 'value',
        clearValue: () => {
          setValueX(null);
        },
      });
    }
  }, [ref.current, fieldName]); //eslint-disable-line

  const handleDateChange = date => {
    setValueX(date);
    dateSelected(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
      <Grid container justifyContent="space-around" ref={ref}>
        <KeyboardDateTimePicker
          margin="normal"
          id="date-picker-dialog"
          label={label}
          format="dd/MM/yyyy HH:mm"
          value={valueX}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          required={required}
          name={name}
          fullWidth={fullWidth}
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          inputVariant="filled"
          maxDate={maxDate}
          minDate={minDate}
          error={!!error}
        />
        {!!error && (
          <Typography component="p" variant="caption" className={classes.error}>
            {error}
          </Typography>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

CustomDateTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  dateSelected: PropTypes.func,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  maxDate: PropTypes.shape(),
  minDate: PropTypes.shape(),
};

CustomDateTimePicker.defaultProps = {
  fullWidth: false,
  required: false,
  disabled: false,
  dateSelected: () => '',
  disableFuture: false,
  disablePast: false,
  maxDate: null,
  minDate: null,
};

import React, { useState, useRef, useEffect } from 'react';
import { useField } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';

export default function DatePickerField({ name, ...props }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [valueX, setValueX] = useState(defaultValue || null);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'value',
        clearValue: () => {
          setValueX(null);
        },
      });
    }
  }, [ref.current, fieldName]); //eslint-disable-line

  return (
    <KeyboardDatePicker
      inputRef={ref}
      defaultValue={valueX}
      error={!!error}
      helperText={error}
      {...props}
    />
  );
}

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
};

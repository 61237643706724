import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Button,
  Grid,
  Divider,
  Typography,
  FormControl,
  FilledInput,
  Checkbox,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import ReactLoading from 'react-loading';
import Image from 'material-ui-image';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import DataGrid, {
  Column,
  Button as ButtonGrid,
} from 'devextreme-react/data-grid';
import { Close, Edit, DeleteOutlineOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt';

import api from '~/services/api';
import apiAtividadeStorage from '~/services/apiAtividadeStorage';
import apiVideoStorage from '~/services/apiVideoStorage';
import history from '~/services/history';
import Ilustracao from '~/assets/ilustracao_videoaula.svg';
import Select from '~/components/CustomUi/FormSelect';
import DateTimePicker from '~/components/CustomUi/CustomDateTimePicker';
import TextField from '~/components/CustomUi/TextField';
import UploadComponent from '~/components/VideoAula/ModalUpload';
import ProgressBar from '~/components/ProgressBar';
import ModalAtividade from './ModalAdicionarAtividade';
import ModalEditarAtividade from './ModalEditarAtividade';

const useStyles = makeStyles(() => ({
  ilustracao: {
    display: 'flex',
    justifyContent: 'center',
    background: '#f1f1f1',
    padding: '20px 10px',
    borderRadius: '25px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
  },
  button: {
    color: 'white',
    borderRadius: '200px',
    margin: '0 5px',
  },
  media: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
  },
  status: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  uploadDivider: {
    margin: '10px 0',
  },
  file: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(80, 166, 255, 0.4)',
    borderRadius: '8px',
    maxHeight: '39px',
    padding: '5px 15px',
    color: '#50A6FF',
    fontSize: '14px',
    margin: '0 0 20px 0',
  },
  fileName: {
    fontWeight: 'bold',
  },
  btRemover: {
    color: '#50A6FF',
  },
}));

const schema = Yup.object().shape({
  curso_id: Yup.number().typeError('O curso é obrigatório'),
  turma_id: Yup.number()
    .positive()
    .nullable(true)
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  materia_id: Yup.number()
    .positive()
    .nullable(true)
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  aula_id: Yup.number()
    .positive()
    .nullable(true)
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  data: Yup.string().required('A data/hora é obrigatória'),
  conteudos: Yup.array(Yup.number()).nullable(true),
  professor_id: Yup.number().typeError('O curso é obrigatório'),
  tipo: Yup.number().typeError('O curso é obrigatório'),
  titulo: Yup.string().required('O título é obrigatório'),
  status: Yup.number().typeError(),
  descricao: Yup.string().required('A descrição é obrigatória'),
  streaming_url: Yup.string(),
});

export default function Formulario({ initialData }) {
  const classes = useStyles();
  const formRef = useRef(null);

  const cursos = useSelector(state => state.cursos.data);
  const turmas = useSelector(state => state.turmas.data);
  const lookup = useSelector(state => state.lookup);
  const unidade = useSelector(state => state.unidade.unidade_id);

  const [cursoEscolhido, setCursoEscolhido] = useState(null);
  const [turmaEscolhida, setTurmaEscolhida] = useState(null);
  const [materiaEscolhida, setMateriaEscolhida] = useState(null);
  const [aulaEscolhida, setAulaEscolhida] = useState(null);
  const [videoUploaded, setVideoUploaded] = useState(null);
  const [statusEscolhido, setStatusEscolhido] = useState(initialData.status);
  const [conteudosSelecionados, setConteudosSelecionados] = useState([]);
  const [uploadLoadingProgress, setUploadLoadingProgress] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [professores, setProfessores] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [aulas, setAulas] = useState([]);
  const [conteudos, setConteudos] = useState([]);

  const [urlPreview, setUrlPreview] = useState(null);
  const [showModalAtividade, setShowModalAtividade] = useState(false);
  const [parametrosAtividades, setParametrosAtividades] = useState({
    cursos: [initialData.curso_id],
    turmas: [initialData.turma_id],
    materias: [initialData.materia_id],
    aulas: [initialData.aula_id],
  });
  const [showLoading, setShowLoading] = useState(false);
  const [atividadesAdicionadas, setAtividadesAdicionadas] = useState([]);

  const [atividadesRemovidas, setAtividadesRemovidas] = useState([]);
  const [atividadesModificadas, setAtividadesModificadas] = useState([]);
  const [editarAtividade, setEditarAtividade] = useState(null);

  useEffect(() => {
    async function getAtividadesVideoAula() {
      const response = await api.get(
        `videoaula/${initialData.videoaula_id}/atividades`
      );
      setAtividadesAdicionadas([...atividadesAdicionadas, ...response.data]);
    }

    if (!cursoEscolhido) {
      setCursoEscolhido(initialData.curso_id);
      setTurmaEscolhida(initialData.turma_id);
      setMateriaEscolhida(initialData.materia_id);
      setAulaEscolhida(initialData.aula_id);
      setConteudosSelecionados(() => {
        if (initialData.conteudos) {
          if (initialData.conteudos instanceof Array) {
            return initialData.conteudos;
          }

          return [initialData.conteudos];
        }

        return [];
      });
      getAtividadesVideoAula();
    }
  }, [initialData]); //eslint-disable-line

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView();
    }

    const getProfessores = async () => {
      const response = await api.get('professores');
      setProfessores(response.data);
    };

    if (!professores.length) {
      getProfessores();
    }
  }, [professores]);

  useEffect(() => {
    const getMaterias = async () => {
      const response = await api.get(`turma/${turmaEscolhida}/materias`);
      setMaterias(response.data);
    };

    if (turmaEscolhida) {
      getMaterias();
    } else {
      setMaterias([]);
    }
  }, [turmaEscolhida]);

  useEffect(() => {
    const getAulas = async () => {
      const response = await api.get(`materia/${materiaEscolhida}/aulas`);
      setAulas(response.data);
    };

    if (materiaEscolhida) {
      getAulas();
    } else {
      setAulas([]);
    }
  }, [materiaEscolhida]);

  useEffect(() => {
    const getConteudos = async () => {
      const response = await api.get(`aula/${aulaEscolhida}/conteudos`);
      setConteudos(response.data);
    };

    if (aulaEscolhida) {
      getConteudos();
    } else {
      setConteudos([]);
    }
  }, [aulaEscolhida]);

  async function handleSubmit({
    curso_id: curso,
    turma_id: turma,
    materia_id: materia,
    aula_id: aula,
    data,
    conteudos: conteudosEscolhidos,
    professor_id: professor,
    tipo,
    titulo,
    descricao,
    streaming_url: url,
  }) {
    setShowLoading(true);
    const unidadeFormatada = `0000${unidade}`.slice(-5);
    if (!!atividadesAdicionadas) {
      await atividadesAdicionadas.map(atividade => {
        if (!!atividade.files) {
          atividade.files.map(arquivo => {
            atividade.anexos.push(arquivo.downloadUrl);
            apiAtividadeStorage.put(
              `${process.env.REACT_APP_ORACLE_URL_UPLOAD_ATIVIDADE}${unidadeFormatada}/atividades/${atividade.atividade_id}/${arquivo.fileName}`,
              arquivo,
              {
                'Content-Type': `${arquivo.type}`,
              }
            );
          });
        }
      });
    }

    let urlVideoUploaded = null;

    if (videoUploaded) {
      try {
        setShowLoading(false);
        setUploadLoadingProgress(true);

        const fileName = `${new Date().getTime()}`;
        const extension = videoUploaded.type.replace(/\w+\//, '.');

        await apiVideoStorage.put(
          `${unidadeFormatada}/videoaula/${initialData.videoaula_id}/${fileName}${extension}`,
          videoUploaded,
          {
            headers: {
              'Content-type': videoUploaded.type,
            },
            onUploadProgress: progress => {
              setUploadPercentage((100 * progress.loaded) / progress.total);
            },
          }
        );
        urlVideoUploaded = `${process.env.REACT_APP_ORACLE_URL_DOWNLOAD_VIDEO}${unidadeFormatada}%2Fvideoaula%2F${initialData.videoaula_id}%2F${fileName}${extension}`;
        setUploadLoadingProgress(false);
        setShowLoading(true);
      } catch (error) {
        setUploadLoadingProgress(false);
        setShowLoading(true);
      }
    }

    if (url && initialData.streaming_tipo === 3) {
      const hasProtocol = url.includes('http');
      url = (hasProtocol && url) || `https://${url}`;
    }

    const dataHoraSplitted = data.split(' ');
    const hora = dataHoraSplitted[1];
    const dataSplitted = dataHoraSplitted[0].split('/');
    const dataFormatada = `${dataSplitted[2]}-${dataSplitted[1]}-${dataSplitted[0]} ${hora}:00`;
    await api
      .put(`videoaula/${initialData.videoaula_id}`, {
        curso,
        turma,
        materia,
        aula,
        data: dataFormatada,
        conteudos: conteudosEscolhidos,
        professor,
        tipo,
        titulo,
        status: statusEscolhido,
        descricao,
        url: urlVideoUploaded || url,
      })
      .then(() => {
        setShowLoading(false);
        toast.success('Videoaula atualizada com sucesso.');

        if (urlVideoUploaded) {
          api.put(`url-videoaula/${initialData.videoaula_id}`, {
            url: urlVideoUploaded,
          });
        }

        atividadesAdicionadas.map(atividade => {
          if (atividade.lancaratividade_id) {
            if (atividadesModificadas.includes(atividade.lancaratividade_id)) {
              api.put(`/atividade-lancada/${atividade.lancaratividade_id}`, {
                ...atividade,
                tipo_atividade: atividade.tipo_atividade_id,
                tipo_resposta: atividade.tipo_resposta_id,
                curso,
                turma,
                materia,
                aula,
              });
            }
          } else {
            api.post(`/lancar-atividade/${initialData.videoaula_id}`, {
              ...atividade,
              tipo_atividade: atividade.tipo_atividade_id,
              tipo_resposta: atividade.tipo_resposta_id,
              curso,
              turma,
              materia,
              aula,
            });
          }
        });
        atividadesRemovidas.map(atividade =>
          api.delete(`/atividade-lancada/${atividade}`)
        );
        return history.push('/video-aula');
      })
      .catch(() => {
        toast.error('Erro ao atualizar videoaula.');
        setShowLoading(false);
      });
  }

  const handleChangeCurso = event => {
    setCursoEscolhido(event.target.value);
    setParametrosAtividades({
      cursos: [event.target.value],
      turmas: null,
      materias: null,
      aulas: null,
    });
  };

  const handleChangeTurma = event => {
    setTurmaEscolhida(event.target.value);
    setParametrosAtividades({
      cursos: [event.target.value],
      turmas: [event.target.value],
      materias: null,
      aulas: null,
    });
  };

  const handleChangeMateria = event => {
    setMateriaEscolhida(event.target.value);
    setParametrosAtividades({
      ...parametrosAtividades,
      materias: [event.target.value],
      aulas: null,
    });
  };

  const handleChangeAula = event => {
    setAulaEscolhida(event.target.value);
    setParametrosAtividades({
      ...parametrosAtividades,
      aulas: [event.target.value],
    });
  };

  function handleChangeUrl(url) {
    setUrlPreview(url);
  }

  const handleCloseModalAtividade = () => {
    setShowModalAtividade(false);
  };

  const addAtividade = atividade => {
    return (
      !!atividade &&
      setAtividadesAdicionadas([...atividadesAdicionadas, atividade])
    );
  };

  const handleChangeConteudo = event => {
    setConteudosSelecionados(event.target.value);
  };

  const renderSelecionados = () => {
    const descricoes = [];
    conteudos
      .filter(conteudoFilter =>
        conteudosSelecionados.includes(conteudoFilter.conteudo_id)
      )
      .map(conteudoMapped => descricoes.push(conteudoMapped.descricao));
    return descricoes.join(', ');
  };

  const handleCloseEditarAtividade = () => {
    setEditarAtividade(null);
  };

  const atualizarAtividade = atividadeAtualizada => {
    const atividade = atividadesAdicionadas.filter(
      atividadeFilter =>
        atividadeFilter.lancaratividade_id ===
        atividadeAtualizada.lancaratividade_id
    )[0];
    let newAtividadesAdicionadas = [...atividadesAdicionadas];
    const indexAtividadeAtt = newAtividadesAdicionadas.indexOf(atividade);
    newAtividadesAdicionadas.splice(indexAtividadeAtt, 1);
    newAtividadesAdicionadas = [
      ...newAtividadesAdicionadas,
      atividadeAtualizada,
    ];
    setAtividadesAdicionadas(newAtividadesAdicionadas);
    setAtividadesModificadas([
      ...atividadesModificadas,
      atividadeAtualizada.lancaratividade_id,
    ]);
  };

  const removerAtividade = atividade => {
    const index = atividadesAdicionadas.indexOf(atividade);
    const newArray = [...atividadesAdicionadas];
    newArray.splice(index, 1);
    setAtividadesAdicionadas(newArray);
    setAtividadesRemovidas([
      ...atividadesRemovidas,
      atividade.lancaratividade_id,
    ]);
  };

  const prepareCell = event => {
    if (event.rowType === 'data') {
      if (event.column.caption === 'DESCRIÇÃO') {
        event.cellElement.innerText =
          event.data.descricao.length < 29
            ? event.data.descricao
            : `${event.data.descricao.substring(0, 28)}...`;
      }
    }
  };

  const getHandledFileName = () => {
    if (videoUploaded.name.length > 50) {
      return `${videoUploaded.name.substring(
        0,
        20
      )} ... ${videoUploaded.name.substring(videoUploaded.name.length - 20)}`;
    }

    return videoUploaded.name;
  };

  const isUrlFileUploaded = () => {
    return (
      (initialData.url && initialData.url.includes('objectstorage')) ||
      (initialData.streaming_url &&
        initialData.streaming_url.includes('objectstorage'))
    );
  };

  const removeTimezoneDate = date => {
    const dateFormatted = date.replace(/z/i, '');
    return dateFormatted;
  };

  return (
    <>
      {uploadLoadingProgress && <ProgressBar progress={uploadPercentage} />}
      <Form schema={schema} onSubmit={handleSubmit} initialData={initialData}>
        <Typography variant="h5" color="primary">
          Nova videoaula
        </Typography>
        <Typography color="secondary" component="p">
          Preencha os dados a seguir para iniciar a videoaula
        </Typography>
        <Divider variant="fullWidth" color="secondary" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="secondary" variant="h6" component="p">
              Dados do curso
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select
                    label="Curso"
                    name="curso_id"
                    placeholder="Selecione"
                    onChange={handleChangeCurso}
                    disabled
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {cursos &&
                      cursos
                        .filter(
                          curso => curso.curso_id === initialData.curso_id
                        )
                        .map(curso => (
                          <MenuItem key={curso.curso_id} value={curso.curso_id}>
                            {curso.descricao}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select
                    label="Turma"
                    name="turma_id"
                    onChange={handleChangeTurma}
                    disabled
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {turmas &&
                      turmas
                        .filter(
                          turma =>
                            turma.curso_id === cursoEscolhido &&
                            initialData.turma_id === turma.turma_id
                        )
                        .map(turma => (
                          <MenuItem key={turma.turma_id} value={turma.turma_id}>
                            {turma.nome}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select
                    label="Matéria"
                    name="materia_id"
                    onChange={handleChangeMateria}
                    disabled
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {materias &&
                      materias
                        .filter(
                          materia =>
                            initialData.materia_id === materia.materia_id
                        )
                        .map(materia => (
                          <MenuItem
                            key={materia.materia_id}
                            value={materia.materia_id}
                          >
                            {materia.materia}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth margin="normal">
                  <Select
                    label="Aula"
                    name="aula_id"
                    onChange={handleChangeAula}
                    disabled
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {aulas &&
                      aulas
                        .filter(
                          aula => initialData.aula_id === aula.conteudo_id
                        )
                        .map(aula => (
                          <MenuItem
                            key={aula.conteudo_id}
                            value={aula.conteudo_id}
                          >
                            {aula.descricao}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography color="secondary" variant="h6" component="p">
                  Dados da aula
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <DateTimePicker label="Data" name="data" fullWidth disabled />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="filled"
                  fullWidth
                  margin="normal"
                  disabled
                >
                  <Select
                    label="Conteúdo da aula"
                    name="conteudos"
                    value={conteudosSelecionados}
                    multiple
                    input={<FilledInput />}
                    renderValue={() => renderSelecionados()}
                    onChange={handleChangeConteudo}
                    disabled
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {conteudos &&
                      conteudos.map(conteudo => (
                        <MenuItem
                          key={conteudo.conteudo_id}
                          value={conteudo.conteudo_id}
                        >
                          <Checkbox
                            checked={
                              conteudosSelecionados.indexOf(
                                conteudo.conteudo_id
                              ) > -1
                            }
                          />
                          <ListItemText primary={conteudo.descricao} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="filled"
                  fullWidth
                  margin="normal"
                  disabled
                >
                  <Select label="Professor" name="professor_id" disabled>
                    <MenuItem value="">Selecione</MenuItem>
                    {professores &&
                      professores
                        .filter(
                          professor =>
                            professor.professor_id === initialData.professor_id
                        )
                        .map(professor => (
                          <MenuItem
                            key={professor.professor_id}
                            value={professor.professor_id}
                          >
                            {professor.professor}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="filled"
                  fullWidth
                  margin="normal"
                  disabled
                >
                  <Select label="Tipo de transmissão" name="tipo" disabled>
                    {lookup.tipoAula
                      .filter(tipo => tipo.intkey === initialData.tipo)
                      .map(tipo => (
                        <MenuItem key={tipo.intkey} value={tipo.intkey}>
                          {tipo.descricao}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Título da aula"
                  name="titulo"
                  placeholder="Digite o título da aula"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descrição da aula"
                  name="descricao"
                  placeholder="Digite a descrição da aula"
                  multiline
                  rows="3"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="secondary" variant="h6" component="p">
                  Atividades dessa aula
                </Typography>
                <DataGrid
                  dataSource={atividadesAdicionadas}
                  showBorders
                  hoverStateEnabled
                  noDataText="Sem Atividades Adicionadas"
                  onCellPrepared={event => {
                    prepareCell(event);
                  }}
                >
                  <Column
                    caption="ID"
                    dataField="atividade_id"
                    visible={false}
                  />
                  <Column caption="TIPO" dataField="tipo_atividade" />
                  <Column caption="TÍTULO" dataField="atividade" />
                  <Column caption="DESCRIÇÃO" dataField="descricao" />
                  <Column type="buttons">
                    <ButtonGrid
                      render={event => {
                        return (
                          <>
                            {!event.data.respondida && (
                              <>
                                <IconButton
                                  onClick={() => {
                                    const data = {
                                      ...event.data,
                                      inicio: removeTimezoneDate(
                                        event.data.inicio
                                      ),
                                      prazo: removeTimezoneDate(
                                        event.data.prazo
                                      ),
                                    };
                                    setEditarAtividade(data);
                                  }}
                                >
                                  <Edit color="secondary" />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    removerAtividade(event.data);
                                  }}
                                >
                                  <Close color="error" />
                                </IconButton>
                              </>
                            )}
                          </>
                        );
                      }}
                    />
                  </Column>
                </DataGrid>
              </Grid>
              {turmaEscolhida && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      setShowModalAtividade(true);
                    }}
                  >
                    Adicionar uma atividade
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.media}>
            {!videoUploaded && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label="URL do vídeo"
                    name="streaming_url"
                    fullWidth
                    helperText={
                      initialData.streaming_url &&
                      initialData.data_upload_video &&
                      `Upload realizado em ${format(
                        new Date(initialData.data_upload_video),
                        'dd/MM/yyyy',
                        {
                          locale: ptBr,
                        }
                      )}`
                    }
                    disabled={
                      !!initialData.streaming_url &&
                      initialData.streaming_tipo !== 3
                    }
                    onChange={() => handleChangeUrl()}
                  />
                  {(!initialData.streaming_url ||
                    (initialData.streaming_tipo === 3 &&
                      !isUrlFileUploaded())) && (
                    <>
                      <Grid
                        container
                        alignItems="center"
                        className={classes.uploadDivider}
                      >
                        <Grid item xs={5}>
                          <Divider variant="middle" component="div" />
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body1" align="center">
                            ou
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Divider variant="middle" component="div" />
                        </Grid>
                      </Grid>
                      <UploadComponent setFile={setVideoUploaded} />
                    </>
                  )}
                </Grid>
              </>
            )}
            {videoUploaded && (
              <Grid item xs={12} className={classes.file}>
                <Typography className={classes.fileName}>
                  {getHandledFileName()}
                </Typography>
                <IconButton size="small" onClick={() => setVideoUploaded(null)}>
                  <DeleteOutlineOutlined className={classes.btRemover} />
                </IconButton>
              </Grid>
            )}
            <Grid item xs={12} className={classes.ilustracao}>
              {(urlPreview && <ReactPlayer url={urlPreview} controls />) || (
                <Image
                  src={Ilustracao}
                  style={{
                    paddingTop: 'unset',
                    background: 'none',
                  }}
                  imageStyle={{
                    width: '100%',
                    height: 'unset',
                    position: 'unset',
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} className={classes.status}>
              <FormControl variant="filled" fullWidth margin="normal">
                <Select
                  label="Status de aula"
                  name="status"
                  onChange={event => {
                    setStatusEscolhido(event.target.value);
                  }}
                >
                  {lookup.videoAulaStatus.map(tipo => (
                    <MenuItem key={tipo.intkey} value={tipo.intkey}>
                      {tipo.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.buttons}>
              {(showLoading && (
                <ReactLoading
                  type="cylon"
                  color="#868CAB"
                  className={classes.loading}
                />
              )) || (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className={classes.button}
                    onClick={() => {
                      history.push('/video-aula');
                    }}
                  >
                    Voltar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.button}
                  >
                    Salvar
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Form>
      {showModalAtividade && Boolean(parametrosAtividades.cursos.length) && (
        <ModalAtividade
          openModal={showModalAtividade}
          handleClose={handleCloseModalAtividade}
          parametrosPesquisa={parametrosAtividades}
          adicionarAtividade={addAtividade}
        />
      )}
      {editarAtividade && (
        <ModalEditarAtividade
          openModal={!!editarAtividade}
          handleClose={handleCloseEditarAtividade}
          initialData={editarAtividade}
          atualizarAtividade={atualizarAtividade}
        />
      )}
    </>
  );
}

Formulario.propTypes = {
  initialData: PropTypes.shape().isRequired,
};

import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  requestProfessoresFailure,
  requestProfessoresSuccess,
} from './actions';

export function* requestProfessores() {
  try {
    const response = yield call(api.get, 'professores');

    yield put(requestProfessoresSuccess(response.data));
  } catch (err) {
    toast.error('Falha na requisição, contacte o suporte.');
    yield put(requestProfessoresFailure());
  }
}

export default all([
  takeLatest('@professores/REQUEST_PROFESSORES', requestProfessores),
]);

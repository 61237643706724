import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { validate } from 'cnpj';
import CpfValidate from 'cpf';
import { subYears } from 'date-fns';
import { toast } from 'react-toastify';

import TextField from '~/components/CustomUi/CustomTextField';
import CpfTextField from '~/components/CustomUi/CpfTextField';
import CnpjTextField from '~/components/CustomUi/CnpjTextField';
import CepTextField from '~/components/CustomUi/CepTextField';
import CustomDatePicker from '~/components/CustomUi/CustomDatePicker';
import PhoneTextField from '~/components/CustomUi/PhoneTextField';
import Checkbox from '~/components/CustomUi/CustomCheckbox';
import Select from '~/components/CustomUi/CustomSelect';

import api from '~/services/api';

const schema = Yup.object().shape({
  pessoaFisica: Yup.boolean(),
  pessoaJuridica: Yup.boolean(),
  cpf_cnpj: Yup.string().when('pessoaFisica', {
    is: true,
    then: Yup.string().required('O CPF é obrigatório'),
    otherwise: Yup.string(),
  }),
  nome: Yup.string().required('O nome é obrigatório'),
  apelido: Yup.string(),
  nascimento: Yup.string().when('pessoaFisica', {
    is: true,
    then: Yup.string().required('A data de nascimento é obrigatório'),
    otherwise: Yup.string(),
  }),
  rg_ir: Yup.string().when('pessoaFisica', {
    is: true,
    then: Yup.string().required('O RG é obrigatório'),
    otherwise: Yup.string(),
  }),
  sexo: Yup.string().when('pessoaFisica', {
    is: true,
    then: Yup.string().required('O sexo é obrigatório'),
    otherwise: Yup.string(),
  }),
  estado_civil: Yup.string().when('pessoaFisica', {
    is: true,
    then: Yup.string().required('O estado civil é obrigatório'),
    otherwise: Yup.string(),
  }),
  mae_nome: Yup.string().when('pessoaFisica', {
    is: true,
    then: Yup.string().required('O nome da mãe é obrigatório'),
    otherwise: Yup.string(),
  }),
  pai_nome: Yup.string().when('pessoaFisica', {
    is: true,
    then: Yup.string().required('O nome do pai é obrigatório'),
    otherwise: Yup.string(),
  }),
  escolaridade: Yup.string().when('pessoaFisica', {
    is: true,
    then: Yup.string().required('A escolaridade é obrigatória'),
    otherwise: Yup.string(),
  }),
  profissao: Yup.string(),
  nomeFantasia: Yup.string(),
  inscricaoMunicipal: Yup.string(),
  inscricaoEstadual: Yup.string(),
  email: Yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
  celular: Yup.string(),
  telefone: Yup.string(),
  ramal: Yup.string(),
  endereco_cep: Yup.string(),
  endereco: Yup.string(),
  endereco_numero: Yup.string(),
  endereco_complemento: Yup.string(),
  endereco_bairro: Yup.string(),
  endereco_cidade: Yup.string(),
  endereco_estado: Yup.string(),
});

const useStyles = makeStyles({
  root: {
    margin: '10px 15px',
  },
  buttons: {
    margin: '10px',
  },
  buttonText: {
    color: '#fff',
  },
});

export default function DadosTitular({
  previousStep,
  nextStep,
  setDadosTitular,
  setDadosPessoais,
  initialDataAluno,
  initialDataTitular,
  setOpenConfirmationExit,
}) {
  const classes = useStyles();

  const [checkPessoaFisica, setCheckPessoaFisica] = useState(
    initialDataTitular.pessoaFisica ||
      (!initialDataTitular.pessoaFisica && !initialDataTitular.pessoaJuridica)
  );
  const [checkPessoaJuridica, setCheckPessoaJuridica] = useState(
    initialDataTitular.pessoaJuridica
  );
  const [cpfValid, setCpfValid] = useState(false);
  const [cnpjValid, setCnpjValid] = useState(false);
  const [sexoOptions, setSexoSelectValues] = useState(null);
  const [escolaridadeOptions, setEscolaridadeSelectValues] = useState(null);
  const [estadoCivilOptions, setEstadoCivilSelectValues] = useState(null);
  const [enderecoCompleto, setEnderecoCompleto] = useState(null);
  const [
    openConfirmationAlunoTitular,
    setOpenConfirmationAlunoTitular,
  ] = useState(false);

  const sexo = useSelector(state => state.lookup.sexo);
  const escolaridade = useSelector(state => state.lookup.escolaridade);
  const estadoCivil = useSelector(state => state.lookup.estadoCivil);
  const unidade_id = useSelector(state => state.unidade.unidade_id);

  useEffect(() => {
    if (!sexoOptions && sexo.length > 0) {
      const arraySelectValues = [];
      sexo.map(sexoMap => {
        arraySelectValues.push({
          value: sexoMap.key_id,
          label: sexoMap.descricao,
        });
      });

      setSexoSelectValues(arraySelectValues);
    }

    if (!escolaridadeOptions && escolaridade.length > 0) {
      const arraySelectValues = [];
      escolaridade.map(escolaridadeMap => {
        arraySelectValues.push({
          value: escolaridadeMap.key_id,
          label: escolaridadeMap.descricao,
        });
      });

      setEscolaridadeSelectValues(arraySelectValues);
    }
    if (!estadoCivilOptions && estadoCivil.length > 0) {
      const arraySelectValues = [];
      estadoCivil.map(estadoCivilMap => {
        arraySelectValues.push({
          value: estadoCivilMap.key_id,
          label: estadoCivilMap.descricao,
        });
      });

      setEstadoCivilSelectValues(arraySelectValues);
    }

    if (initialDataTitular.cpf) {
      setCpfValid(true);
    }

    if (initialDataTitular.cnpj) {
      setCnpjValid(true);
    }
  }, [
    escolaridade,
    escolaridade.length,
    escolaridadeOptions,
    estadoCivil,
    estadoCivil.length,
    estadoCivilOptions,
    initialDataAluno.cnpj,
    initialDataAluno.cpf,
    initialDataTitular.cnpj,
    initialDataTitular.cpf,
    sexo,
    sexoOptions,
  ]);

  function handleChangeTipoPessoa() {
    setCheckPessoaFisica(!checkPessoaFisica);
    setCheckPessoaJuridica(!checkPessoaJuridica);
    setCpfValid(false);
    setCnpjValid(false);
    setDadosTitular({});
  }

  function handleSubmit(data) {
    if (data.pessoaFisica) {
      const dataSplitted = data.nascimento.split('/');
      data.nascimento = `${dataSplitted[2]}-${dataSplitted[1]}-${dataSplitted[0]}`;
    }

    const hasTelefone = data.telefone.match(/\d/);
    const hasCelular = data.celular.match(/\d/);

    if (!hasTelefone) {
      data.telefone = '';
    }

    if (!hasCelular) {
      data.celular = '';
    }

    data.unidade_id = unidade_id;

    setDadosTitular(data);
    nextStep();
  }

  function cpfChanged(cpfDigitado) {
    if (CpfValidate.isValid(cpfDigitado)) {
      setCpfValid(true);
    } else {
      setCpfValid(false);
    }
  }

  function cnpjChanged(cnpjDigitado) {
    if (validate(cnpjDigitado)) {
      setCnpjValid(true);
    } else {
      setCnpjValid(false);
    }
  }

  async function cepChanged(cepDigitado) {
    const cepFormatted = cepDigitado.replace(/\D+/, '');
    const hasNonDigit = cepFormatted.match(/\D/);
    if (!hasNonDigit && cepFormatted.length > 7) {
      await api
        .get(`consulta-cep/${cepFormatted}`)
        .then(response => {
          setEnderecoCompleto(response.data);
        })
        .catch(() => {
          toast.error('CEP não encontrato.');
        });
    }
  }

  function handleChangeAlunoTitular() {
    setOpenConfirmationAlunoTitular(true);
  }

  function handleOkAlunoTitular() {
    const dadosAluno = initialDataAluno;
    if (dadosAluno.alunoTitular) {
      dadosAluno.alunoTitular = false;
      setDadosPessoais(dadosAluno);
      setDadosTitular({});
      setOpenConfirmationAlunoTitular(false);
    } else {
      dadosAluno.alunoTitular = true;
      setDadosPessoais(dadosAluno);
      setDadosTitular(dadosAluno);
      setOpenConfirmationAlunoTitular(false);
      nextStep();
    }
  }

  return (
    <div className={classes.root}>
      <Form
        onSubmit={handleSubmit}
        schema={schema}
        initialData={initialDataTitular}
      >
        <Typography component="h6" variant="h6">
          Preencha os campos abaixo para cadastrar os dados pessoais do aluno
        </Typography>
        <div>
          <Checkbox
            name="alunoTitular"
            label="O aluno também é o titular desse contrato"
            onChange={handleChangeAlunoTitular}
            color="secondary"
            valor={initialDataTitular.alunoTitular}
          />
        </div>
        <Typography component="span" variant="caption" color="secondary">
          Escolha um tipo de contrato
        </Typography>
        <div>
          <Checkbox
            name="pessoaFisica"
            label="Pessoa Física"
            valor={checkPessoaFisica}
            onChange={handleChangeTipoPessoa}
            color="secondary"
            disabled={initialDataTitular.alunoTitular}
          />
          <Checkbox
            name="pessoaJuridica"
            label="Pessoa Jurídica"
            valor={checkPessoaJuridica}
            onChange={handleChangeTipoPessoa}
            color="secondary"
            disabled={initialDataTitular.alunoTitular}
          />
        </div>
        <Typography component="p" color="secondary">
          {(checkPessoaFisica && 'Dados pessoais') || 'Dados da empresa'}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            {(checkPessoaFisica && (
              <CpfTextField
                name="cpf_cnpj"
                label="CPF do titular"
                placeholder="Digite o CPF do aluno"
                valueChanged={cpfChanged}
                disabled={initialDataTitular.alunoTitular}
              />
            )) || (
              <CnpjTextField
                name="cpf_cnpj"
                label="CNPJ da empresa"
                placeholder="Digite o CNPJ da empresa"
                valueChanged={cnpjChanged}
                disabled={initialDataTitular.alunoTitular}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {(checkPessoaFisica && (
              <TextField
                name="nome"
                label="Nome do titular"
                placeholder="Digite o nome do aluno"
                disabled={!cpfValid || initialDataTitular.alunoTitular}
                required
              />
            )) || (
              <TextField
                name="nome"
                label="Razão social"
                placeholder="Digite a razão social da empresa"
                disabled={!cnpjValid || initialDataTitular.alunoTitular}
                required
              />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {(checkPessoaFisica && (
              <TextField
                name="rg_ir"
                label="RG"
                type="number"
                placeholder="Digite o RG do aluno"
                disabled={!cpfValid || initialDataTitular.alunoTitular}
              />
            )) || (
              <TextField
                name="nomeFantasia"
                label="Nome fantasia"
                placeholder="Digite o nome fantasia da empresa"
                disabled={!cnpjValid || initialDataTitular.alunoTitular}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {(checkPessoaFisica && (
              <CustomDatePicker
                name="nascimento"
                label="Data de nascimento"
                disableFuture
                fullWidth
                disabled={!cpfValid || initialDataTitular.alunoTitular}
                maxDate={subYears(new Date(), 15)}
                required
              />
            )) || (
              <TextField
                name="inscricaoEstadual"
                label="Inscrição estadual"
                placeholder="Digite a inscrição estadual da empresa"
                type="number"
                disabled={!cnpjValid || initialDataTitular.alunoTitular}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {(checkPessoaFisica && (
              <TextField
                name="apelido"
                label="Apelido"
                placeholder="Digite o apelido"
                disabled={!cpfValid || initialDataTitular.alunoTitular}
              />
            )) || (
              <TextField
                name="inscricaoMunicipal"
                label="Inscrição municipal"
                placeholder="Digite a inscrição municipal da empresa"
                type="number"
                disabled={!cnpjValid || initialDataTitular.alunoTitular}
              />
            )}
          </Grid>
          {checkPessoaFisica && (
            <>
              <Grid item xs={12} md={3}>
                <Select
                  name="sexo"
                  label="Sexo"
                  options={sexoOptions}
                  disabled={!cpfValid || initialDataTitular.alunoTitular}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Select
                  name="estado_civil"
                  label="Estado civil"
                  placeholder="Digite o estado civil"
                  disabled={!cpfValid || initialDataTitular.alunoTitular}
                  required
                  options={estadoCivilOptions}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="mae_nome"
                  label="Nome da mãe"
                  placeholder="Digite o nome da mãe"
                  disabled={!cpfValid || initialDataTitular.alunoTitular}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="pai_nome"
                  label="Nome do pai"
                  placeholder="Digite o nome do pai"
                  disabled={!cpfValid || initialDataTitular.alunoTitular}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Select
                  name="escolaridade"
                  label="Escolaridade"
                  disabled={!cpfValid || initialDataTitular.alunoTitular}
                  required
                  options={escolaridadeOptions}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="profissao"
                  label="Profissão"
                  placeholder="Digite a profissão"
                  disabled={!cpfValid || initialDataTitular.alunoTitular}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography component="p" color="secondary">
              Dados de contato
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              name="email"
              label="Email"
              type="email"
              disabled={
                (!cpfValid && !cnpjValid) || initialDataTitular.alunoTitular
              }
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PhoneTextField
              name="celular"
              label="Celular"
              disabled={
                (!cpfValid && !cnpjValid) || initialDataTitular.alunoTitular
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PhoneTextField
              name="telefone"
              label="Telefone comercial"
              isFixo
              disabled={
                (!cpfValid && !cnpjValid) || initialDataTitular.alunoTitular
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              name="ramal"
              label="Digite o ramal de houver"
              type="number"
              disabled={
                (!cpfValid && !cnpjValid) || initialDataTitular.alunoTitular
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" color="secondary">
              Dados de endereço
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <CepTextField
              name="endereco_cep"
              label="CEP"
              disabled={
                (!cpfValid && !cnpjValid) || initialDataTitular.alunoTitular
              }
              valueChanged={cepChanged}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              name="endereco"
              label="Endereço"
              disabled
              valor={(enderecoCompleto && enderecoCompleto.logradouro) || ''}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              name="endereco_numero"
              label="Número"
              disabled={
                (!cpfValid && !cnpjValid) || initialDataTitular.alunoTitular
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              name="endereco_complemento"
              label="Complemento"
              disabled={
                (!cpfValid && !cnpjValid) || initialDataTitular.alunoTitular
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              name="endereco_bairro"
              label="Bairro"
              disabled
              valor={(enderecoCompleto && enderecoCompleto.bairro) || ''}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              name="endereco_cidade"
              label="Cidade"
              disabled
              valor={(enderecoCompleto && enderecoCompleto.localidade) || ''}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              name="endereco_estado"
              label="Estado"
              disabled
              valor={(enderecoCompleto && enderecoCompleto.uf) || ''}
            />
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            className={classes.buttons}
          >
            <Grid item xs={12} sm={3} md={2}>
              <Button
                type="button"
                color="secondary"
                variant="text"
                fullWidth
                onClick={() => {
                  setOpenConfirmationExit(true);
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                fullWidth
                onClick={() => {
                  previousStep();
                }}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                className={classes.buttonText}
              >
                Avançar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openConfirmationAlunoTitular}
      >
        <DialogContent dividers>
          <Typography>
            {(initialDataAluno.alunoTitular &&
              'Tem certeza que deseja desmarcar o aluno como titular?') ||
              `Tem certeza que deseja marcar o aluno
            como titular? Nessa ação você perderá todos os dados já informados
            para o titlar.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenConfirmationAlunoTitular(false);
            }}
            color="primary"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleOkAlunoTitular}
            color="primary"
            variant="contained"
            className={classes.buttonText}
          >
            {(initialDataAluno.alunoTitular && 'Continuar') || 'Perder Dados'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DadosTitular.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  setDadosTitular: PropTypes.func.isRequired,
  setDadosPessoais: PropTypes.func.isRequired,
  initialDataAluno: PropTypes.shape().isRequired,
  initialDataTitular: PropTypes.shape().isRequired,
  setOpenConfirmationExit: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Menu,
  MenuItem,
  Button,
  Badge,
  Typography,
  Chip,
  Select,
  FormControl,
  InputLabel,
  Divider,
  Checkbox,
  ListItemText,
  FilledInput,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { FilterList } from '@material-ui/icons';
import { isValid, format } from 'date-fns';

import api from '~/services/api';

import { useFilters } from '~/hooks/filters';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    width: '442px',
    height: '454px',
    padding: '34px 42px',
  },
  filterBadge: {
    marginLeft: '15px',
  },
  btOpen: {
    width: '160px',
  },
  menu: {
    padding: '10px 37px',
    width: '350px',
  },
  textField: {
    margin: '5px 0',
  },
  btSubmit: {
    margin: '5px 0',
    color: '#fff',
  },
  chip: {
    textTransform: 'capitalize',
    marginLeft: '5px',
  },
  divider: {
    marginTop: '15px',
  },
}));

export default function ModalFiltros({ applyFilters, filters, professores }) {
  const classes = useStyles();
  const { filters: appliedFilters, setFilters } = useFilters();
  const cursos = useSelector(state => state.cursos.data);
  const turmas = useSelector(state => state.turmas.data);
  const lookup = useSelector(state => state.lookup);

  const [anchorEl, setAnchorEl] = useState(null);
  const [activatedFilters, setActivatedFilters] = useState(() => {
    const filtersActive = [];
    if (!!appliedFilters.videoaulas) {
      Object.keys(appliedFilters.videoaulas).map(filter => {
        if (filter === 'conteudo') {
          filter = 'conteúdo';
        }

        if (filter === 'materia') {
          filter = 'matéria';
        }
        filtersActive.push(filter);
      });
    }

    return filtersActive;
  });
  const [objectFilters, setObjectFilters] = useState(filters);
  const [materias, setMaterias] = useState(null);
  const [statusSelecionados, setStatusSelecionados] = useState(
    appliedFilters.videoaulas.status || []
  );

  const getMaterias = async query => {
    const response = await api.get('/materias', { params: query });
    setMaterias(response.data);
  };

  useEffect(() => {
    if (!materias) {
      getMaterias();
    }
  }, [materias]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = chip => () => {
    const newObjectFilters = { ...objectFilters };
    if (chip === 'conteúdo') {
      delete newObjectFilters.conteudo;
    }

    if (chip === 'matéria') {
      delete newObjectFilters.materia;
    }

    if (chip === 'status') {
      setStatusSelecionados([]);
    }

    delete newObjectFilters[chip];
    setObjectFilters(newObjectFilters);
    setActivatedFilters(Object.keys(newObjectFilters));
    applyFilters(newObjectFilters);
    setFilters({ ...appliedFilters, videoaulas: newObjectFilters });
  };

  const handleChange = event => {
    const newObjectFilters = { ...objectFilters };

    if (
      (!event.target.value && event.target.checked === undefined) ||
      (event.target.name === 'conteudo' &&
        newObjectFilters.conteudo !== undefined &&
        newObjectFilters.conteudo === false)
    ) {
      delete newObjectFilters[event.target.name];
    } else {
      newObjectFilters[event.target.name] =
        event.target.value || event.target.checked;
    }

    if (event.target.name === 'status') {
      setStatusSelecionados(event.target.value);
    }

    setObjectFilters(newObjectFilters);
  };

  const handleChangeDate = fieldName => value => {
    const newObjectFilters = { ...objectFilters };

    if (!isValid(value)) {
      delete newObjectFilters[fieldName];
    } else {
      newObjectFilters[fieldName] = format(value, 'yyyy-MM-dd');
    }

    setObjectFilters(newObjectFilters);
  };

  const handleSubmit = () => {
    applyFilters(objectFilters);
    setFilters({ ...appliedFilters, videoaulas: objectFilters });
    const filtersActive = [];

    Object.keys(objectFilters).map(filter => {
      if (filter === 'conteudo') {
        filter = 'conteúdo';
      }

      if (filter === 'materia') {
        filter = 'matéria';
      }
      filtersActive.push(filter);
    });

    setActivatedFilters(filtersActive);
    handleClose();
  };

  const handleChangeTurma = async turma => {
    const query = {
      turma,
    };

    if (objectFilters.curso) {
      query.curso = objectFilters.curso;
    }

    getMaterias(query);
  };

  const handleChangeCurso = async curso => {
    const query = {
      curso,
    };

    if (objectFilters.turma) {
      query.turma = objectFilters.turma;
    }

    getMaterias(query);
  };

  const renderSelecionados = () => {
    const descricoes = [];
    lookup.videoAulaStatus
      .filter(statusFilter => statusSelecionados.includes(statusFilter.intkey))
      .map(statusMapped => descricoes.push(statusMapped.descricao));

    return descricoes.join(', ');
  };

  return (
    <div>
      {activatedFilters &&
        activatedFilters.map(filter => (
          <Chip
            label={filter}
            onDelete={handleDelete(filter)}
            className={classes.chip}
            key={filter}
          />
        ))}
      <Button
        type="button"
        onClick={handleClick}
        variant="text"
        color="secondary"
        className={classes.btOpen}
        startIcon={<FilterList />}
        endIcon={
          <Badge
            className={classes.filterBadge}
            badgeContent={(activatedFilters && activatedFilters.length) || 0}
            color="secondary"
          />
        }
      >
        Filtros
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className={classes.menu}>
          <Typography color="secondary" variant="body1">
            Filtros
          </Typography>
          <Divider variant="fullWidth" />
          <FormControl variant="filled" fullWidth>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              label="Status"
              input={<FilledInput />}
              renderValue={() => renderSelecionados()}
              name="status"
              labelId="status-label"
              className={classes.textField}
              onChange={handleChange}
              defaultValue={filters.status}
              value={statusSelecionados}
              multiple
            >
              <MenuItem disabled>Selecione</MenuItem>
              {lookup.videoAulaStatus.map(status => (
                <MenuItem key={status.intkey} value={status.intkey}>
                  <Checkbox
                    checked={statusSelecionados.indexOf(status.intkey) > -1}
                  />
                  <ListItemText primary={status.descricao} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="curso-label">Curso</InputLabel>
            <Select
              labelId="curso-label"
              name="curso"
              variant="filled"
              className={classes.textField}
              onChange={event => {
                handleChange(event);
                handleChangeCurso(event.target.value);
              }}
              defaultValue={filters.curso}
              value={filters.curso || undefined}
            >
              <MenuItem>Selecione</MenuItem>
              {cursos &&
                cursos.map(curso => (
                  <MenuItem key={curso.curso_id} value={curso.curso_id}>
                    {curso.descricao}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="professor-label">Professor</InputLabel>
            <Select
              labelId="professor-label"
              name="professor"
              variant="filled"
              className={classes.textField}
              onChange={handleChange}
              defaultValue={filters.professor}
              value={filters.professor || undefined}
            >
              <MenuItem value="">Selecione</MenuItem>
              {professores &&
                professores.map(professor => (
                  <MenuItem
                    key={professor.professor_id}
                    value={professor.professor_id}
                  >
                    {professor.professor}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="turma-label">Turma</InputLabel>
            <Select
              labelId="turma-label"
              name="turma"
              variant="filled"
              className={classes.textField}
              onChange={event => {
                handleChange(event);
                handleChangeTurma(event.target.value);
              }}
              defaultValue={filters.turma}
              value={filters.turma || undefined}
            >
              <MenuItem value="">Selecione</MenuItem>
              {turmas &&
                turmas.map(turma => (
                  <MenuItem key={turma.turma_id} value={turma.turma_id}>
                    {turma.nome}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="materia-label">Matéria</InputLabel>
            <Select
              labelId="materia-label"
              name="materia"
              variant="filled"
              className={classes.textField}
              onChange={handleChange}
              defaultValue={filters.materia}
              value={filters.materia || undefined}
            >
              <MenuItem value="">Selecione</MenuItem>
              {materias &&
                materias.map(materia => (
                  <MenuItem key={materia.materia_id} value={materia.materia_id}>
                    {materia.materia}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <KeyboardDatePicker
            value={
              (objectFilters.data &&
                new Date(`${objectFilters.data} 03:00:00.000 UTC`)) ||
              null
            }
            format="dd/MM/yyyy"
            name="data"
            label="Data"
            inputVariant="filled"
            InputProps={{ color: 'secondary' }}
            fullWidth
            className={classes.textField}
            onChange={handleChangeDate('data')}
            color="secondary"
          />
          <Button
            type="button"
            variant="contained"
            fullWidth
            color="primary"
            className={classes.btSubmit}
            onClick={handleSubmit}
          >
            FILTRAR
          </Button>
        </div>
      </Menu>
    </div>
  );
}

ModalFiltros.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  professores: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export function cursosRequest() {
  return {
    type: '@cursos/CURSOS_REQUEST',
  };
}

export function cursosRequestSuccess(cursos) {
  return {
    type: '@cursos/CURSOS_REQUEST_SUCCESS',
    payload: { cursos },
  };
}

export function cursosRequestFailure() {
  return {
    type: '@cursos/CURSOS_REQUEST_FAILURE',
  };
}

import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import ptBr from 'date-fns/locale/pt';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import './config/ReactotronConfig';

import Routes from './routes';
import history from './services/history';

import { store, persistor } from './store';
import AppProvider from '~/hooks';

import GlobalStyle from './styles/global';

function App() {
  return (
    <AppProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
              <Routes />
              <GlobalStyle />
              <ToastContainer autoClose={3000} />
            </MuiPickersUtilsProvider>
          </Router>
        </PersistGate>
      </Provider>
    </AppProvider>
  );
}

export default App;

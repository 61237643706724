import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import DataGrid, {
  Column,
  Pager,
  Paging,
  GroupPanel,
  Grouping,
  MasterDetail,
} from 'devextreme-react/data-grid';

// import Filtros from '../Filtros';

import { useStyles } from './styles';

export default function GridAulas() {
  const classes = useStyles();
  const largeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));

  const [isLargeScreen, setIsLargeScreen] = useState(largeScreen);

  const { aulas } = useSelector(state => state.pautas);

  useEffect(() => {
    setIsLargeScreen(largeScreen);
  }, [largeScreen]); // eslint-disable-line

  return (
    <Box className={classes.box}>
      {/* <Filtros /> */}
      <DataGrid
        dataSource={aulas}
        allowColumnReordering
        showBorders
        hoverStateEnabled
        noDataText="Sem pautas cadastradas"
      >
        {!isLargeScreen && (
          <MasterDetail
            enabled
            component={e => {
              return (
                <>
                  <Typography variant="body2">
                    Turma: {e.data.data.turma}
                  </Typography>
                  <Typography variant="body2">
                    Matéria: {e.data.data.materia}
                  </Typography>
                  <Typography variant="body2">
                    Presentes: {e.data.data.presentes}
                  </Typography>
                </>
              );
            }}
          />
        )}

        <GroupPanel visible emptyPanelText="" />
        <Grouping autoExpandAll={false} />
        <Paging defaultPageSize={20} />
        <Pager showInfo infoText="Página {0} de {1} ({2} atividades)" />

        <Column caption="DATA DA AULA" dataField="data" />
        {isLargeScreen && <Column caption="TURMA" dataField="turma" />}
        {isLargeScreen && <Column caption="MATÉRIA" dataField="materia" />}
        <Column caption="PRESENTES" dataField="presentes" />
      </DataGrid>
    </Box>
  );
}

import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import clsx from 'clsx';
import { green, grey } from '@material-ui/core/colors';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Grid,
  Checkbox,
  FormControl,
  Select,
  FilledInput,
  MenuItem,
  ListItemText,
  InputLabel,
  Badge,
  Tooltip,
  withStyles,
  // Switch,
  // withStyles,
} from '@material-ui/core';
import { CheckCircle, Cancel, RemoveCircle } from '@material-ui/icons';
// import { DateTimePicker } from '@material-ui/pickers';
import DataGrid, {
  Column,
  GroupPanel,
  Grouping,
  Button as DxButton,
  Scrolling,
  MasterDetail,
} from 'devextreme-react/data-grid';
import { isValid, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { toast } from 'react-toastify';
import { confirm } from '~/components/Utils/Confirm';

import { useStyles } from './styles';

import {
  iniciarNovaPauta,
  cancelarNovaPauta,
  salvarNovaPauta,
  updateNovaPauta,
  requestAlunos,
} from '~/store/modules/pautas/actions';

import AnotacoesPauta from '../ModalFormAnotacoesAula';
import AnotacoesPresenca from '../ModalFormAnotacoesAluno';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 950px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 200px;
`;

const StyledCheckbox = withStyles(theme => ({
  root: {
    color: theme.palette.error.main,
  },
  indeterminate: {
    color: theme.palette.grey[600],
  },
  checked: {
    color: theme.palette.success.main,
  },
}))(props => <Checkbox color="default" {...props} />);

export default function ModalChamada() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { largeScreen } = useSelector(state => state.layout);
  const {
    novaPauta,
    materiaSelecionada,
    turmaSelecionada,
    filtros,
    alunos,
    proximaAula,
    conteudos,
    dadosPauta,
  } = useSelector(state => state.pautas);

  const { pauta } = useSelector(state => state.permissoes.acessos);
  const cursos = useSelector(state => state.cursos.data);
  const professores = useSelector(state => state.professores.data);
  const tipoPresenca = useSelector(state => state.lookup.tipoPresenca);
  const [open, setOpen] = useState(false);
  const [ultimasAulas, setUltimasAulas] = useState(null);
  const [conteudosSelecionados, setConteudosSelecionados] = useState([]);

  useEffect(() => {
    if (!alunos) {
      dispatch(
        requestAlunos({
          turma: turmaSelecionada.turma_id,
          materia: materiaSelecionada.materia_id,
        })
      );
    }
    setUltimasAulas(null);
  }, [alunos]); // eslint-disable-line

  useEffect(() => {
    if (open) {
      let ultimasDatas = [];

      if (alunos.length) {
        alunos.map(aluno => {
          Object.keys(aluno).map(key => {
            if (isValid(new Date(key))) {
              if (!ultimasDatas.includes(key)) {
                ultimasDatas.push(key);
              }
            }
          });
        });

        ultimasDatas.sort((a, b) => new Date(a) - new Date(b));

        ultimasDatas = ultimasDatas.slice(-3);
        setUltimasAulas(ultimasDatas);
      }

      setConteudosSelecionados(() => {
        return (
          (conteudos && conteudos.map(conteudo => conteudo.conteudo_id)) || []
        );
      });
    }
  }, [open]); // eslint-disable-line

  const getTipoPresencaPresencial = () => {
    const index = tipoPresenca.findIndex(tipo =>
      tipo.descricao.toLowerCase().includes('presencial')
    );
    return tipoPresenca[index].intkey;
  };

  const handleClickOpen = async () => {
    if (
      proximaAula.materia_id === materiaSelecionada.materia_id ||
      (await confirm(
        'Esta é a última aula prevista para esta matéria, ao confirmar, não será possível criar uma nova pauta de chamada. Deseja coninuar?'
      ))
    ) {
      const alunosPauta = alunos.map(aluno => ({
        aluno_id: aluno.id,
        contrato_id: aluno.contratoID,
        presente: dadosPauta.presencaAutomatica || null,
        tipopresenca: getTipoPresencaPresencial(),
        obs: null,
      }));

      const conteudoIds = [];

      if (conteudos) {
        conteudos.map(conteudo => {
          conteudoIds.push(conteudo.conteudo_id);
        });
      }

      dispatch(
        iniciarNovaPauta({
          turma: turmaSelecionada.turma_id,
          materia: materiaSelecionada.materia_id,
          professor: turmaSelecionada.professor_id,
          assistente: null,
          tipoAula: (proximaAula.tipo && proximaAula.tipo) || null,
          data:
            proximaAula.data && isValid(new Date(proximaAula.data))
              ? proximaAula.data
              : new Date(),
          tempo: (proximaAula.tempo && proximaAula.tempo) || null,
          anotacoes: null,
          finalizarMateria:
            proximaAula.materia_id !== materiaSelecionada.materia_id,
          alunos: alunosPauta,
          conteudos: conteudoIds,
          aula: proximaAula.aula_id,
        })
      );

      setOpen(true);
    }
  };

  const handleSave = async () => {
    if (new Date(proximaAula.data) > new Date()) {
      toast.info('Não é possível criar pauta com data futura.');
      return;
    }

    const pautaNaoConcluida = novaPauta.alunos.some(
      aluno => aluno.presente === null
    );

    if (pautaNaoConcluida) {
      toast.info(
        'Para salvar a pauta, todos os alunos devem receber presença ou falta.'
      );
      return;
    }

    if (
      proximaAula.materia_id === materiaSelecionada.materia_id ||
      (await confirm(
        'Ao confirmar, não será possível cadastrar nova pauta para esta matéria.'
      ))
    ) {
      dispatch(
        salvarNovaPauta(
          novaPauta,
          filtros,
          turmaSelecionada.turma_id,
          materiaSelecionada.materia_id
        )
      );
      setOpen(false);
    }
  };

  const handleClose = () => {
    dispatch(cancelarNovaPauta());
    setOpen(false);
  };

  // const handleDateChange = data => {
  //   dispatch(
  //     updateNovaPauta({
  //       ...novaPauta,
  //       data: format(data, "yyyy-MM-dd'T'HH:mm:ss"),
  //     })
  //   );
  // };

  const handleChangePresenca = (aluno, isPresente) => {
    const indexAluno = novaPauta.alunos.findIndex(
      alunoPauta => alunoPauta.aluno_id === aluno.id
    );
    const novaPautaAlunos = [...novaPauta.alunos];
    novaPautaAlunos[indexAluno] = {
      ...novaPauta.alunos[indexAluno],
      presente: isPresente,
    };

    dispatch(
      updateNovaPauta({
        ...novaPauta,
        alunos: novaPautaAlunos,
      })
    );
  };

  const prepareRenderCell = cell => {
    if (
      cell.rowType === 'header' &&
      cell.column.caption ===
        `${dadosPauta.aula && dadosPauta.aula.toUpperCase()} PRESENÇA OU FALTA`
    ) {
      cell.cellElement.style.whiteSpace = 'normal';
      cell.cellElement.style.wordWrap = 'break-word';
    }

    if (cell.column && cell.column.caption && cell.rowType !== 'header') {
      if (
        cell.column.caption.includes('/') ||
        cell.column.caption === 'BADGE'
      ) {
        if (Number(cell.displayValue) && Number(cell.displayValue) > 0) {
          const render = (largeScreen && (
            <CheckCircle style={{ color: green[500] }} />
          )) || (
            <Badge variant="dot" classes={{ badge: classes.colorSuccess }}>
              <Typography />
            </Badge>
          );
          cell.cellElement.innerHTML = ReactDOMServer.renderToString(render);
        } else {
          let render = (largeScreen && <Cancel color="error" />) || (
            <Badge color="error" variant="dot">
              <Typography />
            </Badge>
          );

          if (cell.displayValue === null) {
            render = (largeScreen && <RemoveCircle color="disabled" />) || (
              <Badge variant="dot" className={classes.icDisabled}>
                <Typography />
              </Badge>
            );
          }
          cell.cellElement.innerHTML = ReactDOMServer.renderToString(render);
        }
      }

      if (cell.column.caption === 'ALUNO') {
        cell.cellElement.style.whiteSpace = 'normal';
        cell.cellElement.style.wordWrap = 'break-word';
      }
    }
  };

  const renderSelecionados = () => {
    const descricoes = [];
    conteudos
      .filter(conteudoFilter =>
        conteudosSelecionados.includes(conteudoFilter.conteudo_id)
      )
      .map(conteudoMapped => descricoes.push(conteudoMapped.descricao));
    return descricoes.join(', ');
  };

  const handleChangeConteudo = event => {
    setConteudosSelecionados(event.target.value);

    dispatch(
      updateNovaPauta({
        ...novaPauta,
        conteudos: event.target.value,
      })
    );
  };

  const getCursoTurma = () => {
    const cursoFiltered = cursos.filter(
      curso => curso.curso_id === turmaSelecionada.curso_id
    );

    return (cursoFiltered.length && cursoFiltered[0].descricao) || '';
  };

  const getProfessorTurma = () => {
    const professorFiltered = professores.filter(
      professor => professor.professor_id === turmaSelecionada.professor_id
    );
    return professorFiltered[0].professor;
  };

  const canCreatePauta = () => {
    return (
      materiaSelecionada &&
      dadosPauta &&
      (dadosPauta.aula_id || pauta.lancaraulaextra)
    );
  };

  const aulaExtra = () => {
    return dadosPauta && !dadosPauta.aula_id && pauta.lancaraulaextra;
  };

  const renderIconMasterDetail = (dadosAula, data) => {
    if (dadosAula[data]) {
      if (dadosAula[data].presente) {
        return <CheckCircle style={{ color: green[500] }} />;
      }

      if (dadosAula[data].presente === null) {
        return <RemoveCircle style={{ color: grey[500] }} />;
      }
    }

    return <Cancel color="error" />;
  };

  return (
    <div>
      <Tooltip title={(aulaExtra() && 'Aula extra') || ''}>
        <Button
          onClick={handleClickOpen}
          variant="contained"
          color="primary"
          disabled={!canCreatePauta()}
          className={clsx(classes.btCreate, aulaExtra() && classes.btWarn)}
        >
          Criar nova pauta
        </Button>
      </Tooltip>
      <StyledDialog
        open={open}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Grid container>
            <Grid item xs={12} md={6}>
              Nova pauta de chamada
              <Typography component="p" variant="caption" color="secondary">
                Para criar um nova pauta, selecione o dia e o conteúdo aula
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridBtAnotacoes}>
              <AnotacoesPauta />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Curso: {getCursoTurma()}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                Turma: {turmaSelecionada.nome}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                Matéria: {materiaSelecionada.materia}
              </Typography>
            </Grid>
            {turmaSelecionada.professor_id && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Professor: {getProfessorTurma()}
                </Typography>
              </Grid>
            )}
            {dadosPauta && dadosPauta.aula && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Aula: {dadosPauta.aula}</Typography>
              </Grid>
            )}
            {novaPauta && novaPauta.data && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Data:{' '}
                  {format(
                    new Date(novaPauta.data),
                    "dd/MM/yyyy 'à(s)' HH:mm 'hora(s)'",
                    {
                      locale: ptBR,
                    }
                  )}
                </Typography>
              </Grid>
            )}
            {/* {proximaAula && proximaAula.data && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Próxima Aula:{' '}
                  {format(
                    new Date(proximaAula.data),
                    "eeee',' dd 'de' MMMM 'de' yyyy 'às' H 'hora(s)'",
                    { locale: ptBR }
                  )}
                </Typography>
              </Grid>
            )} */}
            <Grid item xs={12} />
            {/* <Grid item xs={12} sm={6}>
              <DateTimePicker
                inputVariant="filled"
                fullWidth
                label="Data"
                format="dd/MM/yyyy HH:mm"
                value={(novaPauta && novaPauta.data) || new Date()}
                onChange={handleDateChange}
                animateYearScrolling
                disableFuture
                maxDateMessage="A data escolhida não pode ser maior a data atual"
                disabled={!pauta.alterardatalancamento}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="conteudos">Conteúdos</InputLabel>
                <Select
                  labelId="conteudos"
                  label="Conteúdo da aula"
                  name="conteudos"
                  value={conteudosSelecionados}
                  multiple
                  input={<FilledInput />}
                  renderValue={() => renderSelecionados()}
                  onChange={handleChangeConteudo}
                >
                  <MenuItem value={null} disabled>
                    Selecione
                  </MenuItem>
                  {conteudos &&
                    conteudos.map(conteudo => (
                      <MenuItem
                        key={conteudo.conteudo_id}
                        value={conteudo.conteudo_id}
                      >
                        <Checkbox
                          checked={
                            conteudosSelecionados.indexOf(
                              conteudo.conteudo_id
                            ) > -1
                          }
                        />
                        <ListItemText primary={conteudo.descricao} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.table}>
              <DataGrid
                dataSource={alunos}
                allowColumnReordering
                showBorders
                hoverStateEnabled
                noDataText="Sem alunos cadastrados para esta matéria"
                onCellPrepared={prepareRenderCell}
              >
                {!largeScreen && novaPauta && (
                  <MasterDetail
                    enabled
                    component={e => {
                      const indexAluno = novaPauta.alunos.findIndex(
                        alunoPauta => alunoPauta.aluno_id === e.data.data.id
                      );
                      return (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="body1" align="center">
                              Nome: {e.data.data.aluno}
                            </Typography>
                          </Grid>
                          {ultimasAulas &&
                            ultimasAulas.map(data => {
                              return (
                                <Grid item xs={4} key={data}>
                                  <Typography variant="body1" align="center">
                                    {format(new Date(data), 'dd/MM')}
                                  </Typography>
                                  <Typography variant="body1" align="center">
                                    {e.data.data &&
                                      renderIconMasterDetail(e.data.data, data)}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          <Grid item xs={12}>
                            <AnotacoesPresenca
                              aluno={e.data.data}
                              hasAnotacoes={Boolean(
                                novaPauta.alunos[indexAluno].obs
                              )}
                            />
                          </Grid>
                        </Grid>
                      );
                    }}
                  />
                )}
                <Scrolling columnRenderingMode="virtual" mode="infinite" />
                <GroupPanel visible emptyPanelText="" />
                <Grouping autoExpandAll={false} />

                <Column caption="ALUNO" dataField="aluno" />
                {ultimasAulas &&
                  ultimasAulas.map(data => (
                    <Column
                      caption={
                        (largeScreen && format(new Date(data), 'dd/MM')) ||
                        'BADGE'
                      }
                      dataField={`${data}.presente`}
                      width={(largeScreen && 65) || 20}
                      key={data}
                    />
                  ))}
                <Column
                  type="buttons"
                  caption={`${dadosPauta &&
                    dadosPauta.aula &&
                    dadosPauta.aula.toUpperCase()} PRESENÇA OU FALTA`}
                >
                  <DxButton
                    render={e => {
                      if (novaPauta) {
                        const indexAluno = novaPauta.alunos.findIndex(
                          alunoPauta => alunoPauta.aluno_id === e.data.id
                        );
                        return (
                          <StyledCheckbox
                            key={indexAluno}
                            defaultChecked={
                              novaPauta.alunos[indexAluno].presente
                            }
                            indeterminate={
                              novaPauta.alunos[indexAluno].presente === null
                            }
                            onChange={() => {
                              if (
                                [null, false].includes(
                                  novaPauta.alunos[indexAluno].presente
                                )
                              ) {
                                handleChangePresenca(e.data, true);
                              } else {
                                handleChangePresenca(e.data, false);
                              }
                            }}
                          />
                        );
                      }
                    }}
                  />
                </Column>
                {largeScreen && (
                  <Column type="buttons" caption="COMENTAR">
                    <DxButton
                      render={e => {
                        if (novaPauta) {
                          const indexAluno = novaPauta.alunos.findIndex(
                            alunoPauta => alunoPauta.aluno_id === e.data.id
                          );
                          return (
                            <AnotacoesPresenca
                              aluno={e.data}
                              hasAnotacoes={Boolean(
                                novaPauta.alunos[indexAluno].obs
                              )}
                            />
                          );
                        }
                      }}
                    />
                  </Column>
                )}
              </DataGrid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            Cancelar
          </StyledButton>
          <StyledButton
            onClick={handleSave}
            color="primary"
            variant="contained"
          >
            Salvar
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}

export function turmasRequestSuccess(turmas) {
  return {
    type: '@turmas/TURMAS_REQUEST_SUCCESS',
    payload: { turmas },
  };
}

export function turmasRequestFailure() {
  return {
    type: '@turmas/TURMAS_REQUEST_FAILURE',
  };
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Formulario from '~/components/VideoAula/FormularioUpdate';
import Breadcrumbs from '~/components/CustomUi/CustomBreadcrumbs';
import api from '~/services/api';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '17px 0 0 0',
    color: '#4D5884',
  },
  subtitle: {
    margin: '0 0 19px 0',
    color: '#868CAB',
  },
  card: {
    padding: '30px',
  },
});

export default function Editar({ match }) {
  const classes = useStyles();
  const [initialState, setInitialState] = useState(null);
  const { id } = match.params;

  useEffect(() => {
    async function getDadosVideoAula() {
      const response = await api.get(`videoaula/${id}`);
      setInitialState(response.data);
    }

    if (!initialState) {
      getDadosVideoAula();
    }
  }, [id, initialState]);

  return (
    <>
      <Breadcrumbs
        categorias={['Videoaula']}
        funcionalidade="Editar Videoaula"
      />
      <Typography variant="h5" className={classes.title}>
        Editar videoaula
      </Typography>
      <Card className={classes.card}>
        {initialState && <Formulario initialData={initialState} />}
      </Card>
    </>
  );
}

Editar.propTypes = {
  match: PropTypes.shape().isRequired,
};

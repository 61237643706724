import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
  },
  btTurma: {
    marginRight: '10px',
  },
}));

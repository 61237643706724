import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Button } from 'devextreme-react/data-grid';
import { IconButton } from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';

export default function GridComplementos({
  complementos,
  removerComplemento,
  editarComplemento,
}) {
  return (
    <DataGrid
      dataSource={complementos}
      showBorders
      hoverStateEnabled
      noDataText="Sem anexos adicionados"
    >
      <Column caption="NOME" dataField="name" />
      <Column caption="EDITAR" type="buttons">
        <Button
          render={e => {
            if (!e.data.fileName) {
              return (
                <>
                  <IconButton
                    onClick={() => {
                      editarComplemento(e.data);
                    }}
                  >
                    <Edit color="primary" />
                  </IconButton>
                </>
              );
            }
          }}
        />
      </Column>
      <Column caption="REMOVER" type="buttons">
        <Button
          render={e => {
            return (
              <>
                <IconButton
                  onClick={() => {
                    removerComplemento(e.data);
                  }}
                >
                  <Close color="error" />
                </IconButton>
              </>
            );
          }}
        />
      </Column>
    </DataGrid>
  );
}

GridComplementos.propTypes = {
  complementos: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  removerComplemento: PropTypes.func.isRequired,
  editarComplemento: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Column, Button } from 'devextreme-react/data-grid';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export default function TableAtividadesAdicionadas({
  atividades,
  removerAtividade,
}) {
  function prepareCell(e) {
    if (e.rowType === 'data') {
      if (e.column.caption === 'DESCRIÇÃO') {
        e.cellElement.innerText =
          e.data.descricao.length < 29
            ? e.data.descricao
            : `${e.data.descricao.substring(0, 28)}...`;
      }
    }
  }

  return (
    <DataGrid
      dataSource={atividades}
      showBorders
      hoverStateEnabled
      noDataText="Sem Atividades Adicionadas"
      onCellPrepared={e => {
        prepareCell(e);
      }}
    >
      <Column caption="ID" dataField="atividade_id" visible={false} />
      <Column caption="TIPO" dataField="tipo_atividade_descricao" />
      <Column caption="TÍTULO" dataField="titulo" />
      <Column caption="DESCRIÇÃO" dataField="descricao" />
      <Column type="buttons">
        <Button
          render={e => {
            return (
              <IconButton
                onClick={() => {
                  removerAtividade(e.data);
                }}
              >
                <Close color="error" />
              </IconButton>
            );
          }}
        />
      </Column>
    </DataGrid>
  );
}

TableAtividadesAdicionadas.propTypes = {
  atividades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  removerAtividade: PropTypes.func.isRequired,
};

import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { turmasRequestSuccess, turmasRequestFailure } from './actions';
import { turmasRequest } from '../unidade/actions';

export function* request() {
  try {
    const response = yield call(api.get, 'turmas');

    yield put(turmasRequestSuccess(response.data));
  } catch (err) {
    if (err.response) {
      if (
        err.response.data.error ===
        'Ocorreu um erro ao tentar configurar sessão.'
      ) {
        yield put(turmasRequest());
      }
    } else {
      toast.error('Falha na requisição, contacte o suporte.');
      yield put(turmasRequestFailure());
    }
  }
}

export default all([takeLatest('@turmas/TURMAS_REQUEST', request)]);

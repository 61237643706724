import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { darken, lighten } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import {
  Modal,
  Grid,
  Backdrop,
  Fade,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import { DeleteOutlineOutlined, CloudUpload } from '@material-ui/icons';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    width: '442px',
    height: '454px',
    padding: '34px 42px',
  },
  container: {
    height: '100%',
  },
  tituloModal: {
    color: '#4D5884',
    fontSize: '16px',
  },
  tituloAtividade: {
    fontSize: '19px',
    color: '#50A6FF',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  label: {
    color: '#50A6FF',
    letterSpacing: '1.5px',
    fontSize: '10px',
  },
  value: {
    color: '#868CAB',
    fontSize: '14px',
  },
  file: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(80, 166, 255, 0.4)',
    borderRadius: '8px',
    maxHeight: '39px',
    padding: '5px 15px',
    color: '#50A6FF',
    fontSize: '14px',
    margin: '5px 2px 0 0',
  },
  fileName: {
    fontWeight: 'bold',
  },
  btRemover: {
    color: '#50A6FF',
  },
  containerFiles: {
    maxHeight: '90px',
    overflowY: 'auto',
  },
  btEntregar: {
    color: '#fff',
    background: '#50A6FF',
    borderRadius: '200px',
    alignSelf: 'center',
    '&:hover': {
      background: `${darken(0.1, '#50A6FF')}`,
    },
  },
  dropZone: {
    border: `2px dashed ${lighten(0.2, '#868CAB')}`,
    borderRadius: '10px',
    width: '364px',
    height: '185px',
    margin: '15px 0',
    cursor: 'pointer',
    background: '#fff',
  },
  internDrop: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  aside: {
    color: '#999',
    textAlign: 'center',
  },
  paside: {
    color: '#50A6FF',
    fontWeight: 'bold',
  },
  pasideu: {
    color: '#333',
  },
  iconUpload: {
    color: '#50A6FF',
    fontSize: '110px',
  },
  btAnexo: {
    color: '#50A6FF',
    borderRadius: '200px',
  },
}));

Yup.setLocale({
  mixed: {
    default: 'Tipo de arquivo inválido',
  },
  string: {
    oneOf: 'Tipo de arquivo inválido',
  },
  number: {
    max: 'Arquivo excede o tamanho máximo permitido',
  },
});

const schema = Yup.object().shape({
  size: Yup.number()
    .max(500)
    .required(),
  type: Yup.string().oneOf(
    [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    'Tipo de arquivo inválido'
  ),
});

export default function ModalUpload({ handleAddArquivos }) {
  const classes = useStyles();

  const [arquivo, setArquivo] = useState(null);
  const [open, setOpen] = useState(false);

  async function validarArquivoUpdado() {
    const { size: tamanho, type } = arquivo;
    const size = tamanho / 1024 / 1024;

    schema.validate({ size, type }).catch(error => {
      setArquivo(null);
      toast.error(error.message);
    });
  }

  if (arquivo) {
    validarArquivoUpdado();
  }

  const onDrop = useCallback(acceptedFile => {
    setArquivo(acceptedFile[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  async function onClickHandler() {
    handleAddArquivos(arquivo);
    setArquivo(null);
    setOpen(false);
  }

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setOpen(true);
        }}
        variant="outlined"
        color="primary"
        className={classes.btAnexo}
        fullWidth
      >
        + Arquivo
      </Button>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12} className={classes.info}>
                  <div>
                    <Typography variant="h5" className={classes.tituloModal}>
                      Entrega de arquivo para
                    </Typography>
                    <Typography
                      variant="h6"
                      component="p"
                      className={classes.tituloAtividade}
                    >
                      Trabalho de informática para iniciantes
                    </Typography>
                  </div>
                  <div className={classes.dropZone}>
                    <div className={classes.internDrop} {...getRootProps()}>
                      <CloudUpload className={classes.iconUpload} />
                      <input {...getInputProps()} />
                      <aside className={classes.aside}>
                        <p className={classes.paside}>
                          Arraste seu arquivo ou{' '}
                          <u className={classes.pasideu}>busque</u>
                        </p>
                        <small>Suportamos arquivos até 50MB.</small>
                      </aside>
                    </div>
                  </div>
                  {arquivo && (
                    <Grid container className={classes.containerFiles}>
                      <Grid item xs={12} className={classes.file}>
                        <Typography className={classes.fileName}>
                          {arquivo.name}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => setArquivo(null)}
                        >
                          <DeleteOutlineOutlined
                            className={classes.btRemover}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    className={classes.btEntregar}
                    onClick={() => onClickHandler()}
                  >
                    ANEXAR
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
}

ModalUpload.propTypes = {
  handleAddArquivos: PropTypes.func.isRequired,
};

export function loockupRequest() {
  return {
    type: '@loockup/LOOCKUP_REQUEST',
  };
}

export function cursosRequest() {
  return {
    type: '@cursos/CURSOS_REQUEST',
  };
}

export function turmasRequest(unidade_id) {
  return {
    type: '@turmas/TURMAS_REQUEST',
    payload: { unidade_id },
  };
}

export function unidadeSelecionadaRequest(unidade_id, unidade) {
  return {
    type: '@unidade/UNIDADE_SELECIONADA_REQUEST',
    payload: { unidade_id, unidade },
  };
}

export function unidadeSelecionadaSuccess(permissoes) {
  return {
    type: '@unidade/UNIDADE_SELECIONADA_SUCCESS',
    payload: { permissoes },
  };
}

export function unidadeSelecionadaFailure() {
  return {
    type: '@unidade/UNIDADE_SELECIONADA_FAILURE',
  };
}

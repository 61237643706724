import React from 'react';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Image from 'material-ui-image';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ReactLoading from 'react-loading';

import TextField from '~/components/CustomUi/CustomTextField';

import { signInRequest } from '~/store/modules/auth/actions';

import LoginIlustracao from '~/assets/login_ilustracao.svg';
import LogoF10 from '~/assets/logo_f10.svg';

const schema = Yup.object().shape({
  login: Yup.string()
    .email('Favor, digitar um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  ilustracao: {
    background: '#868CAB',
  },
  gridIlustracao: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  formulario: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    padding: '35px',
  },
  button: {
    borderRadius: '200px',
    color: '#fff',
    marginTop: '7px',
  },
  loading: {
    margin: '0 auto',
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const loading = useSelector(state => state.auth.loading);
  const dispatch = useDispatch();

  function handleSubmit({ login, password }) {
    dispatch(signInRequest(login, password));
  }

  return (
    <Card className={classes.card}>
      <Grid item xs={12} sm={6} className={classes.gridIlustracao}>
        <Image
          src={LoginIlustracao}
          className={classes.ilustracao}
          style={{ height: '100%' }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.formulario}>
        <Image
          src={LogoF10}
          alt="logo"
          style={{ paddingTop: 'unset' }}
          imageStyle={{ position: 'unset', height: '104px' }}
        />
        <Form schema={schema} onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <TextField
              name="login"
              label="Login"
              placeholder="Digite seu email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              type="password"
              label="Senha"
              placeholder="Digite sua senha"
            />
          </Grid>
          {(loading && (
            <ReactLoading
              type="cylon"
              color="#868CAB"
              className={classes.loading}
            />
          )) || (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.button}
            >
              Acessar
            </Button>
          )}
        </Form>
      </Grid>
    </Card>
  );
}

import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  requestPautasSuccess,
  requestPautasFailure,
  requestMateriasSuccess,
  requestMateriasFailure,
  requestAlunos as requestAlunosAction,
  requestAlunosSuccess,
  requestAlunosFailure,
  requestSavePautaSuccess,
  requestConteudos,
  requestConteudosSuccess,
  requestConteudosFailure,
  requestPautas as requestPautasAction,
} from './actions';

export function* requestPautas({ payload }) {
  const { filtros, turma, materia } = payload;
  try {
    const response = yield call(api.get, `aulas/${turma}/${materia}`, {
      params: { ...filtros },
    });

    yield put(requestPautasSuccess(response.data));
  } catch (err) {
    if (err.response) {
      if (
        err.response.data.error ===
        'Ocorreu um erro ao tentar configurar sessão.'
      ) {
        yield put(requestPautasAction({ filtros, turma, materia }));
      }
      return;
    }
    toast.error('Falha na requisição, contacte o suporte.');
    yield put(requestPautasFailure());
  }
}

export function* requestSaveSchedule({ payload }) {
  try {
    const { pauta, filtros, turma, materia } = payload;
    yield call(api.post, 'pauta', pauta);

    yield put(requestSavePautaSuccess());
    yield put(requestPautasAction({ filtros, turma, materia }));
    yield put(requestAlunosAction({ turma, materia }));
    toast.success('Pauta salva com sucesso.');
  } catch (err) {
    toast.error('Falha na requisição, contacte o suporte.');
  }
}

export function* requestMaterias({ payload }) {
  try {
    const { turma } = payload;
    const response = yield call(api.get, `turma/${turma}/materias`);

    const groupBy = (array, property) => {
      return array.reduce((value, key) => {
        (value[key[property]] = value[key[property]] || []).push(key);
        return value;
      }, {});
    };

    yield put(requestMateriasSuccess(groupBy(response.data, 'status')));
  } catch (err) {
    toast.error('Falha na requisição, contacte o suporte.');
    yield put(requestMateriasFailure());
  }
}

export function* requestAlunos({ payload }) {
  const { turma, materia } = payload;
  try {
    const response = yield call(api.get, `pauta/${turma}/${materia}`);
    const { alunos, datas, dados } = response.data;
    const proximaAula = (datas.length && datas[datas.length - 1]) || null;

    const orderedAlunos =
      (alunos.length &&
        alunos.sort((a, b) =>
          a.aluno.toLowerCase() > b.aluno.toLowerCase() ? 1 : -1
        )) ||
      [];

    yield put(requestAlunosSuccess(orderedAlunos, proximaAula, dados));

    if (proximaAula) {
      yield put(requestConteudos(proximaAula.aula_id));
    }
  } catch (err) {
    if (err.response) {
      if (
        err.response.data.error ===
        'Ocorreu um erro ao tentar configurar sessão.'
      ) {
        yield put(requestAlunosAction({ turma, materia }));
      }
      return;
    }

    toast.error('Falha na requisição, contacte o suporte.');
    yield put(requestAlunosFailure());
  }
}

export function* requestConteudosAula({ payload }) {
  try {
    const { aula } = payload;
    const response = yield call(api.get, `aula/${aula}/conteudos`);

    yield put(requestConteudosSuccess(response.data));
  } catch (err) {
    toast.error('Falha na requisição, contacte o suporte.');
    yield put(requestConteudosFailure());
  }
}

export default all([
  takeLatest('@pautas/REQUEST_PAUTAS', requestPautas),
  takeLatest('@pautas/REQUEST_MATERIAS', requestMaterias),
  takeLatest('@pautas/REQUEST_ALUNOS', requestAlunos),
  takeLatest('@pautas/REQUEST_SAVE_NEW_SCHEDULE', requestSaveSchedule),
  takeLatest('@pautas/REQUEST_CONTEUDOS', requestConteudosAula),
]);

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  box: {
    padding: '20px 30px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
}));

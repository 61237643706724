import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@material-ui/core';
import { CommentOutlined } from '@material-ui/icons';

import { toast } from 'react-toastify';
import { updateNovaPauta } from '~/store/modules/pautas/actions';

import { useStyles } from './styles';

const MAX_CARACTERES_ALLOWED = 50;

export default function ModalFormAnotacoesAula() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { novaPauta } = useSelector(state => state.pautas);

  const [anotacoes, setAnotacoes] = useState(
    (novaPauta && novaPauta.anotacoes) || null
  );
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickSave = () => {
    if (anotacoes.length > MAX_CARACTERES_ALLOWED) {
      toast.warn(
        `O formulário excedeu máximo de ${MAX_CARACTERES_ALLOWED} caracteres permitidos.`
      );
      return;
    }

    dispatch(
      updateNovaPauta({
        ...novaPauta,
        anotacoes,
      })
    );
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="text"
        startIcon={<CommentOutlined />}
        color="primary"
        className={classes.btAnotacoes}
        onClick={handleClickOpen}
      >
        Anotações sobre a aula
      </Button>
      <Dialog
        open={open}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Anotações sobre a aula
        </DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            value={anotacoes}
            variant="filled"
            label="Comentário"
            placeholder="Digite um comentário"
            multiline
            minRows={5}
            onChange={event => setAnotacoes(event.target.value)}
            fullWidth
            error={anotacoes && anotacoes.length > MAX_CARACTERES_ALLOWED}
            helperText={`${(anotacoes && anotacoes.length) ||
              0} / ${MAX_CARACTERES_ALLOWED} caracteres permitidos`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancelar
          </Button>
          <Button
            onClick={onClickSave}
            color="primary"
            variant="contained"
            disabled={anotacoes && anotacoes.length > MAX_CARACTERES_ALLOWED}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  loockupRequestSuccess,
  loockupRequestFailure,
  loockupRequest,
} from './actions';

export function* request() {
  try {
    const response = yield call(api.get, 'lookup');

    yield put(loockupRequestSuccess(response.data));
  } catch (err) {
    if (err.response) {
      if (
        err.response.data.error ===
        'Ocorreu um erro ao tentar configurar sessão.'
      ) {
        yield put(loockupRequest());
      }
    } else {
      toast.error('Falha na requisição, contacte o suporte.');
      yield put(loockupRequestFailure());
    }
  }
}

export default all([takeLatest('@loockup/LOOCKUP_REQUEST', request)]);

import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken';

import AuthConfig from '~/config/AuthConfig';

import api from '~/services/api';

import {
  unidadeSelecionadaSuccess,
  unidadeSelecionadaFailure,
  loockupRequest,
  turmasRequest,
  cursosRequest,
} from './actions';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export function* selected({ payload }) {
  try {
    const { unidade_id } = payload;

    api.defaults.headers.unidade_header = unidade_id;

    const response = yield call(api.get, 'permissoes');
    const { unidades_videoaula, acessos, videofront, pessoa } = response.data;

    yield put(
      unidadeSelecionadaSuccess({
        acessos,
        videofront,
        unidades_videoaula,
        pessoa,
      })
    );
    yield delay(500);
    yield put(loockupRequest());
    yield delay(500);
    yield put(turmasRequest());
    yield delay(500);
    yield put(cursosRequest());
  } catch (err) {
    if (err.response && err.response.status === 401) {
      toast.info(
        'Permissões não encontradas, favor entrar em contato com o administrador'
      );
    } else {
      toast.error('Falha na requisição, contacte o suporte.');
    }
    yield put(unidadeSelecionadaFailure());
  }
}

export function* setUnidade({ payload }) {
  if (!payload || !payload.unidade || !payload.unidade.unidade_id) {
    return;
  }

  const { token } = payload.auth;

  try {
    jwt.verify(token, AuthConfig.secret);
  } catch (error) {
    return;
  }

  const { unidade_id } = payload.unidade;

  api.defaults.headers.Authorization = `Bearer ${token}`;
  api.defaults.headers.unidade_header = unidade_id;

  const response = yield call(api.get, 'permissoes');

  const { acessos, videofront, pessoa } = response.data;

  yield put(unidadeSelecionadaSuccess({ acessos, videofront, pessoa }));
  yield delay(500);
  yield put(loockupRequest());
  yield delay(500);
  yield put(turmasRequest());
  yield delay(500);
  yield put(cursosRequest());
}

export default all([
  takeLatest('persist/REHYDRATE', setUnidade),
  takeLatest('@unidade/UNIDADE_SELECIONADA_REQUEST', selected),
]);

import { makeStyles } from '@material-ui/core/styles';
import { darken } from 'polished';

export const useStyles = makeStyles(theme => ({
  gridBtAnotacoes: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btAnotacoes: {
    height: 'fit-content',
  },
  table: {
    width: '800px',
    max: '800px',
    overflow: 'auto',
  },
  colorSuccess: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
  btWarn: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    '&:hover': {
      background: darken(0.1, theme.palette.warning.main),
    },
  },
  btCreate: {
    borderRadius: '200px',
  },
  icInderteminate: {
    borderRadius: '100%',
  },
  icDisabled: {
    '& > span': {
      backgroundColor: theme.palette.grey[500],
    },
  },
}));

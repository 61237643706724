import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreVert } from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';

import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  buttonDialog: {
    borderRadius: '200px',
    minWidth: '120px',
    margin: theme.spacing(0, 1),
  },
}));

export default function MenuActions({ atividade }) {
  const classes = useStyles();
  const confirm = useConfirm();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDeactivate = async atividadeChoosed => {
    confirm({
      description:
        'Ao confirmar, esta atividade deixará de ser exibida para as novas matrículas.',
      title: 'Deseja continuar?',
      confirmationText: 'Continuar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        className: classes.buttonDialog,
      },
      cancellationButtonProps: {
        variant: 'contained',
        color: 'secondary',
        className: classes.buttonDialog,
      },
    })
      .then(async () => {
        await api.patch(`/atividade/${atividadeChoosed.atividade_id}/status`, {
          status: 2,
        });
        toast.success('Atividade desativada com sucesso.');
      })
      .catch(() => {});

    handleClose();
  };

  const handleClickDelete = atividadeChoosed => {
    confirm({
      description: 'Ao confirmar, esta atividade será excluída do sistema.',
      title: 'Deseja continuar?',
      confirmationText: 'Continuar',
      cancellationText: 'Cancelar',
      confirmationButtonProps: {
        variant: 'contained',
        className: classes.buttonDialog,
      },
      cancellationButtonProps: {
        variant: 'contained',
        color: 'secondary',
        className: classes.buttonDialog,
      },
    })
      .then(async () => {
        try {
          await api.delete(`/atividade/${atividadeChoosed.atividade_id}`);
          toast.success('Atividade excluída com sucesso.');
        } catch (error) {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.error);
          }
        }
      })
      .catch(() => {});

    handleClose();
  };

  return (
    <>
      {((!atividade.atividade_respondida && !atividade.atividade_videoaula) ||
        !atividade.desativada) && (
        <>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu
            id="card-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {!atividade.atividade_respondida &&
              !atividade.atividade_videoaula && (
                <MenuItem onClick={() => handleClickDelete(atividade)}>
                  Excluir
                </MenuItem>
              )}
            {!atividade.desativada && (
              <MenuItem onClick={() => handleClickDeactivate(atividade)}>
                Desativar
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </>
  );
}

MenuActions.propTypes = {
  atividade: PropTypes.shape().isRequired,
};

import { createTheme } from '@material-ui/core/styles';

export default function theme() {
  return createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#50A6FF',
        contrastText: '#fff',
      },
      secondary: {
        main: '#868CAB',
      },
      warning: {
        main: '#f97200',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });
}

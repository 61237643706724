import React from 'react';
import PropTypes from 'prop-types';
import { confirmable, createConfirmation } from 'react-confirm';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import theme from '~/styles/themes';

const ConfirmDialog = ({ show, proceed, confirmation }) => (
  <ThemeProvider theme={theme()}>
    <Dialog open={show} onClose={() => proceed(false)}>
      <DialogTitle>Deseja continuar?</DialogTitle>
      <DialogContent>
        <DialogContentText>{confirmation}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => proceed(false)} color="secondary">
          Cancelar
        </Button>
        <Button onClick={() => proceed(true)} color="primary" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  </ThemeProvider>
);

ConfirmDialog.propTypes = {
  // eslint-disable-next-line
  show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
  // eslint-disable-next-line
  proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
  // eslint-disable-next-line
  confirmation: PropTypes.string, // arguments of your confirm function
  // eslint-disable-next-line
  title: PropTypes.string, // arguments of your confirm function
};

export const confirm = confirmation => {
  return createConfirmation(confirmable(ConfirmDialog))({
    confirmation,
  });
};

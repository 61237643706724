import React from 'react';
import PropTypes from 'prop-types';

import { FiltersProvider } from '~/hooks/filters';

export default function AppProvider({ children }) {
  return <FiltersProvider>{children}</FiltersProvider>;
}

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

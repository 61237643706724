import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Iframe from 'react-iframe';

const useStyles = makeStyles({
  frame: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%',
    height: '100%',
    maxHeight: '700px',
  },
});

export default function Chat({ videoAula }) {
  const classes = useStyles();
  const unidade_id = useSelector(state => state.unidade.unidade_id);
  const userId = useSelector(state => state.user.id);

  const userChatEmail = `${userId}@smart.alu.no`;
  const userChatPass = 'f10mobile';
  const unidade = `000000${unidade_id}`.slice(-6);

  const chatUrl = `${process.env.REACT_APP_CHAT_BASE_URL}/?unit=${unidade}&room=${videoAula}&user=${userChatEmail}&pass=${userChatPass}`;

  return (
    <Iframe
      url={chatUrl}
      display="initial"
      position="relative"
      className={classes.frame}
    />
  );
}

Chat.propTypes = {
  videoAula: PropTypes.number.isRequired,
};

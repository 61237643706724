export function salvarTurmaSelecionada(turma) {
  return {
    type: '@pautas/TURMA_SELECIONADA',
    payload: { turma },
  };
}

export function salvarMateriaSelecionada(materia) {
  return {
    type: '@pautas/MATERIA_SELECIONADA',
    payload: { materia },
  };
}

export function requestPautas({ filtros, turma, materia }) {
  return {
    type: '@pautas/REQUEST_PAUTAS',
    payload: { filtros, turma, materia },
  };
}

export function requestPautasSuccess(aulas) {
  return {
    type: '@pautas/REQUEST_AULAS_SUCCESS',
    payload: { aulas },
  };
}

export function requestPautasFailure() {
  return {
    type: '@pautas/REQUEST_AULAS_FAILURE',
  };
}

export function requestMaterias({ turma }) {
  return {
    type: '@pautas/REQUEST_MATERIAS',
    payload: { turma },
  };
}

export function requestMateriasSuccess(materias) {
  return {
    type: '@pautas/REQUEST_MATERIAS_SUCCESS',
    payload: { materias },
  };
}

export function requestMateriasFailure() {
  return {
    type: '@pautas/REQUEST_MATERIAS_FAILURE',
  };
}

export function iniciarNovaPauta(novaPauta) {
  return {
    type: '@pautas/START_NEW_SCHEDULE',
    payload: { pauta: novaPauta },
  };
}

export function updateNovaPauta(novaPauta) {
  return {
    type: '@pautas/UPDATE_NEW_SCHEDULE',
    payload: { pauta: novaPauta },
  };
}

export function salvarNovaPauta(novaPauta, filtros, turma, materia) {
  return {
    type: '@pautas/REQUEST_SAVE_NEW_SCHEDULE',
    payload: { pauta: novaPauta, filtros, turma, materia },
  };
}

export function requestSavePautaSuccess() {
  return {
    type: '@pautas/NEW_SCHEDULE_SAVE_SUCCESS',
  };
}

export function cancelarNovaPauta() {
  return {
    type: '@pautas/CANCEL_NEW_SCHEDULE',
  };
}

export function salvarFiltrosQuery({ filtros, labels }) {
  return {
    type: '@pautas/SAVE_FILTERS',
    payload: { filtros, labels },
  };
}

export function requestAlunos({ turma, materia }) {
  return {
    type: '@pautas/REQUEST_ALUNOS',
    payload: { turma, materia },
  };
}

export function requestAlunosSuccess(alunos, proximaAula, dados) {
  return {
    type: '@pautas/REQUEST_ALUNOS_SUCCESS',
    payload: { alunos, proximaAula, dados },
  };
}

export function requestAlunosFailure() {
  return {
    type: '@pautas/REQUEST_ALUNOS_FAILURE',
  };
}

export function requestConteudos(aula) {
  return {
    type: '@pautas/REQUEST_CONTEUDOS',
    payload: { aula },
  };
}

export function requestConteudosSuccess(conteudos) {
  return {
    type: '@pautas/REQUEST_CONTEUDOS_SUCCESS',
    payload: { conteudos },
  };
}

export function requestConteudosFailure() {
  return {
    type: '@pautas/REQUEST_CONTEUDOS_FAILURE',
  };
}

export function saveProfessoresSelecionados(professores) {
  return {
    type: '@pautas/PROFESSORES_SELECIONADOS',
    payload: { professores },
  };
}

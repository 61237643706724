import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { GetAppOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt';

import Comentarios from './ComentariosAtividade';
import AtribuirNota from './ComentariosAtividade/AtribuirNota';
import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '879px',
    height: '542px',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '8px',
  },
  container: {
    padding: theme.spacing(2, 3),
    height: '100%',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  label: {
    color: '#50A6FF',
    letterSpacing: '1.5px',
  },
  value: {
    color: '#868CAB',
  },
  file: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(80, 166, 255, 0.4)',
    borderRadius: '8px',
    maxHeight: '39px',
    padding: '5px 15px',
    color: '#50A6FF',
    fontSize: '14px',
    margin: '5px 2px 0 0',
  },
  fileName: {
    fontWeight: 'bold',
  },
  btDownload: {
    color: '#50A6FF',
    fontSize: '14px',
  },
  containerFiles: {
    maxHeight: '100px',
    overflowY: 'auto',
  },
  btNota: {
    borderRadius: '200px',
    color: '#fff',
  },
}));

export default function AtividadeAlunoModal({
  open,
  handleClose,
  resposta,
  atividadeLancada,
  atualizarTabela,
}) {
  const classes = useStyles();

  const lookupStatus = useSelector(state => state.lookup.statusAtividade);

  const [anexos, setAnexos] = useState(null);
  const [notaAtribuida, setNotaAtribuida] = useState(resposta.nota);

  useEffect(() => {
    async function getAnexos() {
      const response = await api.get(
        `/anexos-atividade/${resposta.realizaratividade_id}`
      );
      setAnexos(response.data);
    }

    getAnexos();
  }, [resposta.realizaratividade_id]);

  useEffect(() => {
    async function updateStatusNotificacoes() {
      await api.put(
        `/notificacoes-atividades/${resposta.realizaratividade_id}`
      );
    }

    updateStatusNotificacoes();
  }, [resposta.realizaratividade_id]);

  const handleChangeStatus = async event => {
    if (event.target.value > 0) {
      await api.put(
        `/resposta/${resposta.realizaratividade_id}/status/${event.target.value}`
      );
      atualizarTabela(true);
    }
  };

  const getLabelAnexo = index => {
    if (anexos.ultimaentrega) {
      const dataUltimaEntrega = anexos.ultimaentrega.replace(/z/i, '');

      return `Anexo ${index + 1} - Postado em ${format(
        new Date(dataUltimaEntrega),
        "dd/MM/yyyy à's' HH:mm",
        { locale: ptBr }
      )}`;
    }

    return `Anexo ${index + 1}`;
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container spacing={1} className={classes.container}>
              <Grid item xs={6} className={classes.info}>
                <Typography variant="h5" className={classes.label}>
                  Baixar e comentar
                </Typography>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.label}
                  >
                    ATIVIDADE
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.value}
                  >
                    {resposta.titulo}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.label}
                  >
                    ALUNO
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.value}
                  >
                    {resposta.aluno}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.label}
                  >
                    NOTA
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.value}
                  >
                    {notaAtribuida || '-'}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.label}
                  >
                    RESPOSTA DO ALUNO
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.value}
                  >
                    {resposta.resposta || '-'}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.label}
                  >
                    ARQUIVOS POSTADOS
                  </Typography>
                  <Grid container className={classes.containerFiles}>
                    {!!anexos &&
                      !!anexos.ultimaentrega &&
                      anexos.files.map((anexo, index) => (
                        <Grid item xs={12} className={classes.file} key={anexo}>
                          <Typography className={classes.fileName}>
                            {getLabelAnexo(index)}
                          </Typography>
                          <IconButton
                            className={classes.btDownload}
                            size="small"
                            onClick={() => {
                              window.open(anexo, '_blank');
                            }}
                          >
                            <GetAppOutlined />
                          </IconButton>
                        </Grid>
                      ))}
                  </Grid>
                </div>
                <div>
                  <Typography
                    variant="caption"
                    component="p"
                    className={classes.label}
                  >
                    STATUS DA ATIVIDADE
                  </Typography>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="select-status">Status</InputLabel>
                    <Select
                      onChange={handleChangeStatus}
                      defaultValue={resposta.status_id || 0}
                      labelId="select-status"
                    >
                      <MenuItem value={0} selected>
                        <em>Selecione</em>
                      </MenuItem>
                      {lookupStatus.map(status => (
                        <MenuItem value={status.intkey}>
                          {status.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <AtribuirNota
                  resposta={resposta}
                  atividadeLancada={atividadeLancada}
                  notaAtribuida={setNotaAtribuida}
                />
              </Grid>
              <Grid item xs={6}>
                <Comentarios resposta={resposta} />
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

AtividadeAlunoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  resposta: PropTypes.shape().isRequired,
  atividadeLancada: PropTypes.shape().isRequired,
  atualizarTabela: PropTypes.shape().isRequired,
};

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { toast } from 'react-toastify';

import TextField from '~/components/CustomUi/CustomTextField';
import CustomDatePicker from '~/components/CustomUi/CustomDatePicker';
import Select from '~/components/CustomUi/CustomSelect';

import api from '~/services/api';

const schema = Yup.object().shape({
  curso_id: Yup.string().required('O curso é obrigatório'),
  tipo: Yup.string(),
  data_matricula: Yup.string(),
  data_inicioaula: Yup.string(),
  turma: Yup.string(),
  livro: Yup.string(),
  observacoes: Yup.string(),
});

const useStyles = makeStyles(theme => ({
  root: {
    margin: '10px 15px',
  },
  materiasAdicionadas: {
    height: '114px',
    border: '1px solid #F7F8FA',
    borderRadius: '8px',
    margin: '0px 8px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  buttons: {
    margin: '10px',
  },
  buttonText: {
    color: '#fff',
  },
}));

export default function DadosCurso({
  nextStep,
  previousStep,
  setDadosCurso,
  setOpenConfirmationExit,
  initialDataCurso,
}) {
  const classes = useStyles();

  const cursos = useSelector(state => state.cursos.data);
  const tipoContrato = useSelector(state => state.lookup.tipoContrato);
  const unidade = useSelector(state => state.unidade.unidade_id);

  const [tipoContratoOptions, setTipoContratoOptions] = useState(null);
  const [cursoSelected, setCursoSelected] = useState(
    initialDataCurso.curso || false
  );
  const [cursosOptions, setCursosOptions] = useState(null);
  const [turmas, setTurmas] = useState(null);
  const [livros, setLivros] = useState(null);
  const [materias, setMaterias] = useState(null);
  const [materiasAdicionais, setMateriasAdicionais] = useState(null);
  const [materiasOptions, setMateriasOptions] = useState(null);
  const [materiasAdicionaisOptions, setMateriasAdicionaisOptions] = useState(
    null
  );
  const [turmasOptions, setTurmasOptions] = useState(null);
  const [livrosOptions, setLivrosOptions] = useState(null);
  const [materiasAdicionadas, setMateriasAdicionadas] = useState([]);

  useEffect(() => {
    async function getMaterias() {
      await api
        .get(`materias-curso/${cursoSelected}`)
        .then(response => {
          const arraySelectValues = [];
          response.data.map(materiasMap => {
            arraySelectValues.push({
              value: materiasMap.materia_id,
              label: materiasMap.materia,
            });
          });
          setMaterias(response.data);
          setMateriasOptions(arraySelectValues);
        })
        .catch(() => {
          toast.error('Matérias não encontradas.');
        });
    }

    async function getMateriasAdicionais() {
      await api
        .get(`materias-adicionais/${unidade}`)
        .then(response => {
          const arraySelectValues = [];
          response.data.map(materiasMap => {
            arraySelectValues.push({
              value: materiasMap.materia_id,
              label: materiasMap.materia,
            });
          });
          setMateriasAdicionais(response.data);
          setMateriasAdicionaisOptions(arraySelectValues);
        })
        .catch(() => {
          toast.error('Matérias adicionais não cadastradas.');
        });
    }

    async function getTurmas() {
      await api
        .get(`turmas/${unidade}`)
        .then(response => {
          const arraySelectValues = [];
          response.data.map(turmasMap => {
            arraySelectValues.push({
              value: turmasMap.turma_id,
              label: turmasMap.turma,
            });
          });
          setTurmas(response.data);
          setTurmasOptions(arraySelectValues);
        })
        .catch(() => {
          toast.error('Não há turmas cadastradas.');
        });
    }

    async function getLivros() {
      await api
        .get(`livros/${unidade}`)
        .then(response => {
          const arraySelectValues = [];
          response.data.map(livrosMap => {
            arraySelectValues.push({
              value: livrosMap.livro_id,
              label: livrosMap.descricao,
            });
          });
          setLivros(response.data);
          setLivrosOptions(arraySelectValues);
        })
        .catch(() => {
          toast.error('Não há livros cadastrados.');
        });
    }

    if (cursos && cursosOptions === null) {
      const arraySelectValues = [];
      cursos.map(curso => {
        arraySelectValues.push({ value: curso.curso_id, label: curso.curso });
      });
      setCursosOptions(arraySelectValues);
    }

    if (!tipoContratoOptions && tipoContrato.length > 0) {
      const arraySelectValues = [];
      tipoContrato.map(tipoContratoMap => {
        arraySelectValues.push({
          value: tipoContratoMap.key_id,
          label: tipoContratoMap.descricao,
        });
      });

      setTipoContratoOptions(arraySelectValues);
    }

    if (!materias && cursoSelected) {
      getMaterias();
    }

    if (!materiasAdicionais) {
      getMateriasAdicionais();
    }

    if (!turmas) {
      getTurmas();
    }

    if (!livros) {
      getLivros();
    }

    if (initialDataCurso.materias && materiasAdicionadas.length < 1) {
      const newMateriasAdd = [];

      initialDataCurso.materias.map(materiaMap => {
        newMateriasAdd.push(materiaMap);
      });

      setMateriasAdicionadas(newMateriasAdd);
    }
  }, [
    cursoSelected,
    cursos,
    cursosOptions,
    initialDataCurso.materias,
    livros,
    materias,
    materiasAdicionadas.length,
    materiasAdicionais,
    tipoContrato,
    tipoContratoOptions,
    turmas,
    unidade,
  ]);

  function handleSubmit(data) {
    if (data.data_matricula) {
      const data_matriculaSplitted = data.data_matricula.split('/');
      data.data_matricula = `${data_matriculaSplitted[2]}-${data_matriculaSplitted[1]}-${data_matriculaSplitted[0]}`;
    }

    if (data.data_inicioaula) {
      const dataAulasSplitted = data.data_inicioaula.split('/');
      data.data_inicioaula = `${dataAulasSplitted[2]}-${dataAulasSplitted[1]}-${dataAulasSplitted[0]}`;
    }

    const materiasEscolhidas = [];
    materiasAdicionadas.map(materiaMap => {
      materiasEscolhidas.push(materiaMap.materia_id);
    });

    data.materias = materiasEscolhidas;

    setDadosCurso(data);
    nextStep();
  }

  function handleChangeCurso(value) {
    setCursoSelected(value !== '' ? value : false);
  }

  function handleDeleteChip(materiaAdicionada) {
    const newMateriasAdd = [];

    materiasAdicionadas.map(materiaMap => {
      if (materiaAdicionada.materia_id !== materiaMap.materia_id) {
        newMateriasAdd.push(materiaMap);
      }
    });

    setMateriasAdicionadas(newMateriasAdd);
  }

  function handleSelectMateria(materiaSelecionada) {
    const newMateriasAdd = [];

    materiasAdicionadas.map(materiaMap => {
      newMateriasAdd.push(materiaMap);
    });

    const hasMateriaSelected = newMateriasAdd.filter(materiaFiltered => {
      return materiaFiltered.materia_id === parseInt(materiaSelecionada, 10);
    });

    const materiaSelected = materias.filter(materiaFiltered => {
      return materiaFiltered.materia_id === parseInt(materiaSelecionada, 10);
    });

    if (hasMateriaSelected.length < 1) {
      newMateriasAdd.push(materiaSelected[0]);
      setMateriasAdicionadas(newMateriasAdd);
    }
  }

  return (
    <div className={classes.root}>
      <Form
        onSubmit={handleSubmit}
        schema={schema}
        initialData={initialDataCurso}
      >
        <Typography component="h6" variant="h6">
          Preencha os campos abaixo para cadastrar um curso para esse novo
          contrato
        </Typography>
        <Typography component="p" color="secondary">
          Dados do curso
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={12} md={3}>
            <Select
              name="curso_id"
              label="Curso"
              options={cursosOptions}
              valueSelected={handleChangeCurso}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <Select
              name="tipo"
              label="Tipo do contrato"
              disabled={!cursoSelected}
              options={tipoContratoOptions}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <CustomDatePicker
              name="data_matricula"
              label="Data da matrícula"
              fullWidth
              disabled={!cursoSelected}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <CustomDatePicker
              name="data_inicioaula"
              label="Início das aulas"
              fullWidth
              disabled={!cursoSelected}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <Select
              name="turma"
              label="Turma"
              disabled={!cursoSelected}
              options={turmasOptions}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <Select
              name="livro"
              label="Livros"
              disabled={!cursoSelected}
              options={livrosOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" color="secondary">
              Matérias do curso
            </Typography>
          </Grid>
          <Grid item sm={12} md={3}>
            <Select
              name="materia"
              label="Matérias"
              disabled={!cursoSelected}
              options={materiasOptions}
              valueSelected={handleSelectMateria}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <Select
              name="materiaAdicional"
              label="Matérias adicionais"
              disabled={!cursoSelected}
              options={materiasAdicionaisOptions}
            />
          </Grid>
          <Grid item xs={12} className={classes.materiasAdicionadas}>
            <Typography component="p" variant="caption" color="secondary">
              Matérias adicionadas no curso
            </Typography>

            {materiasAdicionadas &&
              materiasAdicionadas.map(materiaAdicionada => (
                <Chip
                  key={materiaAdicionada.materia_id}
                  label={materiaAdicionada.materia}
                  onDelete={() => {
                    handleDeleteChip(materiaAdicionada);
                  }}
                  className={classes.chip}
                />
              ))}
          </Grid>
          <Grid item xs={12}>
            <Typography component="p" color="secondary">
              Observações sobre o curso:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="observacoes"
              label="Observações Gerais"
              placeholder="Digite as observações referentes a esse contrato"
              multiline
              rows="6"
              disabled={!cursoSelected}
            />
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            className={classes.buttons}
          >
            <Grid item xs={12} sm={3} md={2}>
              <Button
                type="button"
                color="secondary"
                variant="text"
                fullWidth
                onClick={() => {
                  setOpenConfirmationExit(true);
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                fullWidth
                onClick={() => {
                  previousStep();
                }}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                className={classes.buttonText}
              >
                Avançar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}

DadosCurso.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  setDadosCurso: PropTypes.func.isRequired,
  setOpenConfirmationExit: PropTypes.func.isRequired,
  initialDataCurso: PropTypes.shape().isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import {
  Fade,
  Button,
  Badge,
  Typography,
  Chip,
  Backdrop,
  Modal,
  Grid,
  Divider,
  MenuItem,
  FilledInput,
  Checkbox,
  ListItemText,
  FormControl,
} from '@material-ui/core';
import { Select } from 'unform-material-ui';
import { FilterList } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import { format, isValid } from 'date-fns';
import { Form } from '@unform/web';

import { useFilters } from '~/hooks/filters';

import api from '~/services/api';

import DatePickerField from '~/components/CustomUi/DatePickerField';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    maxWidth: '699px',
    padding: '34px 42px',
  },
  filterBadge: {
    marginLeft: '15px',
  },
  btOpen: {
    width: '160px',
  },
  menu: {
    padding: '10px 37px',
    width: '350px',
  },
  textField: {
    margin: '5px 0',
  },
  btSubmit: {
    margin: '5px 0',
    color: '#fff',
  },
  chip: {
    textTransform: 'capitalize',
    marginLeft: '5px',
  },
  divider: {
    marginTop: '15px',
  },
  btsTypeFilter: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  selected: {
    '&&': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  initialDivider: {
    margin: '15px 0',
  },
}));

export default function ModalFiltros({ professores }) {
  const classes = useStyles();

  const cursos = useSelector(state => state.cursos.data);
  const turmas = useSelector(state => state.turmas.data);
  const lookup = useSelector(state => state.lookup);

  const { filters: appliedFilters, setFilters } = useFilters();

  const [open, setOpen] = useState(false);
  const [statusSelecionados, setStatusSelecionados] = useState(
    appliedFilters.atividades.status || []
  );
  const [cursosSelecionados, setCursosSelecionados] = useState(
    appliedFilters.atividades.cursos || []
  );
  const [turmasSelecionadas, setTurmasSelecionadas] = useState(
    appliedFilters.atividades.turmas || []
  );
  const [materiasSelecionadas, setMateriasSelecionadas] = useState(
    appliedFilters.atividades.materias || []
  );
  const [professoresSelecionados, setProfessoresSelecionados] = useState(
    appliedFilters.atividades.professores || []
  );

  const [typeFilter, setTypeFilter] = useState(appliedFilters.atividades.type);
  const [activatedFilters, setActivatedFilters] = useState(() => {
    const filtersActive = [];
    if (!!appliedFilters.atividades) {
      Object.keys(appliedFilters.atividades).map(filter => {
        if (filter === 'conteudo') {
          filter = 'conteúdo';
        }

        if (filter === 'inicio') {
          filter = 'início';
        }

        if (filter === 'materia') {
          filter = 'matéria';
        }

        if (filter === 'data_aula') {
          filter = 'data da aula';
        }
        filtersActive.push(filter);
      });
    }
    return filtersActive;
  });

  const [objectFilters, setObjectFilters] = useState(appliedFilters);
  const [materias, setMaterias] = useState(null);

  const configureAppliedFilters = filters => {
    const filtersActive = [];
    if (!!filters.atividades) {
      Object.keys(filters.atividades)
        .filter(filtro => filtro !== 'type')
        .map(filter => {
          if (filter === 'conteudo') {
            filter = 'conteúdo';
          }

          if (filter === 'inicio') {
            filter = 'início';
          }

          if (filter === 'materia') {
            filter = 'matéria';
          }

          if (filter === 'data_aula') {
            filter = 'data da aula';
          }
          filtersActive.push(filter);
        });
    }
    setActivatedFilters(filtersActive);
  };

  const getMaterias = async query => {
    const response = await api.get('/materias', { params: query });
    setMaterias(response.data);
  };

  useEffect(() => {
    const filtersActive = [];
    if (!!appliedFilters.atividades) {
      Object.keys(appliedFilters.atividades).map(filter => {
        if (filter === 'conteudo') {
          filter = 'conteúdo';
        }

        if (filter === 'inicio') {
          filter = 'início';
        }

        if (filter === 'materias') {
          filter = 'matérias';
        }

        if (filter === 'data_aula') {
          filter = 'data da aula';
        }
        filtersActive.push(filter);
      });
    }
    setActivatedFilters(filtersActive);
  }, [appliedFilters.atividades]);

  useEffect(() => {
    if (!materias) {
      getMaterias();
    }
  }, [materias]);

  const handleChange = event => {
    const newObjectFilters = { ...objectFilters };
    newObjectFilters.atividades[event.target.name] =
      event.target.value || event.target.checked;

    if (event.target.name === 'status') {
      setStatusSelecionados(event.target.value);
    }

    if (event.target.name === 'cursos') {
      setCursosSelecionados(event.target.value);
    }

    if (event.target.name === 'turmas') {
      setTurmasSelecionadas(event.target.value);
    }

    if (event.target.name === 'materias') {
      setMateriasSelecionadas(event.target.value);
    }

    if (event.target.name === 'professores') {
      setProfessoresSelecionados(event.target.value);
    }

    setObjectFilters(newObjectFilters);
  };

  const handleChangeTurma = async turmasEscolhidas => {
    const query = {
      turmas: turmasEscolhidas,
    };

    if (objectFilters.atividades.cursos) {
      query.cursos = objectFilters.atividades.cursos;
    }

    getMaterias(query);
  };

  const handleChangeCurso = async cursosEscolhidos => {
    const query = {
      cursos: cursosEscolhidos,
    };

    if (objectFilters.atividades.turmas) {
      query.turmas = objectFilters.atividades.turmas;
    }

    getMaterias(query);
  };

  const handleChangeDate = fieldName => value => {
    const newObjectFilters = { ...objectFilters };

    if (!isValid(value)) {
      delete newObjectFilters.atividades[fieldName];
    } else {
      newObjectFilters.atividades[fieldName] = format(value, 'yyyy-MM-dd');
    }

    setObjectFilters(newObjectFilters);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = chip => {
    const newObjectFilters = { ...objectFilters };
    if (chip === 'conteúdo') {
      delete newObjectFilters.atividades.conteudo;
    }

    if (chip === 'início') {
      delete newObjectFilters.atividades.inicio;
    }

    if (chip === 'matérias') {
      setMateriasSelecionadas([]);
      delete newObjectFilters.atividades.materias;
    }

    if (chip === 'cursos') {
      setCursosSelecionados([]);
    }

    if (chip === 'turmas') {
      setTurmasSelecionadas([]);
    }

    if (chip === 'professores') {
      setProfessoresSelecionados([]);
    }

    if (chip === 'status') {
      setStatusSelecionados([]);
    }

    if (chip === 'data da aula') {
      delete newObjectFilters.atividades.data_aula;
    }

    delete newObjectFilters.atividades[chip];
    setObjectFilters(newObjectFilters);
    setActivatedFilters(Object.keys(newObjectFilters.atividades));
    setFilters({ ...appliedFilters, atividades: newObjectFilters.atividades });
  };

  const renderStatusSelecionados = () => {
    const descricoes = [];
    lookup.statusAtividade
      .filter(statusFilter => statusSelecionados.includes(statusFilter.intkey))
      .map(statusMapped => descricoes.push(statusMapped.descricao));

    return descricoes.join(', ');
  };

  const renderCursosSelecionados = () => {
    const descricoes = [];
    cursos
      .filter(curso => cursosSelecionados.includes(curso.curso_id))
      .map(curso => descricoes.push(curso.descricao));

    return descricoes.join(', ');
  };

  const renderTurmasSelecionadas = () => {
    const descricoes = [];
    turmas
      .filter(turma => turmasSelecionadas.includes(turma.turma_id))
      .map(turma => descricoes.push(turma.nome));

    return descricoes.join(', ');
  };

  const renderMateriasSelecionadas = () => {
    if (materias.length) {
      const descricoes = [];
      materias
        .filter(materia => materiasSelecionadas.includes(materia.materia_id))
        .map(materia => descricoes.push(materia.materia));

      return descricoes.join(', ');
    }
  };

  const renderProfessoresSelecionados = () => {
    const descricoes = [];
    professores
      .filter(professor =>
        professoresSelecionados.includes(professor.professor_id)
      )
      .map(professor => descricoes.push(professor.professor));

    return descricoes.join(', ');
  };

  const handleSubmit = async data => {
    const newData = {};
    Object.keys(data).filter(property => {
      if (data[property]) {
        if (
          !Array.isArray(data[property]) ||
          (Array.isArray(data[property]) && data[property].length)
        ) {
          newData[property] = data[property];
        }
      }
    });

    configureAppliedFilters(appliedFilters);
    setFilters({
      ...appliedFilters,
      atividades: { ...objectFilters.atividades, ...newData, type: typeFilter },
    });
    handleClose();
  };

  return (
    <div>
      {activatedFilters &&
        activatedFilters
          .filter(activatedFilter => activatedFilter !== 'type')
          .map(filter => (
            <Chip
              label={filter}
              onDelete={() => handleDelete(filter)}
              className={classes.chip}
              key={filter}
            />
          ))}
      <Button
        type="button"
        onClick={handleClick}
        variant="text"
        color="secondary"
        className={classes.btOpen}
        startIcon={<FilterList />}
        endIcon={
          <Badge
            className={classes.filterBadge}
            badgeContent={
              (activatedFilters && activatedFilters.length - 1) || 0
            }
            color="secondary"
          />
        }
      >
        Filtros
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography color="secondary" variant="body1">
              Filtros
            </Typography>
            <div className={classes.btsTypeFilter}>
              <ToggleButton
                variant="contained"
                value={1}
                color="primary"
                selected={typeFilter === 1}
                onClick={() => setTypeFilter(1)}
                classes={{ selected: classes.selected }}
                size="small"
              >
                Conteúdos
              </ToggleButton>
              <ToggleButton
                variant="contained"
                value={2}
                color="primary"
                selected={typeFilter === 2}
                onClick={() => setTypeFilter(2)}
                classes={{ selected: classes.selected }}
                size="small"
              >
                Videoaulas
              </ToggleButton>
            </div>
            <Form
              onSubmit={handleSubmit}
              initialData={appliedFilters.atividades}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Divider
                    variant="fullWidth"
                    className={classes.initialDivider}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      name="cursos"
                      variant="filled"
                      label="Cursos"
                      fullWidth
                      input={<FilledInput />}
                      renderValue={() => renderCursosSelecionados()}
                      multiple
                      onChange={event => {
                        handleChange(event);
                        handleChangeCurso(event.target.value);
                      }}
                    >
                      <MenuItem disabled>Selecione</MenuItem>
                      {cursos &&
                        cursos.map(curso => (
                          <MenuItem key={curso.curso_id} value={curso.curso_id}>
                            <Checkbox
                              checked={
                                cursosSelecionados.indexOf(curso.curso_id) > -1
                              }
                            />
                            <ListItemText primary={curso.descricao} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      name="turmas"
                      variant="filled"
                      label="Turmas"
                      fullWidth
                      input={<FilledInput />}
                      renderValue={() => renderTurmasSelecionadas()}
                      multiple
                      onChange={event => {
                        handleChange(event);
                        handleChangeTurma(event.target.value);
                      }}
                    >
                      <MenuItem disabled>Selecione</MenuItem>
                      {turmas &&
                        turmas.map(turma => (
                          <MenuItem key={turma.turma_id} value={turma.turma_id}>
                            <Checkbox
                              checked={
                                turmasSelecionadas.indexOf(turma.turma_id) > -1
                              }
                            />
                            <ListItemText primary={turma.nome} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <Select
                      name="materias"
                      variant="filled"
                      label="Matérias"
                      fullWidth
                      input={<FilledInput />}
                      renderValue={() => renderMateriasSelecionadas()}
                      multiple
                      onChange={handleChange}
                    >
                      <MenuItem disabled>Selecione</MenuItem>
                      {materias &&
                        materias.map(materia => (
                          <MenuItem
                            key={materia.materia_id}
                            value={materia.materia_id}
                          >
                            <Checkbox
                              checked={
                                materiasSelecionadas.indexOf(
                                  materia.materia_id
                                ) > -1
                              }
                            />
                            <ListItemText primary={materia.materia} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <Select
                      name="professores"
                      variant="filled"
                      label="Professores"
                      input={<FilledInput />}
                      renderValue={() => renderProfessoresSelecionados()}
                      multiple
                      fullWidth
                      onChange={handleChange}
                    >
                      <MenuItem disabled>Selecione</MenuItem>
                      {professores &&
                        professores.map(professor => (
                          <MenuItem
                            key={professor.professor_id}
                            value={professor.professor_id}
                          >
                            <Checkbox
                              checked={
                                professoresSelecionados.indexOf(
                                  professor.professor_id
                                ) > -1
                              }
                            />
                            <ListItemText primary={professor.professor} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <Select
                      name="status"
                      variant="filled"
                      label="Status"
                      input={<FilledInput />}
                      renderValue={() => renderStatusSelecionados()}
                      onChange={handleChange}
                      multiple
                    >
                      <MenuItem disabled>Selecione</MenuItem>
                      {lookup.statusAtividade.map(status => (
                        <MenuItem key={status.intkey} value={status.intkey}>
                          <Checkbox
                            checked={
                              statusSelecionados.indexOf(status.intkey) > -1
                            }
                          />
                          <ListItemText primary={status.descricao} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DatePickerField
                    label="Data Ínicio"
                    name="inicio"
                    fullWidth
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    onChange={handleChangeDate('inicio')}
                    value={
                      (objectFilters.atividades.inicio &&
                        new Date(
                          `${objectFilters.atividades.inicio} 03:00:00.000 UTC`
                        )) ||
                      null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DatePickerField
                    label="Data Fim"
                    name="fim"
                    fullWidth
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    onChange={handleChangeDate('fim')}
                    value={
                      (objectFilters.atividades.fim &&
                        new Date(
                          `${objectFilters.atividades.fim} 03:00:00.000 UTC`
                        )) ||
                      null
                    }
                  />
                </Grid>
                {typeFilter === 2 && (
                  <Grid item xs={12} sm={6} md={4}>
                    <DatePickerField
                      label="Data da Aula"
                      name="data_aula"
                      fullWidth
                      inputVariant="filled"
                      format="dd/MM/yyyy"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      onChange={handleChangeDate('data_aula')}
                      value={
                        (objectFilters.atividades.data_aula &&
                          new Date(
                            `${objectFilters.atividades.data_aula} 03:00:00.000 UTC`
                          )) ||
                        null
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    className={classes.btSubmit}
                  >
                    FILTRAR
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalFiltros.propTypes = {
  professores: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken';

import AuthConfig from '~/config/AuthConfig';
import history from '~/services/history';
import api from '~/services/api';

import {
  signInSuccess,
  signFailure,
  signOut,
  signInSuccessSetUserId,
} from './actions';

export function* signIn({ payload }) {
  try {
    const { login, password } = payload;

    const response = yield call(api.post, 'session', {
      login,
      password,
    });

    const { token, user } = response.data;

    const { usuario_id } = jwt.verify(token, AuthConfig.secret);

    yield put(signInSuccess(token, user));
    yield put(signInSuccessSetUserId(usuario_id));

    api.defaults.headers.Authorization = `Bearer ${token}`;

    history.push('/dashboard');
  } catch (err) {
    if (err.name && err.name === 'TokenExpiredError') {
      toast.error('Sessão expirada.');
      history.push('/');
    } else {
      toast.error('E-mail ou senha incorretos, verifique seus dados.');
    }
    yield put(signFailure());
  }
}

export function* setToken({ payload }) {
  if (!payload || !payload.auth || !payload.auth.token) {
    return;
  }

  const { token } = payload.auth;

  try {
    const { usuario_id } = jwt.verify(token, AuthConfig.secret);
    yield put(signInSuccessSetUserId(usuario_id));
  } catch (error) {
    toast.error('Sessão expirada.');
    history.push('/');
    yield put(signOut());
    return;
  }

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function logout() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', logout),
]);

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { ConfirmProvider } from 'material-ui-confirm';

import Main from '~/components/Main';

import theme from '~/styles/themes';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

export default function DefaultLayout({ children }) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme()}>
      <ConfirmProvider>
        <div className={classes.root}>
          <Main>{children}</Main>
        </div>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

import produce from 'immer';

const INITIAL_STATE = {
  menuOpen: false,
  largeScreen: true,
};

export default function layout(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@layout/CHANGE_OPEN_MENU': {
        draft.menuOpen = action.payload.menuOpen;
        break;
      }
      case '@layout/CHANGE_LARGE_SCREEN': {
        draft.largeScreen = action.payload.largeScreen;
        break;
      }
      case '@unidade/UNIDADE_SELECIONADA_SUCCESS': {
        draft.menuOpen = false;
        break;
      }
      case '@unidade/UNIDADE_SELECIONADA_FAILURE': {
        draft.menuOpen = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.menuOpen = false;
        break;
      }
      default:
    }
  });
}

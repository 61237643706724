export function changeOpenMenu(menuOpen) {
  return {
    type: '@layout/CHANGE_OPEN_MENU',
    payload: { menuOpen },
  };
}

export function changeLargeScreen(largeScreen) {
  return {
    type: '@layout/CHANGE_LARGE_SCREEN',
    payload: { largeScreen },
  };
}

export function aulaSelecionada(aula) {
  return {
    type: '@transmissao/AULA_SELECIONADA',
    payload: { aula },
  };
}

export function iniciarTransmissao(aula) {
  return {
    type: '@transmissao/INICIAR',
    payload: { aula },
  };
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import api from '~/services/api';
import history from '~/services/history';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogNovaAula({ openDialog, handleClose }) {
  const [stream, setStream] = useState(false);
  const [loading, setLoading] = useState(false);

  const useStyles = makeStyles({
    dialog: {
      maxWidth: '388px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '8px 24px',
    },
    boxStream: {
      border: `1px solid ${stream ? '#50A6FF' : '#868CAB'}`,
      color: `${stream ? '#50A6FF' : '#868CAB'}`,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '10px',
      margin: '10px',
      cursor: 'pointer',
      borderRadius: '10px',
    },
    boxUrl: {
      border: `1px solid ${!stream ? '#50A6FF' : '#868CAB'}`,
      color: `${!stream ? '#50A6FF' : '#868CAB'}`,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '10px',
      margin: '10px',
      cursor: 'pointer',
      borderRadius: '10px',
    },
    button: {
      borderRadius: '200px',
      color: '#fff',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%',
      margin: '15px 0',
    },
  });

  const classes = useStyles();

  async function handleConfirm() {
    if (!stream) {
      handleClose(false);
      history.push('/nova-video-aula/0');
      return;
    }

    setLoading(true);
    const responseCheck = await api.get('check-youtube-credentials');
    if (responseCheck.data.credentials === null) {
      const responseOauth = await api.get('goauth');
      setLoading(false);
      window.location.replace(responseOauth.data.url);
      return;
    }

    if (responseCheck.data.credentials === false) {
      let callOauth = false;
      await api.get('refresh-youtube-credentials').catch(() => {
        callOauth = true;
      });
      if (callOauth) {
        const responseOauth = await api.get('goauth');
        setLoading(false);
        handleClose(false);
        window.location.replace(responseOauth.data.url);
      }
    } else {
      handleClose(false);
      setLoading(false);
      history.push('/nova-video-aula/1');
    }
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          handleClose(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.dialog}>
          <Typography color="primary" variant="h5" align="center">
            Nova videoaula
          </Typography>
          <Typography color="secondary" component="p" align="center">
            Selecione como deseja transmitir.
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialog}>
          {(loading && (
            <ReactLoading
              type="cylon"
              color="#868CAB"
              className={classes.loading}
            />
          )) || (
            <>
              <Box className={classes.boxUrl}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={!stream}
                      onChange={() => {
                        setStream(false);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Videoconferência (ao vivo e gravadas)"
                />
              </Box>
              <Box className={classes.boxStream}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={stream}
                      onChange={() => {
                        setStream(true);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Transmissão por live no YouTube"
                />
              </Box>
              <div className={classes.buttons}>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    handleClose(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    handleConfirm();
                  }}
                >
                  Confirmar
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

DialogNovaAula.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

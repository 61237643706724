import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import Image from 'material-ui-image';

import imgConteudoDesativado from '~/assets/ilustra_conteudo_encerrado.svg';

const useStyles = makeStyles(() => ({
  btMeeting: {
    color: '#fff',
    borderRadius: '200px',
    margin: '15px 0',
    maxWidth: '300px',
  },
}));

export default function Hangouts() {
  const classes = useStyles();
  const transmissao = useSelector(state => state.transmissao.aula);

  const isConteudoDesativado = () => {
    return transmissao.status === 4 || transmissao.status_id === 4;
  };

  const isUrlFileUploaded = () => {
    return (
      (transmissao.url && transmissao.url.includes('objectstorage')) ||
      (transmissao.streaming_url &&
        transmissao.streaming_url.includes('objectstorage'))
    );
  };

  const showHangouts = () => {
    if (
      isConteudoDesativado() ||
      (!transmissao.url && !transmissao.streaming_url)
    ) {
      return (
        <>
          <Image
            src={imgConteudoDesativado}
            style={{
              display: 'flex',
              paddingTop: 'unset',
              backgroundColor: 'none',
              justifyContent: 'center',
            }}
            imageStyle={{
              width: 'unset',
              height: 'unset',
              position: 'unset',
            }}
          />
          <Typography
            color="primary"
            variant="h5"
            component="div"
            align="center"
          >
            Fim da Transmissão
          </Typography>
          <Typography
            color="secondary"
            variant="body1"
            component="div"
            align="center"
          >
            A transmissão da videoaula foi encerrada ;)
          </Typography>
        </>
      );
    }

    if ((transmissao.streaming_url || transmissao.url) && isUrlFileUploaded()) {
      return (
        <ReactPlayer
          url={transmissao.url || transmissao.streaming_url}
          controls
        />
      );
    }

    return (
      <Button
        type="button"
        color="primary"
        variant="contained"
        className={classes.btMeeting}
        onClick={() => {
          let url = transmissao.url || transmissao.streaming_url;
          url = url.includes('http') ? url : `https://${url}`;
          window.open(url, '_blank');
        }}
      >
        ENTRAR NA SALA DE REUNIÃO
      </Button>
    );
  };

  return <>{showHangouts()}</>;
}
